import { RelationType } from '@/api'
import { StepperForm, StepperFormProps } from '@/components'
import { useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import {
  useQueryPatientByBirthdate,
  useQueryPatientByFirstName,
  useQueryPatientByLastName,
  useSubmitOnboarding,
} from '@/services'
import { useNavigateToPreviousLocation } from '@/utils'
import { filter, pick } from 'ramda'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { OnboardingBirthdateStep } from './OnboardingBirthdateStep'
import { OnboardingFailedSearch } from './OnboardingFailedSearch'
import { OnboardingFirstNameStep } from './OnboardingFirstNameStep'
import { OnboardingHospitalStep } from './OnboardingHospitalStep'
import { OnboardingLastNameStep } from './OnboardingLastNameStep'
import { OnboardingOverviewStep } from './OnboardingOverviewStep'
import { OnboardingRelationStep } from './OnboardingRelationStep'
import { OnboardingStationStep } from './OnboardingStationStep'
import { stepKeys, validationSchema } from './validationSchema'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('onboardingPage.title'),
    hospital: t('global.hospital'),
    ward: t('global.ward'),
    skip: t('onboardingPage.skip'),
    textStep1: t('onboardingPage.step1Text'),
    textStep2: t('onboardingPage.step2Text'),
    textStep3: t('onboardingPage.step3Text'),
    patientFirstName: t('onboardingPage.patientFirstName'),
    patientLastName: t('onboardingPage.patientLastName'),
    isThisYourPatient: t('onboardingPage.isThisYourPatient'),
    notMyPatient: t('onboardingPage.notMyPatient'),
    sendRequest: t('onboardingPage.sendRequest'),
    checkAgain: t('onboardingPage.checkAgain'),
    alreadyMatchedError: t('onboardingPage.alreadyMatchedError'),
    validations: t('onboardingPage.validations', { returnObjects: true }),
  }
}

export type OnboardingValues = {
  hospital: string
  station: number
  date_of_birth: string
  patient_first_name: string
  patient_last_name: string
  patient_relation: RelationType | ''
  patient_id?: number
}
type stepsType = StepperFormProps<Partial<OnboardingValues>, stepKeys>['steps']

type handleSearchSubmitType = (searchTryCount: 0 | 1 | 2) => stepsType[number]['onSubmit']
type handleFinishType = StepperFormProps<Partial<OnboardingValues>, stepKeys>['onFinish']

const optionalScreens = ['last_name', 'first_name'] as const

export const OnboardingPage = () => {
  const snackbar = useSnackbar()
  const [searchTryCount, setSearchTryCount] = useState<0 | 1 | 2>(0)
  const navigate = useNavigateToPreviousLocation()
  const routerNavigation = useNavigate()
  const queryPatientByBirthdateMutation = useQueryPatientByBirthdate()
  const queryPatientByLastNameMutation = useQueryPatientByLastName()
  const queryPatientByFirstNameMutation = useQueryPatientByFirstName()

  const submitOnboardingMutation = useSubmitOnboarding()
  const messages = useMessages()

  const queryPatientMutations = {
    0: queryPatientByBirthdateMutation,
    1: queryPatientByLastNameMutation,
    2: queryPatientByFirstNameMutation,
  }

  const handleClose = () => {
    navigate(`/${paths.home}`)
  }

  const handleFinish: handleFinishType = (values) =>
    new Promise((resolve, reject) => {
      submitOnboardingMutation.mutate(
        pick(
          ['patient_id', 'patient_relation'],
          values as { patient_id: number; patient_relation: RelationType },
        ),
        {
          onSuccess: () => {
            resolve()
            routerNavigation(`/${paths.home}/${values.patient_id}`)
          },
          onError: (err) => {
            if (err.response?.data?.already_matched) {
              snackbar.error(messages.alreadyMatchedError)
            }
            reject()
          },
        },
      )
    })

  const handleSubmit: handleSearchSubmitType = (searchTry: 0 | 1 | 2) => (
    values,
    { openError, next, goTo },
    { setFieldValue },
  ) =>
    new Promise<void>((resolve, reject) => {
      const fields: [keyof OnboardingValues, ...(keyof OnboardingValues)[]] = [
        'date_of_birth',
        'hospital',
        'station',
      ]

      if (searchTry > 0) fields.push('patient_last_name')
      if (searchTry > 1) fields.push('patient_first_name')

      queryPatientMutations[searchTry].mutate(pick(fields, values as OnboardingValues), {
        onSuccess: ({ id, first_name, last_name, station }) => {
          setFieldValue('patient_first_name', first_name)
          setFieldValue('patient_last_name', last_name)
          setFieldValue('station', station)
          setFieldValue('patient_id', id)
          setSearchTryCount(searchTry)
          next()
          resolve()
        },
        onError: (err) => {
          if (searchTry === 2) {
            openError()
          } else {
            switch (err.response?.status) {
              case 404:
                openError()
                break
              case 409:
                setSearchTryCount((searchTry + 1) as 1 | 2)
                goTo(optionalScreens[searchTry])
                break
            }
          }
          reject()
        },
      })
    })

  const steps = useMemo<(stepsType[number] | false)[]>(
    () => [
      {
        key: 'hospital',
        component: OnboardingHospitalStep,
        stepTitle: messages.textStep1,
        validateFieldsToDisable: ['hospital'],
      },
      {
        key: 'station',
        component: OnboardingStationStep,
        stepTitle: messages.textStep2,
        validateFieldsToDisable: ['station'],
        skip: {
          text: messages.skip,
          fields: ['station'],
        },
      },
      {
        key: 'birthday',
        component: OnboardingBirthdateStep,
        stepTitle: messages.textStep3,
        validateFieldsToDisable: ['date_of_birth'],
        onSubmit: handleSubmit(0),
      },
      searchTryCount === 1 && {
        key: 'last_name',
        component: OnboardingLastNameStep,
        stepTitle: messages.patientLastName,
        validateFieldsToDisable: ['patient_last_name'],
        onSubmit: handleSubmit(1),
      },
      searchTryCount === 2 && {
        key: 'first_name',
        component: OnboardingFirstNameStep,
        stepTitle: messages.patientFirstName,
        validateFieldsToDisable: ['patient_first_name'],
        onSubmit: handleSubmit(2),
      },
      {
        key: 'overview',
        component: OnboardingOverviewStep,
        stepTitle: messages.isThisYourPatient,
        skip: {
          text: messages.notMyPatient,
          to: 'hospital',
          type: 'reset',
        },
      },
      {
        key: 'relation',
        component: OnboardingRelationStep,
        validateFieldsToDisable: ['patient_relation'],
      },
      {
        key: 'final_overview',
        component: OnboardingOverviewStep,
        stepTitle: messages.checkAgain + ':',
        skip: {
          text: messages.notMyPatient,
          to: 'hospital',
          type: 'reset',
        },
      },
    ],
    [handleSubmit, searchTryCount],
  )

  return (
    <StepperForm<Partial<OnboardingValues>, stepKeys>
      hideLastStepProgress
      initialValues={{
        hospital: '',
        date_of_birth: '',
        patient_relation: '',
      }}
      onClose={handleClose}
      submitButtonText={messages.sendRequest}
      title={messages.title}
      validationSchema={validationSchema(messages.validations)}
      errorComponent={OnboardingFailedSearch}
      onFinish={handleFinish}
      steps={filter(Boolean, steps) as stepsType}
    />
  )
}
