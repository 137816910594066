import { ReactComponent as CloseIcon } from '@/assets/close.svg'
import { ReactComponent as InfoIcon } from '@/assets/info.svg'
import { useAuthContext } from '@/providers'
import { useModal } from '@/utils'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('global.help'),
  }
}

export const DiaryHelpModal = () => {
  const messages = useMessages()
  const { currentPatient } = useAuthContext()
  const modalState = useModal(false)

  return (
    <div className="flex justify-end w-full">
      <InfoIcon
        fill="var(--b50)"
        height={20}
        width={20}
        className="cursor-pointer"
        onClick={modalState.open}
      />
      {modalState.isOpen &&
        createPortal(
          <div className="fixed top-0 bottom-0 left-0 right-0 animate-slide-up flex justify-center bg-sec0 z-[10000] pt-[60px] overflow-y-auto">
            <div className="subpage-header">
              <CloseIcon
                height={24}
                width={24}
                fill="var(--primary)"
                className="cursor-pointer"
                onClick={modalState.close}
              />
              {messages.title}
              <div />
            </div>
            <div className="max-w-[488px] flex flex-col gap-6 py-4 sm:p-4 w-full overflow-y-auto text-b50">
              <div className="text-h2reg text-b100">
                <Trans i18nKey="icuDiaryPage.help.title" className="text-h2" />
              </div>
              <Trans
                i18nKey="icuDiaryPage.help.text"
                values={{
                  patientName: `${currentPatient?.first_name} ${currentPatient?.last_name}`,
                  patientFirstName: currentPatient?.first_name,
                }}
              />
            </div>
          </div>,
          document.body,
        )}
    </div>
  )
}
