import { ReactComponent as CloseIcon } from '@/assets/close.svg'
import { InputHTMLAttributes, PropsWithChildren } from 'react'

type FileInputProps = PropsWithChildren<InputHTMLAttributes<HTMLInputElement>> & {
  value?: File
  onChange: (file?: File) => void
}

export const FileInput = ({ id, children, value, onChange, ...props }: FileInputProps) => (
  <>
    <label htmlFor={id}>{children}</label>
    <input
      id={id}
      type="file"
      className="hidden"
      onChange={(e) => onChange?.(e.target.files?.[0])}
      {...props}
    />
    {value && (
      <div className="flex justify-between gap-2 items-center">
        {value.name}
        <CloseIcon fill="var(--b100)" onClick={() => onChange(undefined)} />
      </div>
    )}
  </>
)
