import {
  Me,
  NotificationType,
  PatientListItem,
  Profile,
  RelativeListItem,
  SettingsType,
  User,
} from '@/api'
import { Error403, useSetErrorInterceptor } from '@/api/api'
import { PageLoader } from '@/components'
import { ExceededOnboardingQuotaPage, NotVerifiedPage } from '@/pages'
import {
  useAuthenticatedUser,
  useLogout,
  useNotifications,
  usePatientsList,
  useProfile,
  useProfilePicture,
  useRelativesList,
  useSettings,
} from '@/services'
import { areLoading, useItemModal } from '@/utils'
import i18next from 'i18next'
import { find, mergeLeft, pipe, prop, propEq } from 'ramda'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'
import { AnyObject } from 'yup'
import { useSnackbar } from './snackbarProvider'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    noRelativeAccount: t('global.noRelativeAccount'),
  }
}

type AuthContext = {
  user: User
  patients: PatientListItem[]
  relatives: RelativeListItem[]
  notifications: NotificationType[]
  arePatientsLoading: boolean
  currentPatient?: PatientListItem
  settings: SettingsType
}

export const useAuthContext = () => useOutletContext<AuthContext>()

export const AuthenticatedOutlet = <T extends AnyObject = AnyObject>({
  context,
}: {
  context?: T
}) => {
  const authContext = useAuthContext()

  return <Outlet context={{ ...(context || {}), ...authContext }} />
}

type OutletWrapperProps = {
  user: Me
  profile: Profile & { avatar: string | null }
  notifications: NotificationType[]
}

const OutletWrapper = ({ user, profile, notifications }: OutletWrapperProps) => {
  const { patientId } = useParams<{ patientId: string }>()
  const patientsResponse = usePatientsList()
  const settingsResponse = useSettings()

  const { data: patients = [] } = patientsResponse
  const currentPatient = patientId ? find(propEq(parseInt(patientId), 'id'), patients) : undefined

  const { data: relatives = [] } = useRelativesList(currentPatient)

  const memoizedContext = useMemo<Partial<AuthContext>>(() => {
    return {
      user: mergeLeft(user, profile),
      patients: patients,
      arePatientsLoading: patientsResponse.isLoading,
      currentPatient,
      notifications,
      relatives,
      settings: settingsResponse.data as SettingsType,
    }
  }, [
    JSON.stringify(user),
    JSON.stringify(profile),
    JSON.stringify(patients),
    JSON.stringify(notifications),
    JSON.stringify(settingsResponse.data),
    patientsResponse.isLoading,
    JSON.stringify(currentPatient),
    JSON.stringify(relatives),
  ])

  return <Outlet context={memoizedContext} />
}

export const AuthProvider = () => {
  const logout = useLogout()
  const snackbar = useSnackbar()
  const accessDeniedState = useItemModal<keyof typeof Error403>()
  const ready = useSetErrorInterceptor(accessDeniedState.openItemModal)
  const messages = useMessages()

  const userResponse = useAuthenticatedUser({ enabled: ready })
  const avatarResponse = useProfilePicture({
    enabled: ready,
  })
  const profileResponse = useProfile({
    enabled: ready,
    onSuccess: pipe(prop('language'), i18next.changeLanguage),
    onError: (err) => {
      if (err.response?.status === 404) {
        logout().then(() => {
          snackbar.error(messages.noRelativeAccount)
        })
      }
    },
  })
  const notificationsResponse = useNotifications({ enabled: ready })

  if (!ready || areLoading([userResponse, profileResponse])) return <PageLoader />

  if (accessDeniedState.isOpen) {
    switch (accessDeniedState.item) {
      case 'not_verified':
        return <NotVerifiedPage />
      case 'exceeded_onboarding_quota':
        return <ExceededOnboardingQuotaPage />
    }
  }

  return (
    <OutletWrapper
      user={userResponse.data as Me}
      profile={mergeLeft(
        profileResponse.data!,
        avatarResponse.error || !avatarResponse.data ? { avatar: null } : avatarResponse.data,
      )}
      notifications={notificationsResponse.data || []}
    />
  )
}
