import './sentry'

import ReactDOM from 'react-dom/client'
import { App } from './App'
import './components.css'
import './i18n'
import './index.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>
)
