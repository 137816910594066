import { PageLoader, Select, selectOptionType } from '@/components'
import { FormItem, useFormContext } from '@/providers'
import { useStationList } from '@/services'
import { map } from 'ramda'
import { OnboardingValues } from '.'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    label: t('global.ward'),
  }
}

export const OnboardingStationStep = () => {
  const { values } = useFormContext<Pick<OnboardingValues, 'hospital'>>()

  const { data = [], isLoading } = useStationList<selectOptionType[]>(values.hospital, {
    select: map((item) => ({ value: item.id, label: item.name })),
  })
  const messages = useMessages()

  if (isLoading) return <PageLoader />

  return (
    <FormItem<typeof Select>
      component={Select}
      name="station"
      label={messages.label}
      componentProps={{ options: data }}
    />
  )
}
