import { api } from './api'
import { RelationType } from './patient'
import { Profile } from './user'

export type SettingsType = {
  relationships: { value: RelationType; label: string }[]
  languages: { value: Profile['language']; label: string }[]
}

export const getSettings = () => api.get<SettingsType>('/contacts/hospitals/get_settings/')

export type DocumentType = {
  id: string
  name: string
  has_an_uploaded_document: boolean
  creation_time: string
}

export const getDocumentTypes = (patientId: string) =>
  api.get<DocumentType[]>(`/contacts/medical_documents/${patientId}/documents_types/`)

export const uploadDocuments = (id: string, file: File, document_request: string) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('document_request', document_request)
  formData.append('title', file.name)
  return api.post(`/contacts/medical_documents/${id}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
