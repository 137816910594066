import { isValidPhoneNumber } from 'libphonenumber-js'
import { object, string } from 'yup'

export const validationSchema = (messages: {
  firstName?: string
  firstNameInvalid?: string
  lastName?: string
  lastNameInvalid?: string
  inputPhoneNo?: string
  inputEmail?: string
  emailInvalid?: string
}) => {
  return object({
    first_name: string().required(messages.firstName).min(2, messages.firstNameInvalid),
    last_name: string().required(messages.lastName).min(2, messages.lastNameInvalid),
    phone: string().test('phone-check', `${messages.inputPhoneNo}`, (value) => {
      return Boolean(value && isValidPhoneNumber(value))
    }),
    email: string().required(messages.inputEmail).email(messages.emailInvalid),
  })
}
