// todo cleanup
import { resources } from '@/@types/resources'
import { ChatMessageType } from '@/api'
import { ReactComponent as ActiveVideoCallIcon } from '@/assets/active-meeting.svg'
import { ReactComponent as CloseIcon } from '@/assets/close.svg'
import { ReactComponent as DocumentIcon } from '@/assets/document.svg'
import { ReactComponent as GlobeIcon } from '@/assets/globe.svg'
import { ReactComponent as EndedVideoCallIcon } from '@/assets/inactive-meeting.svg'
import { ReactComponent as TickIcon } from '@/assets/tick.svg'
import { AudioPlayer } from '@/components'
import { useAuthContext, useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import { useTranslate, useUpdateProfile } from '@/services'
import { getLocalStorageItem, useModal, useNavigateWithFromState } from '@/utils'
import stringToHtml from 'html-react-parser'
import { useCallback } from 'react'
import { createPortal } from 'react-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Preview } from '../MediaPage/Preview'

const { languages } = resources['DE']['translation']

type ChatMessageContentType = Pick<
  ChatMessageType,
  | 'text'
  | 'is_notification'
  | 'media'
  | 'medical_document'
  | 'needs_to_be_translated'
  | 'original_language'
  | 'is_icu_diary_message'
> & {
  translation?: string
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    translatedFrom: (original_language: keyof typeof languages) =>
      t('chatMessage.translatedFrom', { original_language: t(`languages.${original_language}`) }),
    translatedFromGeneric: t('chatMessage.translatedFromGeneric'),
    showOriginal: t('chatMessage.showOriginal'),
    hideTranslation: t('chatMessage.hideTranslation'),
    showTranslation: t('chatMessage.showTranslation'),
    virtualVisitOn: t('chatMessage.virtualVisitOn'),
    virtualVisitEnded: t('chatMessage.virtualVisitEnded'),
    stopAsking: t('chatMessage.stopAsking'),
  }
}

const MEETING_STARTED_MESSAGE_IDENTIFIER = 'Started meeting, waiting for relatives to join'

const MEETING_ENDED_MESSAGE_IDENTIFIER = '[ended]'

const showTranslationKey = 'show-translation-ignore'
const showOriginalKey = 'show-original-ignore'

const AutoTranslationPrompt = ({
  onClose,
  autoTranslation,
}: {
  onClose: () => void
  autoTranslation: boolean
}) => {
  const updateProfileMutation = useUpdateProfile()

  const handleUpdateSettings = () => {
    updateProfileMutation.mutate(
      {
        user_settings: {
          auto_translation_enabled: !autoTranslation,
        },
      },
      {
        onSuccess: onClose,
      },
    )
  }

  const handleIgnore = () => {
    localStorage.setItem(autoTranslation ? showOriginalKey : showTranslationKey, String(true))
    onClose()
  }
  const messages = useMessages()

  return (
    <div className="flex items-center gap-2">
      <GlobeIcon width={20} height={20} className="shrink-0" />
      <div className="flex flex-col flex-1 text-textXS">
        {autoTranslation ? (
          <span>
            <Trans i18nKey={'chatMessage.alwaysOriginal'} />
          </span>
        ) : (
          <span>
            <Trans i18nKey={'chatMessage.alwaysTranslate'} />
          </span>
        )}
        <span className="underline cursor-pointer text-primary" onClick={handleIgnore}>
          {messages.stopAsking}
        </span>
      </div>
      <div className="flex shrink-0 gap-4 items-center">
        <TickIcon
          onClick={handleUpdateSettings}
          className="shrink-0 cursor-pointer"
          width={16}
          height={16}
          fill="var(--primary)"
        />
        <CloseIcon
          onClick={onClose}
          className="shrink-0 cursor-pointer"
          width={16}
          height={16}
          fill="var(--danger)"
        />
      </div>
    </div>
  )
}

const TranslatableChatMessage = ({
  text,
  original_language,
  translation,
  is_notification,
}: Pick<
  ChatMessageContentType,
  'text' | 'original_language' | 'translation' | 'is_notification'
>) => {
  const { patientId } = useParams()
  const snackbar = useSnackbar()
  const { user } = useAuthContext()

  const { isOpen, toggle } = useModal(false)
  const translationMutation = useTranslate(patientId!)

  const Snackbar = useCallback(
    ({ onClose }: { onClose: () => void }) => (
      <AutoTranslationPrompt
        onClose={onClose}
        autoTranslation={user.user_settings?.auto_translation_enabled!}
      />
    ),
    [user.user_settings?.auto_translation_enabled],
  )

  const handleClick = () => {
    if (!isOpen) {
      if (user.user_settings?.auto_translation_enabled && !getLocalStorageItem(showOriginalKey)) {
        snackbar.accent(Snackbar, 0)
      }
      if (!user.user_settings?.auto_translation_enabled) {
        if (!translation) {
          translationMutation.mutate({ messages: [text!] })
        }
        if (!getLocalStorageItem(showTranslationKey)) {
          snackbar.accent(Snackbar, 0)
        }
      }
    }
    toggle()
  }
  const messages = useMessages()

  const formatText = (textToFormat?: string) => {
    if (is_notification && textToFormat) return <div>{stringToHtml(textToFormat)}</div>
    return textToFormat
  }

  return (
    <>
      {formatText(user.user_settings?.auto_translation_enabled ? translation : text)}
      {isOpen && !translationMutation.isLoading && (
        <>
          <div className="w-full border-t-[1px] border-t-[#C1DBDF]" />
          {formatText(
            user.user_settings?.auto_translation_enabled
              ? text
              : translation || translationMutation.data?.[0],
          )}
        </>
      )}
      <div className="flex gap-2 items-center">
        <GlobeIcon className="shrink-0" />
        <span className="text-[12px] font-normal leading-4 text-int-grey-90">
          {!isOpen &&
            user.user_settings?.auto_translation_enabled &&
            messages.translatedFrom(original_language || 'DE')}
          {/*todo use language from the string and translate it to the local, language. if not*/}
          {/*existing in the translation files, use translatedFromGeneric*/}
          <span className="underline cursor-pointer text-[#55A0BE]" onClick={handleClick}>
            {isOpen
              ? messages.hideTranslation
              : user.user_settings?.auto_translation_enabled
              ? messages.showOriginal
              : messages.showTranslation}
          </span>
        </span>
      </div>
    </>
  )
}

export const ChatMessageContent = ({
  text,
  is_notification,
  media,
  medical_document,
  needs_to_be_translated,
  original_language,
  translation,
  is_icu_diary_message,
}: ChatMessageContentType) => {
  const { patientId } = useParams<{ patientId: string }>()
  const navigate = useNavigateWithFromState()

  const previewState = useModal(false)
  const messages = useMessages()

  // todo check
  if (previewState.isOpen)
    return createPortal(
      <Preview url={media?.link!} assetType={media?.type!} onClose={previewState.close} />,
      document.body,
    )

  if (text?.includes(MEETING_STARTED_MESSAGE_IDENTIFIER)) {
    const isActive = !text?.includes(MEETING_ENDED_MESSAGE_IDENTIFIER)

    const handleClick = () => {
      if (isActive) {
        navigate(`/${paths.home}/${patientId}/${paths.videoCall}`)
      }
    }

    return (
      <div
        className={`flex gap-4 items-center ${isActive ? 'cursor-pointer' : ''}`}
        onClick={handleClick}
      >
        {isActive ? (
          <ActiveVideoCallIcon width={40} height={40} />
        ) : (
          <EndedVideoCallIcon width={40} height={40} />
        )}
        <span className={!isActive ? 'text-b50' : 'text-primary'}>
          {!isActive ? messages.virtualVisitEnded : messages.virtualVisitOn}
        </span>
      </div>
    )
  }

  switch (media?.type) {
    case 'image':
      return (
        <div className="overflow-clip z-10 h-[200px]">
          <img
            className="rounded-[12px] cursor-pointer w-full h-full"
            src={media.link}
            alt="#"
            onClick={previewState.open}
          />
        </div>
      )
    case 'audio':
      return <AudioPlayer audio={media.link} sound_wave={media.sound_wave} />
    case 'video':
      return (
        <div className="z-10 h-[200px] cursor-pointer">
          <video
            onClick={previewState.open}
            className="rounded-[12px] w-full h-full"
            src={media.link}
            id="videoPreview"
            controls
          />
        </div>
      )
  }

  if (text) {
    if (is_icu_diary_message) return text

    if (needs_to_be_translated && (!original_language || original_language in languages))
      return (
        <TranslatableChatMessage
          text={text}
          original_language={original_language}
          translation={translation}
          is_notification={is_notification}
        />
      )

    if (is_notification) return <div>{stringToHtml(text)}</div>

    return text.replace(/&comma;/gi, ',')
  }

  if (medical_document) {
    return (
      <div className="flex flex-row items-center gap-2">
        <div className="bg-b0 rounded-[4px]">
          <DocumentIcon width={40} height={40} fill="var(--primary)" />
        </div>
        <div>{medical_document}</div>
      </div>
    )
  }

  return null
}
