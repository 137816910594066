/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSnackbar } from '@/providers'
import { AxiosError } from 'axios'
import { any, flatten, identity, is, pipe, pluck, propEq, propOr } from 'ramda'
import { useTranslation } from 'react-i18next'
import {
  MutationFunction,
  MutationOptions,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
  useMutation as useReactQueryMutation,
} from 'react-query'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    generalError: t('global.messageError'),
  }
}

type ReactQueryKeyType = typeof reactQueryKeys[keyof typeof reactQueryKeys]

export type ReactQueryKey = ReactQueryKeyType | [ReactQueryKeyType, ...string[]]

export type ReactQueryOptions<
  ApiResultType,
  K extends ReactQueryKey,
  ResultType = ApiResultType
> = Omit<UseQueryOptions<ApiResultType, AxiosError<any>, ResultType, K>, 'queryKey' | 'queryFn'>

export type PaginatedResponseType<Result> = {
  count: number
  next: string
  previous: string
  results: Result[]
}

export type PaginatedQueryParams = {
  limit: number
  offset: number
}

export type InfiniteQueryOptions<T> = Omit<
  Omit<
    UseInfiniteQueryOptions<
      PaginatedResponseType<T>,
      AxiosError,
      T[],
      PaginatedResponseType<T>,
      ReactQueryKey
    >,
    'queryKey' | 'queryFn'
  >,
  'select' | 'onSuccess'
> & {
  select?: (a: T[]) => T[]
  onSuccess?: (a: T[]) => void
}

export const reactQueryKeys = {
  settings: 'settings',
  session: 'session',
  me: 'me',
  profile: 'profile',
  patients: 'patients',
  hospitals: 'hospitals',
  stations: 'stations',
  invite: 'invite',
  notifications: 'notifications',
  avatar: 'avatar',
  notificationsConfig: 'notificationsConfig',
  otp: 'otp',
  relatives: 'relatives',
  superRelative: 'superRelative',
  chat: 'chat',
  chatMessages: 'chatMessages',
  pinnedMessages: 'pinnedMessages',
  meeting: 'meeting',
  meetingPrompt: 'meetingPrompt',
  media: 'media',
  textElements: 'textElements',
  documentTypes: 'documentTypes',
  survey: 'survey',
  appSurvey: 'appSurvey',
  icuDiary: 'icuDiary',
  patientInvite: 'patientInvite',
} as const

export const useInvalidateQuery = () => {
  const queryClient = useQueryClient()

  return (key: ReactQueryKey) => queryClient.invalidateQueries(key)
}

export const useCachedQuery = <T = any>(
  queryKey: ReactQueryKey,
  queryFn: () => Promise<T>,
  options?: ReactQueryOptions<T, typeof queryKey>,
  validateAfter: number = 0,
) => {
  const queryCache = useQueryClient()
  const { select = identity, ...rest } = options || {}
  // @ts-ignore
  const fetchedTime = window[JSON.stringify(queryKey)]

  const { data, ...query } = useQuery(
    queryKey,
    () =>
      new Promise<T>((resolve, reject) => {
        const now = Date.now()
        const cachedData = queryCache.getQueryData(queryKey) as T
        if (cachedData && fetchedTime && now < fetchedTime + validateAfter * 60 * 60 * 1000)
          resolve(cachedData)
        // @ts-ignore
        window[JSON.stringify(queryKey)] = now
        queryFn().then(resolve).catch(reject)
      }),
    rest,
  )

  return { data: select(data as T), ...query }
}

const getOffsetForQuery = (str: string) => {
  const index = str.indexOf('offset=')
  if (index !== -1) {
    return str.slice(index + 7)
  }
}

export const getDataFromInfinitiveQuery = pipe(propOr([], 'pages'), pluck('results'), flatten)

export type InfiniteQueryData<T> = Omit<UseInfiniteQueryResult<T[], AxiosError>, 'data'> & {
  data?: T[]
}

export const useInfiniteQueryData = <T>(
  key: ReactQueryKey,
  queryFn: QueryFunction<PaginatedResponseType<T>>,
  { select = identity, ...options }: InfiniteQueryOptions<T> = {},
) =>
  // @ts-ignore
  useInfiniteQuery<PaginatedResponseType<T>, AxiosError, T[], ReactQueryKey>(key, queryFn, {
    ...options,
    getNextPageParam: (lastPage) => {
      if (lastPage.next) {
        return getOffsetForQuery(lastPage.next)
      }
    },
    // @ts-ignore
    select: pipe(getDataFromInfinitiveQuery, select),
  }) as InfiniteQueryData<T>

export const useMutation = <VariableType, ResultType>(
  mutationFn: MutationFunction<ResultType, VariableType>,
  options?: MutationOptions<ResultType, AxiosError<any>, VariableType>,
) => useReactQueryMutation<ResultType, AxiosError<any>, VariableType>(mutationFn, options)

const makeSnackbarErrorMessage = (message: true | string, generalError: string) => {
  return is(Boolean, message) ? generalError : message
}

export const useInvalidateOnSuccessMutation = <VariableType, ResultType = any>(
  keyToInvalidate: ReactQueryKey,
  mutationFn: MutationFunction<ResultType, VariableType>,
  errorMessage?: true | string,
) => {
  const messages = useMessages()
  const invalidateQuery = useInvalidateQuery()
  const snackbar = useSnackbar()

  return useMutation(mutationFn, {
    onSuccess: () => {
      invalidateQuery(keyToInvalidate)
    },
    onError: () => {
      if (errorMessage) {
        snackbar.error(makeSnackbarErrorMessage(errorMessage, messages.generalError))
      }
    },
  })
}

export const useInvalidateOnSuccessMutations = <VariableType, ResultType>(
  keyToInvalidate: ReactQueryKey[],
  mutationFn: MutationFunction<ResultType, VariableType>,
  errorMessage?: true | string,
) => {
  const messages = useMessages()
  const invalidateQuery = useInvalidateQuery()
  const snackbar = useSnackbar()

  return useMutation(mutationFn, {
    onSuccess: () => {
      keyToInvalidate.forEach((key) => {
        invalidateQuery(key)
      })
    },
    onError: () => {
      if (errorMessage) {
        snackbar.error(makeSnackbarErrorMessage(errorMessage, messages.generalError))
      }
    },
  })
}

export const useOptimisticUpdatesWithQueryInvalidation = <VariableType>(
  keys: QueryKey[],
  mutationFn: MutationFunction<VariableType>,
  optimisticUpdateFn: (variables: VariableType, previousValue: any) => void,
) => {
  const queryClient = useQueryClient()

  const onMutate = async (variables: VariableType) => {
    await Promise.all(keys.map((key) => queryClient.cancelQueries(key)))

    return keys.map((key) => {
      const previousValue = queryClient.getQueryData(key)

      queryClient.setQueryData(key, optimisticUpdateFn(variables, previousValue))

      return previousValue
    })
  }

  const onError = (_: any, __: VariableType, previousValues: any) => {
    keys.forEach((key, i) => {
      queryClient.setQueryData(key, previousValues[i])
    })
  }

  const onSuccess = () => Promise.all(keys.map((key) => queryClient.invalidateQueries(key)))

  return useMutation(mutationFn, {
    onMutate,
    onError,
    onSuccess,
  })
}

export const isLoading = propEq(true, 'isLoading')

export const areLoading = any(isLoading)
