import { useFloatPositioning, useOutsideClick } from '@/utils'
import { PropsWithChildren, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export const Modal = ({
  title,
  onClose = () => {},
  children,
  parentElement = null,
}: PropsWithChildren<{
  title?: string
  onClose?: () => void
  parentElement?: HTMLDivElement | null
}>) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  useOutsideClick(ref, onClose)

  useFloatPositioning(ref, parentElement)

  useEffect(() => {
    if (ref) {
      ref.lastElementChild?.classList.add('overflow-auto')
    }
  }, [ref])

  return createPortal(
    <div className={`mask ${parentElement ? 'sm:items-end sm:px-0' : '!items-end !px-0'}`}>
      <div
        ref={setRef}
        className={`flex flex-col gap-4 p-6 bg-b0 rounded-[16px] backdrop-blur-[20px] shadow-[0_0.5px_0_0_#ECEFF0,0_6px_14px_0_rgba(0,0,0,0.05)] max-w-[488px] !min-h-max max-h-[90%] w-full ${
          parentElement ? 'sm:rounded-b-[0] sm:animate-slide-up' : 'rounded-b-[0] animate-slide-up'
        }`}
      >
        {title && (
          <div className="text-h2 overflow-hidden w-full shrink-0">
            <span className="ellipsis">{title}</span>
          </div>
        )}
        {children}
      </div>
    </div>,
    document.body,
  )
}
