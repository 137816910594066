import translation_ar from '@/locales/ar/translation.json'
import translation_de from '@/locales/de/translation.json'
import translation from '@/locales/en/translation.json'
import translation_es from '@/locales/es/translation.json'
import translation_it from '@/locales/it/translation.json'
import translation_ro from '@/locales/ro/translation.json'
import translation_ru from '@/locales/ru/translation.json'
import translation_tr from '@/locales/tr/translation.json'
import translation_uk from '@/locales/uk/translation.json'
import translation_vi from '@/locales/vi/translation.json'

export const resources = {
  AR: {
    translation: translation_ar,
  },
  EN: {
    translation: translation,
  },
  DE: {
    translation: translation_de,
  },
  ES: {
    translation: translation_es,
  },
  IT: {
    translation: translation_it,
  },
  RO: {
    translation: translation_ro,
  },
  RU: {
    translation: translation_ru,
  },
  TR: {
    translation: translation_tr,
  },
  UK: {
    translation: translation_uk,
  },
  VI: {
    translation: translation_vi,
  },
}
