import { useTrackEvent } from '@/services'
import { inc } from 'ramda'
import { useEffect, useRef, useState } from 'react'
import { useModal } from '.'
import { useCurrentRoute } from './reactRouter'

export const useTimer = (start?: boolean) => {
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (start) {
      interval = setInterval(() => {
        setSeconds(inc)
      }, 1000)
    } else {
      setSeconds(0)
    }

    return () => {
      clearInterval(interval)
    }
  }, [start])

  return seconds
}

type useCountDownProps = {
  duration: number
  initialStart: boolean
}

export const useCountDown = ({ initialStart, duration }: useCountDownProps) => {
  const countdownState = useModal(initialStart)
  const cooldown = useTimer(countdownState.isOpen)

  useEffect(() => {
    if (cooldown === duration) {
      countdownState.close()
    }
  }, [cooldown])

  return {
    value: duration - cooldown,
    start: countdownState.open,
    playing: countdownState.isOpen,
  }
}

export const useSeconds = (start?: boolean) => {
  const seconds = useTimer(start)
  const minutes = Math.floor(seconds / 60)

  return `${String(minutes).padStart(2, '0')}:${String(seconds % 60).padStart(2, '0')}`
}

export const useTrackTime = () => {
  const currentRoute = useCurrentRoute()
  const trackEventMutation = useTrackEvent()

  const intervalRef = useRef<NodeJS.Timeout>()

  useEffect(() => {
    let time = 0
    if (import.meta.env.PROD) {
      intervalRef.current = setInterval(() => {
        time += 1
      }, 1000)
    }

    return () => {
      if (import.meta.env.PROD) {
        // todo maybe move if we want to re-use time track for any other reason
        trackEventMutation.mutate({
          event: 'page_viewed',
          properties: {
            route: currentRoute,
            duration_in_seconds: time,
          },
        })
        clearInterval(intervalRef.current)
      }
    }
  }, [currentRoute])
}
