import { ChatType } from '@/api'
import { ReactComponent as PlusIcon } from '@/assets/plus.svg'
import { ReactComponent as SendIcon } from '@/assets/send.svg'
import { IconButton } from '@/components'
import { useAuthContext, useSurveyContext } from '@/providers'
import { useSendMessage } from '@/services'
import { useModal } from '@/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextElements } from './TextElements'

type ChatPanelProps = {
  type: ChatType['chat_communication_method_for_relatives']
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    sendMessage: t('chatMessage.sendMessage'),
    sendTextElements: t('chatMessage.sendTextElements'),
    writeMessagesInLanguage: t('chatMessage.writeMessagesInLanguage'),
  }
}

export const ChatPanel = ({ type }: ChatPanelProps) => {
  const { currentPatient } = useAuthContext()
  const surveyContext = useSurveyContext()
  const [message, setMessage] = useState('')
  const textElementsState = useModal(false)

  const sendMessageMutation = useSendMessage(currentPatient?.id!)
  const messages = useMessages()

  const disabled = !message.length

  const handleInputClick = () => {
    if (type !== 'FREE_TEXT_AND_TEXT_ELEMENTS') {
      textElementsState.open()
    }
  }

  const handleSubmit = (text: string) => {
    if (!sendMessageMutation.isLoading) {
      sendMessageMutation.mutate(text, {
        onSuccess: () => {
          setMessage('')
          textElementsState.close()
          surveyContext.triggerSurvey()
        },
      })
    }
  }

  const handleSendFreeText = () => {
    if (!disabled) {
      handleSubmit(message)
    }
  }

  return (
    <div className="p-2 bg-sec0 fixed bottom-0 left-[108px] sm:bottom-[92px] sm:left-0 right-0 flex flex-col gap-2">
      {type === 'FREE_TEXT_AND_TEXT_ELEMENTS' && (
        <div className="flex items-center gap-1 text-b50 text-textXS">
          {messages.writeMessagesInLanguage}
        </div>
      )}
      <div className="relative bg-b0 flex items-center gap-4 px-4 py-3 rounded-[32px] border-[1px] border-sec10 shadow-[0_0.5px_0_0_#ECEFF0,0_6px_14px_0_rgba(0,0,0,0.05)]">
        <IconButton size={32} icon={PlusIcon} onClick={textElementsState.open} />
        <input
          readOnly={type !== 'FREE_TEXT_AND_TEXT_ELEMENTS'}
          onClick={handleInputClick}
          placeholder={
            type === 'FREE_TEXT_AND_TEXT_ELEMENTS'
              ? messages.sendMessage
              : messages.sendTextElements
          }
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className={`flex-1 text-b100 text-[14px] outline-none ${
            type === 'ONLY_TEXT_ELEMENTS' ? 'cursor-pointer' : ''
          }`}
        />
        {type === 'FREE_TEXT_AND_TEXT_ELEMENTS' && (
          <SendIcon
            onClick={handleSendFreeText}
            width={24}
            height={24}
            className={`shrink-0 transition-all ${
              disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            stroke={disabled ? 'var(--sec10)' : 'var(--primary)'}
          />
        )}
      </div>
      {textElementsState.isOpen && (
        <TextElements onSubmit={handleSubmit} onClose={textElementsState.close} />
      )}
    </div>
  )
}
