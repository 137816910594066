import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { PrimaryButton } from '@/components'
import { config } from '@/config'
import { FormItem, FormProvider } from '@/providers'
import { paths } from '@/routes/paths'
import { useRequestResetPassword } from '@/services'
import { useItemModal } from '@/utils'
import { FormikConfig } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { object, string } from 'yup'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('forgotPasswordPage.title'),
    button: t('forgotPasswordPage.button'),
    hint: t('forgotPasswordPage.hint'),
    forgotPassword: t('loginPage.forgotPassword'),
    successTitle: t('forgotPasswordPage.successState.title'),
    successText: t('forgotPasswordPage.successState.text'),
    email: t('profilePage.email'),
    validations: {
      email: t('profilePage.validations.inputEmail'),
      invalidEmail: t('profilePage.validations.emailInvalid'),
    },
  }
}

const validationSchema = (messages: ReturnType<typeof useMessages>['validations']) =>
  object({
    email: string().required(messages.email).email(messages.invalidEmail),
  })

export const ForgotPasswordPage = () => {
  const messages = useMessages()
  const navigate = useNavigate()
  const requestResetPasswordMutation = useRequestResetPassword()
  const successState = useItemModal('')

  const handleSubmit: FormikConfig<{ email: string }>['onSubmit'] = (body, { setErrors }) => {
    requestResetPasswordMutation.mutate(body.email, {
      onError: (error) => {
        setErrors({ email: error.message })
      },
      onSuccess: () => successState.openItemModal(body.email),
    })
  }

  return (
    <div className="page flex items-center justify-center pt-[60px] bg-sec0 sm:items-start">
      <div className="subpage-header">
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={() => navigate(`/${paths.auth}/${paths.login}`)}
        />
        <div className="text-textM">{messages.title}</div>
        <div />
      </div>
      <div className="max-w-[488px] flex flex-col gap-8 items-center p-6">
        <h2 className="text-h2reg text-center">
          {successState.isOpen ? messages.successTitle : messages.forgotPassword}
        </h2>
        <span className="text-center text-b90 whitespace-pre-line">
          {successState.isOpen ? messages.successText : messages.hint}
          {successState.isOpen && <span className="underline text-b100">{successState.item}</span>}
        </span>
        {successState.isOpen ? (
          <div className="text-center text-textS text-b90">
            <Trans
              i18nKey="forgotPasswordPage.successState.hint"
              values={{
                mail: config.supportEmail,
                phone: config.contactPhone,
              }}
              components={[
                <a className="underline" href={`mailto:${config.supportEmail}`} />,
                <a className="underline" href={`tel:${config.contactPhone}`} />,
              ]}
            />
          </div>
        ) : (
          <FormProvider<{ email: string }>
            initialValues={{ email: '' }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema(messages.validations)}
            className="w-full flex flex-col gap-8"
          >
            <FormItem
              name="email"
              label={`${messages.email}*`}
              componentProps={{ inputType: 'email' }}
            />
            <PrimaryButton disabled={requestResetPasswordMutation.isLoading} type="submit" block>
              {messages.button}
            </PrimaryButton>
          </FormProvider>
        )}
      </div>
    </div>
  )
}
