import { find, findIndex, not, propEq } from 'ramda'
import { useState } from 'react'
import { AnyObject } from 'yup'

export const useModal = (defaultValue: boolean) => {
  const [isOpen, setIsOpen] = useState(defaultValue)

  const open = () => {
    setIsOpen(true)
  }

  const close = () => {
    setIsOpen(false)
  }

  const toggle = () => {
    setIsOpen(not)
  }

  return { isOpen, open, close, toggle }
}

export const useItemModal = <T>(defaultValue?: T) => {
  const { isOpen, open, close } = useModal(Boolean(defaultValue))
  const [item, setItem] = useState(defaultValue)

  const openItemModal = (newItem?: T) => {
    setItem(newItem)
    open()
  }

  const closeItemModal = () => {
    setItem(defaultValue)
    close()
  }

  return { isOpen, item, openItemModal, closeItemModal }
}

type useStepsProps<T> = {
  steps: T[]
  keyPropName: keyof T
  initialStep?: T[keyof T]
}

export const useSteps = <T extends AnyObject = AnyObject>({
  initialStep,
  steps,
  keyPropName,
}: useStepsProps<T>) => {
  const getStep = (i: number) => steps[i][keyPropName]

  const [step, setStep] = useState(initialStep || getStep(0))

  const isCurrentStep = propEq(step, keyPropName)

  const data = find(isCurrentStep, steps)
  const index = findIndex(isCurrentStep, steps)

  const total = steps.length

  const isLast = index === total - 1

  const isFirst = index === 0

  const back = () => setStep(getStep(index - 1))

  const next = () => setStep(getStep(index + 1))

  const goTo = setStep

  const reset = () => goTo(initialStep || getStep(0))

  return { step, isLast, isFirst, index, total, data, back, next, goTo, reset }
}

type useAnimationProps = {
  onLeave: () => void
  duration: number
  defaultOpened: boolean
}

export const useAnimation = ({ onLeave, duration, defaultOpened }: useAnimationProps) => {
  const { isOpen, open, close } = useModal(defaultOpened)
  const leavingState = useModal(false)

  const leave = () => {
    leavingState.toggle()
    setTimeout(() => {
      close()
      onLeave()
      leavingState.toggle()
    }, duration)
  }

  return { isOpen, isLeaving: leavingState.isOpen, leave, open }
}
