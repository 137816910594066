import { AuthTroubleshootLayout, PageLoader } from '@/components'
import { useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import { useResendVerificationEmail, useVerifyEmail } from '@/services'
import { useModal } from '@/utils'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('verifyEmailPage.title'),
    text: t('verifyEmailPage.text'),
    button: t('verifyEmailPage.button'),
    success: t('verifyEmailPage.snackSuccess'),
    btnResend: t('notVerifiedPage.button'),
  }
}

export const VerifyEmailPage = () => {
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { token } = useParams<{ token: string }>()
  const errorState = useModal(!token)
  const verifyEmailMutation = useVerifyEmail(token as string)
  const resendVerificationEmailMutation = useResendVerificationEmail()
  const messages = useMessages()

  const goToDashboard = () => {
    navigate(`/${paths.home}`)
  }

  const handleClick = () => {
    resendVerificationEmailMutation.mutate({})
  }

  useEffect(() => {
    if (token) {
      verifyEmailMutation.mutate(
        {},
        {
          onSuccess: () => {
            snackbar.success(messages.success)
            goToDashboard()
          },
          onError: () => {
            navigate(`/${paths.verifyEmail}`)
          },
        },
      )
    } else {
      errorState.open()
    }
  }, [token])

  if (errorState.isOpen)
    return (
      <div>
        <AuthTroubleshootLayout
          title={`${messages.title}`}
          buttonText={`${messages.btnResend}`}
          onClick={handleClick}
          linkBottomText={messages.button}
          linkBottomRedirectPath={`/${paths.auth}/${paths.register}`}
        >
          <Trans i18nKey="verifyEmailPage.text" />
        </AuthTroubleshootLayout>
      </div>
    )

  return <PageLoader />
}
