import { head } from 'ramda'

type AvatarProps = {
  size?: number
  onClick?: () => void
} & (
  | { src: string | null; first_name?: string; last_name?: string }
  | { first_name: string; last_name: string; src?: string | null }
)

export const Avatar = ({ src, first_name, last_name, size = 46, onClick }: AvatarProps) => {
  return (
    <div
      className="cursor-pointer flex items-center justify-center object-contain overflow-hidden shrink-0 rounded-[50%]"
      style={{
        width: `${size}px`,
        height: `${size}px`,
        background: src ? '' : 'var(--sec30)',
      }}
      onClick={onClick}
    >
      {src ? (
        <img src={src} alt={`${first_name} ${last_name}`} className="w-full object-cover" />
      ) : (
        <span
          className="text-b90 uppercase"
          style={{
            fontSize: `${size / 2.5}px`,
          }}
        >
          {head(first_name || '')} {head(last_name || '')}
        </span>
      )}
    </div>
  )
}
