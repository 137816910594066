import { ReactNode } from 'react'
import { useSession } from '../services'
import { PageLoader } from '@/components'

export type AuthGuardProps = {
  children: ReactNode
  fallback?: ReactNode
}

export const AuthGuard = ({ children, fallback = null }: AuthGuardProps) => {
  const { data, isLoading } = useSession()

  if (isLoading) return <PageLoader />

  if (data?.isAuthenticated) return children

  return fallback
}
