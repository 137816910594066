import { ReactComponent as Close } from '@/assets/arrow-left.svg'
import { ReactComponent as CameraSwitch } from '@/assets/camera-switch.svg'
import { useSnackbar } from '@/providers'
import { useModal, useSeconds } from '@/utils'
import { append, filter, pipe, propEq } from 'ramda'
import { useCallback, useEffect, useRef, useState } from 'react'
import { isAndroid, isMobile } from 'react-device-detect'
import Webcam from 'react-webcam'
import { useTranslation } from 'react-i18next'

type CameraProps = {
  onCapture: (a: Blob) => void
  onClose: () => void
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    cameraPermissionError: t('global.cameraPermissionDeniedError'),
  }
}

const getAcceptRatio = () => {
  const isLandscape = window.innerHeight <= window.innerWidth
  return isLandscape
    ? window.innerWidth / window.innerHeight
    : window.innerHeight / window.innerWidth
}

export const VideoRecorder = ({ onCapture, onClose }: CameraProps) => {
  const snackbar = useSnackbar()
  const facingModeState = useModal(false)
  const ref = useRef<Webcam>(null)
  const mediaRecorderRef = useRef<MediaRecorder>(null)
  const submitState = useModal(false)
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([])
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const recordState = useModal(false)
  const messages = useMessages()

  const time = useSeconds(recordState.isOpen)

  const handleError = () => {
    snackbar.error(messages.cameraPermissionError)
    onClose()
  }

  const handleDevices = useCallback(
    pipe<[MediaDeviceInfo[]], MediaDeviceInfo[], void>(
      filter(propEq('videoinput', 'kind')),
      setDevices,
    ),
    [setDevices],
  )

  const onStartCapture = useCallback(() => {
    if (ref.current?.stream) {
      // @ts-ignore
      mediaRecorderRef.current = new MediaRecorder(ref.current.stream)
      mediaRecorderRef.current.addEventListener('dataavailable', ({ data }) => {
        setRecordedChunks(append(data))
      })
      mediaRecorderRef.current.start()
      recordState.open()
    }
  }, [ref, mediaRecorderRef])

  const onStopCapture = useCallback(() => {
    mediaRecorderRef.current?.stop()
    recordState.close()
  }, [mediaRecorderRef])

  useEffect(() => onStopCapture, [])

  const handleCapture = () => {
    if (!submitState.isOpen) {
      if (recordState.isOpen) {
        onStopCapture()
        setTimeout(submitState.open, 1000)
      } else {
        onStartCapture()
      }
    }
  }

  useEffect(() => {
    if (submitState.isOpen && recordedChunks.length) {
      const blob = new Blob(recordedChunks)
      onCapture(blob)
      onClose()
    }
  }, [submitState.isOpen, recordedChunks])

  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 bg-b100">
      <Webcam
        ref={ref}
        audio
        muted
        className={`w-full h-full object-cover transition-all ${
          facingModeState.isOpen ? 'animate-camera-back' : 'animate-camera-front'
        }`}
        videoConstraints={{
          facingMode: facingModeState.isOpen ? 'environment' : 'user',
          width: isMobile && isAndroid ? window.innerHeight : window.innerWidth,
          height: isMobile && isAndroid ? window.innerWidth : window.innerHeight,
          aspectRatio: getAcceptRatio(),
        }}
        onUserMedia={() =>
          navigator.mediaDevices.enumerateDevices().then(handleDevices).catch(handleError)
        }
        mirrored={!facingModeState.isOpen}
        onUserMediaError={handleError}
      />
      <div className="backdrop-blur-[5px] flex flex-col-reverse gap-[56px] justify-center items-center w-[108px] border-l-[1px] border-b10 fixed top-0 right-0 bottom-0 sm:flex-row sm:top-[unset] sm:w-screen sm:px-4 sm:gap-2 sm:justify-between sm:border-t-[1px] sm:border-l-0 sm:h-[92px] sm:items-center sm:pt-2">
        <div className="flex items-center justify-center w-[48px] h-[48px] border-[1px] border-b0 rounded-[50%] bg-b90">
          <Close
            width={24}
            height={24}
            fill="var(--b0)"
            className="cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="flex flex-col items-center gap-0">
          <div className="flex w-[64px] h-[64px] shrink-0 items-center justify-center cursor-pointer p-1 border-4 border-b0 rounded-[50%]">
            <div
              className={` bg-danger transition-all ${
                recordState.isOpen
                  ? 'rounded-[8px] w-[36px] h-[36px]'
                  : 'rounded-[50%] w-full h-full'
              }`}
              onClick={handleCapture}
            />
          </div>
          {recordState.isOpen && <div className="text-b0">{time}</div>}
        </div>
        {devices.length > 1 && !recordState.isOpen ? (
          <div className="flex items-center justify-center w-[48px] h-[48px] border-[1px] border-b0 rounded-[50%] bg-b90">
            <CameraSwitch
              width={24}
              height={24}
              fill="var(--b0)"
              className="cursor-pointer"
              onClick={facingModeState.toggle}
            />
          </div>
        ) : (
          <div className="w-[48px]" />
        )}
      </div>
    </div>
  )
}
