import { useEffect } from 'react'
import { AnyObject } from 'yup'

export const webviewMessageTypes = {
  joinMeeting: 'joinMeeting',
  meetingEnded: 'meetingEnded',
  startAudioRecord: 'startAudioRecord',
  stopAudioRecord: 'stopAudioRecord',
  openInNewTab: 'openInNewTab',
  login: 'login',
  logout: 'logout',
  meetingPrompt: 'meetingPrompt',
} as const

export const postMessageToWebview = ({
  type,
  params = {},
  callback,
}: {
  type: keyof typeof webviewMessageTypes
  params?: AnyObject
  callback?: () => void
}) => {
  // @ts-ignore
  if (window.ReactNativeWebView) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify({ type, ...params }))
  } else {
    callback?.()
  }
}

export const useWebviewMessageListener = (
  handleNativeMessage: ({ data }: MessageEvent) => void,
  onUnmount?: () => void,
) =>
  useEffect(() => {
    window.addEventListener('message', handleNativeMessage)
    // @ts-ignore
    document.addEventListener('message', handleNativeMessage)

    return () => {
      onUnmount?.()
      window.removeEventListener('message', handleNativeMessage)
      // @ts-ignore
      document.removeEventListener('message', handleNativeMessage)
    }
  }, [])
