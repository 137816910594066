import {
  ChatMessageType,
  PinnedMessage,
  TextElementType,
  TranslateBody,
  getChat,
  getChatMessages,
  getPinnedMessages,
  getTextElements,
  sendMessage,
  translateMessages,
  updateLikeMessage,
} from '@/api'
import {
  InfiniteQueryOptions,
  ReactQueryKey,
  ReactQueryOptions,
  reactQueryKeys,
  useCachedQuery,
  useInfiniteQueryData,
  useInvalidateOnSuccessMutation,
  useMutation,
} from '@/utils'
import { useQuery } from 'react-query'

export const useChatMessages = (
  chatId?: string,
  options: InfiniteQueryOptions<ChatMessageType> = {},
) =>
  useInfiniteQueryData<ChatMessageType>(
    [reactQueryKeys.chatMessages, chatId as string],
    ({ pageParam = 0 }) => getChatMessages(chatId as string, { offset: pageParam, limit: 10 }),
    { enabled: Boolean(chatId), ...options },
  )

export const useChat = (patientId: string, options: InfiniteQueryOptions<ChatMessageType> = {}) => {
  const { data, isFetching } = useQuery([reactQueryKeys.chat, patientId], () => getChat(patientId))
  const chatMessages = useChatMessages(data?.id, options)

  return {
    ...chatMessages,
    isFetching: chatMessages.isFetching || isFetching,
    data: {
      ...data,
      messages: chatMessages.data || [],
    },
  }
}

export const usePinnedMessages = (
  patientId: string,
  options?: ReactQueryOptions<PinnedMessage, ['pinnedMessages', string]>,
) =>
  useQuery([reactQueryKeys.pinnedMessages, patientId], () => getPinnedMessages(patientId), options)

export const useLikeMessage = (id: ChatMessageType['id']) =>
  useMutation((like: boolean) => updateLikeMessage(id, like))

// todo optimize
export const useSendMessage = (patientId: number) =>
  useInvalidateOnSuccessMutation(
    'chatMessages',
    (text: string) => sendMessage(patientId, text),
    true,
  )

export const useTextElements = (
  patientId: number,
  options?: ReactQueryOptions<TextElementType[], ReactQueryKey>,
) =>
  useCachedQuery(
    [reactQueryKeys.textElements, String(patientId)],
    () => getTextElements(patientId),
    options,
    8,
  )

export const useTranslate = (patientId: string) =>
  useMutation<TranslateBody, string[]>((body) => translateMessages(patientId, body))
