import { ReactComponent as InfoIcon } from '@/assets/info.svg'
import {
  CardSelect,
  Checkbox,
  FileInput,
  Input,
  Password,
  PhoneInput,
  PrimaryButton,
  Select,
  TextArea,
} from '@/components'
import { Form, Formik, FormikConfig, useField, useFormikContext } from 'formik'
import { __, equals, ifElse, isEmpty, pick, prop } from 'ramda'
import {
  ChangeEvent,
  ComponentProps,
  FocusEventHandler,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import { AnyObject } from 'yup'

export type FormProviderProps<T> = FormikConfig<T> & {
  className?: string
  children: ReactNode
}

export type FormSubmitType<T> = FormikConfig<T>['onSubmit']

export const FormProvider = <Values extends AnyObject = AnyObject>({
  children,
  className,
  ...props
}: FormProviderProps<Values>) => (
  <Formik validateOnChange {...props}>
    <Form className={className}>{children}</Form>
  </Formik>
)

type formItemComponentType =
  | typeof Input
  | typeof Select
  | typeof Password
  | typeof PhoneInput
  | typeof Checkbox
  | typeof CardSelect
  | typeof TextArea
  | typeof FileInput

type FormItemProps<C extends formItemComponentType = typeof Input> = {
  name: string
  component?: C
  label?: string
  helperText?: string
  valuePropName?: 'value' | 'checked'
  componentProps?: Omit<
    ComponentProps<C>,
    'value' | 'name' | 'id' | 'error' | 'onBlur' | 'onChange' | 'checked'
  >
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  animateLabel?: boolean
}

export const FormItem = <C extends formItemComponentType = typeof Input>({
  // @ts-ignore
  component: Component = Input,
  name,
  helperText,
  label,
  componentProps,
  onBlur: onBlurAddOn,
  animateLabel = true,
  valuePropName = 'value',
  children,
}: PropsWithChildren<FormItemProps<C>>) => {
  const ref = useRef<HTMLDivElement>(null)
  const { error, touched, onBlur, onChange, value } = useFormItem(name)

  const hasError = Boolean(touched && typeof error !== 'undefined')

  useEffect(() => {
    // todo cleanup
    if (name === 'email' && (value || '').includes(' ')) {
      onChange(value.replace(' ', ''))
    }
  }, [value])

  return (
    <div ref={ref} className="flex flex-col gap-1 relative w-full">
      {label && (
        <label
          id="label"
          className={`absolute pointer-events-none transition-all duration-[400ms] left-4 z-[3] capitalize ${
            hasError ? 'text-danger' : 'text-b50'
          } ${
            value ||
            !animateLabel ||
            (ref.current?.querySelector(`#${name}`) as HTMLInputElement)?.value
              ? '-top-[6px] px-1 before:content-[""] before:w-full before:absolute before:h-[1px] before:bg-b0 before:top-[6px] before:left-0 before:z-[-1]'
              : 'text-placeholder top-[14px]'
          }`}
        >
          {label}
        </label>
      )}
      {/* @ts-ignore */}
      <Component
        {...(componentProps || {})}
        {...{ [valuePropName]: value }}
        id={name}
        name={name}
        onChange={onChange}
        onBlur={(e) => {
          onBlur(e)
          onBlurAddOn?.(e)
        }}
        error={hasError}
      >
        {children}
      </Component>
      {hasError && error ? (
        <span className="text-inputDescription text-danger flex items-center gap-1">
          <InfoIcon className="shrink-0" fill="var(--danger)" width={16} height={16} />
          {error}
        </span>
      ) : (
        helperText && (
          <span className="text-inputDescription text-b50 flex items-center gap-1">
            <InfoIcon className="shrink-0" fill="var(--b50)" width={16} height={16} />
            {helperText}
          </span>
        )
      )}
    </div>
  )
}

export const FormSubmit = <FormValues extends AnyObject = AnyObject>({
  children,
  ...props
}: PropsWithChildren<Omit<ComponentProps<typeof PrimaryButton>, 'type' | 'onClick'>>) => {
  const formikState = useFormContext<FormValues>()
  const { isSubmitting, isValid, hasChanges } = formikState

  return (
    <PrimaryButton type="submit" disabled={isSubmitting || !isValid || !hasChanges} {...props}>
      {children}
    </PrimaryButton>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any
export const useFormItem = <T extends any = any>(name: string) => {
  const [{ onChange, onBlur }, { value, error, touched }, { setValue }] = useField<T>({ name })

  const handleChange = (e: ChangeEvent<HTMLInputElement> | typeof value) => {
    if ((e as ChangeEvent<HTMLInputElement>)?.target) {
      onChange(e)
    } else {
      setValue(e as T)
    }
  }

  return { value, onChange: handleChange, error, touched, onBlur }
}

type customValidateType<T> = {
  fields: [keyof T, ...(keyof T)[]]
  onSuccess: (x?: Partial<T>) => void
  onFail: () => void
}

export const useFormContext = <T extends AnyObject = AnyObject>() => {
  const formikContext = useFormikContext<T>()
  const [validationState, setValidationState] = useState<
    customValidateType<T> | Record<string, unknown>
  >({})

  useEffect(() => {
    if (!isEmpty(validationState)) {
      const { fields, onSuccess, onFail } = validationState as customValidateType<T>

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const pickFields: any = pick(fields)

      if (isEmpty(pickFields(formikContext.errors))) {
        onSuccess(pickFields(formikContext.values))
      } else {
        onFail()
      }

      setValidationState({})
    }
  }, [formikContext.errors, validationState])

  const customValidate = async ({
    fields,
    onSuccess,
    onFail = () => {},
  }: customValidateType<T>) => {
    if (!fields || isEmpty(fields)) {
      formikContext.validateForm().then(ifElse(isEmpty, onSuccess, onFail))
    } else {
      await Promise.all(fields.map((val) => formikContext.validateField(val as string)))
      setValidationState({ fields, onSuccess, onFail })
    }
  }
  const value = prop(__, formikContext.values)

  const hasChanges = !equals(formikContext.values, formikContext.initialValues)

  const areFieldsValid = (fields: [keyof T, ...(keyof T)[]]) =>
    !equals(pick(fields, formikContext.values), pick(fields, formikContext.initialValues)) &&
    isEmpty(pick(fields, formikContext.errors))

  return { customValidate, value, hasChanges, areFieldsValid, ...formikContext }
}
