import { ReactComponent as WaitingIcon } from '@/assets/cloud-waiting.svg'
import { Alert } from '@/components'
import { useModal } from '@/utils'
import { Trans, useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('waitingPage.title'),
    whySoLong: t('waitingPage.whySoLong'),
    text2: t('waitingPage.text2'),
    whySoLongAnswer1: t('waitingPage.whySoLongAnswer1'),
    whySoLongAnswer2: t('waitingPage.whySoLongAnswer2'),
    whySoLongAnswer3: t('waitingPage.whySoLongAnswer3'),
    whySoLongAnswer4: t('waitingPage.whySoLongAnswer4'),
  }
}

export const WaitingConfirmationPage = () => {
  const alertState = useModal(false)
  const messages = useMessages()

  return (
    <div className="flex flex-col items-center justify-center p-6 h-full">
      <div className="h-min flex flex-col max-w-[488px] gap-4">
        <div className="flex flex-col gap-2 items-center sm:items-start">
          <WaitingIcon className="shrink-0" width={28} height={20} />
          <div className="text-h2 text-left">{messages.title}</div>
          <div>
            <Trans i18nKey={'waitingPage.text1'} />
            <br />
            <br />
            {messages.text2}
          </div>
        </div>
        <div className="w-full text-center py-8">
          <span className="text-primary underline cursor-pointer text-h4" onClick={alertState.open}>
            {messages.whySoLong}
          </span>
        </div>
      </div>
      {alertState.isOpen && (
        <Alert
          title={messages.whySoLong}
          onClose={alertState.close}
          contents={[
            messages.whySoLongAnswer1,
            messages.whySoLongAnswer2,
            messages.whySoLongAnswer3,
            messages.whySoLongAnswer4,
          ]}
        />
      )}
    </div>
  )
}
