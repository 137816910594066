import { keys } from 'ramda'
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom'
import { makeQueryString } from '.'

export const useNavigateWithFromState = () => {
  const location = useLocation()
  const navigate = useNavigate()

  return (path: string, state = {}) => navigate(path, { state: { from: location, ...state } })
}

export const useNavigateToPreviousLocation = () => {
  const navigate = useNavigate()
  const location = useLocation() as Omit<Location, 'state'> & { state?: { from?: Location } }

  const { state } = location
  const { from } = state || {}

  return (fallbackPath: string, search = {}) =>
    navigate(
      (from?.pathname ? `${from.pathname}${from.search}` : fallbackPath) + makeQueryString(search),
    )
}

export const useCurrentRoute = () => {
  const location = useLocation()
  const params = useParams()

  let pathname = location.pathname

  if (!pathname.endsWith('/')) {
    pathname += '/'
  }

  keys(params).forEach((key) => (pathname = pathname.replace(`/${params[key]}/`, `/:${key}/`)))

  return pathname
}
