import dayjs from 'dayjs'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from './@types/resources'
import { Profile } from './api'

i18next.on('languageChanged', async (lng: Profile['language']) => {
  switch (lng) {
    case 'AR':
      await import('dayjs/locale/ar.js')
      break
    case 'DE':
      await import('dayjs/locale/de.js')
      break
    case 'EN':
      await import('dayjs/locale/en.js')
      break
    case 'ES':
      await import('dayjs/locale/es.js')
      break
    case 'IT':
      await import('dayjs/locale/it.js')
      break
    case 'RO':
      await import('dayjs/locale/ro.js')
      break
    case 'RU':
      await import('dayjs/locale/ru.js')
      break
    case 'TR':
      await import('dayjs/locale/tr.js')
      break
    case 'UK':
      await import('dayjs/locale/uk.js')
      break
    case 'VI':
      await import('dayjs/locale/vi.js')
      break
    default:
      await import('dayjs/locale/de.js')
  }

  dayjs.locale(lng.toLowerCase())
})

i18next.use(initReactI18next).init({
  lng: 'DE',
  debug: import.meta.env.DEV,
  resources,
  // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
  // set returnNull to false (and also in the i18next.d.ts options)
  // returnNull: false,
})
