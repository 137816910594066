import { makeQueryString } from '@/utils'
import { api } from './api'

export type SurveyGroupTarget = 'patient' | 'relative'

export type SurveyQuestionType = {
  id: string
  question: string
} & (
  | {
      question_type: 'MULTI_SELECT'
      possible_answers: string[]
    }
  | {
      question_type: 'RATING' | 'FREE_TEXT' | 'BOOLEAN_SELECT'
      possible_answers: null
    }
)

export type SurveyType = {
  id: string
  answered_at: string | null
  questions: SurveyQuestionType[]
}

export type SurveyAnswer = string | number[] | 1 | 2 | 3 | 4 | 5 | boolean
export type SurveyAnswers = Record<string, SurveyAnswer>

export const getSurvey = (target_group: SurveyGroupTarget = 'relative') =>
  api.get<SurveyType>(`/reactions/product-surveys/me/${makeQueryString({ target_group })}`)

export const submitSurveyAnswers = (
  surveyId: string,
  answers: SurveyAnswers,
  target_group: SurveyGroupTarget = 'relative',
) =>
  api.post(
    `/reactions/product-surveys/${surveyId}/submit_survey_answers/${makeQueryString({
      target_group,
    })}`,
    { answers },
  )
