import { IcuDiaryItem } from '@/api'
import { useModal } from '@/utils'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    hour: t('global.hour'),
  }
}
export const IcuDiaryElement = ({ image, text, creation_time }: Omit<IcuDiaryItem, 'id'>) => {
  const messages = useMessages()
  const openState = useModal(false)

  const date = useMemo(() => dayjs(creation_time), [creation_time])

  return (
    <div
      className={`overflow-hidden w-full rounded-[8px] cursor-pointer flex flex-col shrink-0 bg-b0 transition-all ${
        openState.isOpen ? 'border-[1px] border-accent' : ''
      }`}
      onClick={openState.toggle}
    >
      <img
        alt={image}
        src={image}
        height={120}
        width="100%"
        className="object-cover h-[120px] w-full"
      />
      <div
        className="p-[10px] flex flex-col gap-[10px] h-fi"
        style={{
          maxHeight: openState.isOpen ? '100%' : '186px',
        }}
      >
        <div>
          <div className="text-h3 text-primary">{date.format('dddd, DD.MM')}</div>
          <div className="text-h4 text-sec100">
            {date.format('hh:mm')} {messages.hour}
          </div>
        </div>
        <div className="text-int-black overflow-hidden">{text}</div>
      </div>
    </div>
  )
}
