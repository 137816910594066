import { PageLoader } from '@/components'
import { AuthenticatedOutlet } from '@/providers'
import { useCheckCurrentSuperRelative } from '@/services'
import { Navigate } from 'react-router-dom'

export const SuperRelativeGuard = ({ fallbackRoute }: { fallbackRoute: string }) => {
  const { data, isLoading } = useCheckCurrentSuperRelative()

  if (isLoading) return <PageLoader />

  if (data) return <AuthenticatedOutlet />

  return <Navigate to={fallbackRoute} />
}
