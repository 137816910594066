import { UpdatePasswordBody } from '@/api'
import { ObjectSchema, object, string } from 'yup'

export const makeValidationSchema: (messages: {
  currentPassLabel: string
  currentPassHint: string
  newPassLabel: string
  newPassHint: string
}) => ObjectSchema<UpdatePasswordBody> = (messages) =>
  object({
    old_password: string()
      .required(messages.currentPassLabel)
      .matches(/^.*(?=.{8,})((?=.*\d)+)((?=.*[A-z]){1}).*$/, messages.currentPassHint),

    new_password: string()
      .required(messages.newPassLabel)
      .matches(/^.*(?=.{8,})((?=.*\d)+)((?=.*[A-z]){1}).*$/, messages.newPassHint),
  })
