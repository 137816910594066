import { PhoneInput } from '@/components'
import { FormItem } from '@/providers'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    firstName: t('profilePage.firstName'),
    lastName: t('profilePage.lastName'),
    phone: t('profilePage.phone'),
  }
}

export const RegisterProfileStep = () => {
  const messages = useMessages()

  return (
    <>
      <FormItem
        name="first_name"
        label={`${messages.firstName}*`}
        componentProps={{ inputType: 'name' }}
      />
      <FormItem
        name="last_name"
        label={`${messages.lastName}*`}
        componentProps={{ inputType: 'name' }}
      />
      <FormItem<typeof PhoneInput>
        component={PhoneInput}
        animateLabel={false}
        name="phone"
        label={`${messages.phone}*`}
      />
    </>
  )
}
