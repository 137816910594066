/* eslint-disable @typescript-eslint/no-explicit-any */

export const log = (...args: any[]) => {
  if (import.meta.env.DEV) {
    console.log(...args)
  }
}

export const warn = (...args: any[]) => {
  if (import.meta.env.DEV) {
    console.warn(...args)
  }
}

export const error = (...args: any[]) => {
  if (import.meta.env.DEV) {
    console.error(...args)
  }
}

export const logTable = (...args: any[]) => {
  if (import.meta.env.DEV) {
    console.table(...args)
  }
}
