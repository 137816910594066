import { LoginBody } from '@/api'
import { ReactComponent as Close } from '@/assets/close.svg'
import { ReactComponent as FullLogo } from '@/assets/full-logo.svg'
import { Divider, PrimaryButton } from '@/components'
import { FormItem, FormProvider, FormSubmitType } from '@/providers'
import { paths } from '@/routes/paths'
import { useLogin } from '@/services'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { object, string } from 'yup'

const validationSchema = (messages: ReturnType<typeof useMessages>['validations']) =>
  object({
    email: string().required(messages.emailRequired).email(messages.emailInvalid),
    password: string().required(messages.passwordRequired).min(8, messages.minPasswordLength),
  })

const useMessages = () => {
  const { t } = useTranslation()

  return {
    validations: {
      emailRequired: t('profilePage.validations.inputEmail'),
      emailInvalid: t('profilePage.validations.emailInvalid'),
      passwordRequired: t('changePasswordPage.validations.currentPassLabel'),
      minPasswordLength: t('loginPage.validations.minPasswordLength'),
    },
    snackError: t('loginPage.snackError'),
    title: t('loginPage.title'),
    subtitle: t('loginPage.subtitle'),
    text: t('loginPage.text'),
    forgotPassword: t('loginPage.forgotPassword'),
    button: t('loginPage.button'),
    noAccount: t('loginPage.noAccount'),
    registerNow: t('loginPage.registerNow'),
    emailLabel: t('profilePage.email'),
    passwordLabel: t('changePasswordPage.currentPasswordLabel'),
  }
}

export const LoginPage = () => {
  const messages = useMessages()
  const navigate = useNavigate()
  const loginMutation = useLogin()

  const handleClose = () => {
    navigate('/')
  }

  const handleSubmit: FormSubmitType<Partial<LoginBody>> = (body, { setErrors }) => {
    loginMutation.mutate(body as LoginBody, {
      onError: (err) => {
        if (err.response?.status === 404) {
          setErrors({ email: '', password: messages.snackError }) // todo snackbar
        }
      },
    })
  }

  return (
    <div className="page flex items-center justify-center pt-[60px] bg-sec0 sm:items-start">
      <div className="subpage-header">
        <div />
        <div className="text-textM">{messages.title}</div>
        <Close
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={handleClose}
        />
      </div>
      <div className="max-w-[488px] flex flex-col gap-8 items-center p-6">
        <FullLogo width={100} height={51} className="shrink-0 sm:hidden" />
        <h2 className="text-h2reg text-center">{messages.subtitle}</h2>
        <span className="text-center text-b90">{messages.text}</span>
        <FormProvider<Partial<LoginBody>>
          initialValues={{}}
          onSubmit={handleSubmit}
          validationSchema={validationSchema(messages.validations)}
          className="w-full flex flex-col gap-8"
        >
          <div className="flex flex-col gap-2 w-full">
            <FormItem
              name="email"
              label={`${messages.emailLabel}*`}
              componentProps={{ inputType: 'email' }}
            />
            <FormItem
              name="password"
              label={messages.passwordLabel}
              componentProps={{
                type: 'password',
              }}
            />
            <a className="underline text-textS text-center" href={paths.forgotPassword}>
              {messages.forgotPassword}
            </a>
          </div>
          <PrimaryButton disabled={loginMutation.isLoading} type="submit" block>
            {messages.button}
          </PrimaryButton>
        </FormProvider>
        <Divider />
        <div className="text-center text-textS text-b90">
          {messages.noAccount}{' '}
          <a className="underline" href={paths.register}>
            {messages.registerNow}
          </a>
        </div>
      </div>
    </div>
  )
}
