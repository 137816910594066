import { StepperFormProps } from '@/components'
import { validateDate } from '@/utils'
import { AnyObject, number, object, string } from 'yup'

export type stepKeys =
  | 'hospital'
  | 'station'
  | 'birthday'
  | 'first_name'
  | 'last_name'
  | 'relation'
  | 'overview'
  | 'final_overview'

export const validationSchema = (messages: {
  hospital?: string
  ward?: string
  birthdate?: string
  firstName?: string
  lastName?: string
  relationshipType?: string
}) => {
  return {
    hospital: object({
      hospital: string().required(messages.hospital),
    }),
    station: object({
      station: number().required(messages.ward),
    }),
    birthday: object({
      date_of_birth: string().test(
        'date-check',
        `${messages.birthdate?.replace(
          '{{max_date}}',
          String(new Date().getDate()).padStart(2, '0') +
            '.' +
            String(new Date().getMonth() + 1).padStart(2, '0') +
            '.' +
            new Date().getFullYear(),
        )}`,
        validateDate,
      ),
    }),
    last_name: object({
      patient_last_name: string().required(messages.lastName),
    }),
    first_name: object({
      patient_first_name: string().required(messages.firstName),
    }),
    relation: object({
      patient_relation: string().required(messages.relationshipType),
    }),
  } as StepperFormProps<AnyObject, stepKeys>['validationSchema']
}
