import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { useModal, useOutsideClick } from '@/utils'
import { both, complement, filter, includes, pipe, prop, propEq, toLower } from 'ramda'
import {
  ComponentProps,
  FunctionComponent,
  SVGProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from '../Input'

export type selectOptionType<Value = string | number | boolean | null> = {
  value: Value
  label: string
  disabled?: boolean
}

type SelectProps<Value> = Omit<ComponentProps<typeof Input>, 'onChange' | 'value'> & {
  options: selectOptionType<Value>[]
  onChange: (value?: Value) => void
  value?: Value
  defaultFocused?: boolean
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  reversed?: boolean
  inputValueType?: 'label' | 'value'
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    noResults: t('global.noResults'),
  }
}

export const Select = <Value extends selectOptionType['value'] = selectOptionType['value']>({
  value,
  onChange,
  options,
  disabled,
  defaultFocused = false,
  icon: Icon,
  reversed,
  inputValueType = 'label',
  ...props
}: SelectProps<Value>) => {
  const messages = useMessages()

  const focusedState = useModal(defaultFocused)
  const [search, setSearch] = useState('')
  const ref = useRef<HTMLDivElement>(null)

  const defaultValue = useMemo(() => value, [])

  const minimized = useMemo(() => inputValueType === 'value' && !focusedState.isOpen, [
    inputValueType,
    focusedState.isOpen,
  ])

  useOutsideClick(ref.current, focusedState.close)

  const isOptionDisabled = useCallback(
    both(
      prop('disabled') as (option: selectOptionType<Value>) => boolean,
      complement(propEq(defaultValue, 'value')),
    ),
    [],
  )

  const filterOptions = useMemo(() => {
    const potentialOption = options.find(propEq(search, 'label'))

    if (potentialOption) return options

    return filter(pipe(prop('label'), toLower, includes(search.toLowerCase())), options)
  }, [search])

  const handleSearch: ComponentProps<typeof Input>['onChange'] = (e) => {
    setSearch(e.target.value)

    const potentialOption = options.find(propEq(e.target.value, 'label'))

    if (potentialOption && !isOptionDisabled(potentialOption)) {
      onChange(potentialOption?.value)
    } else {
      onChange()
    }
  }

  const handleOptionClick = (option: selectOptionType<Value>) => {
    if (!isOptionDisabled(option)) {
      onChange(option.value)
      focusedState.close()
    }
  }

  useEffect(() => {
    const potentialOption = options.find(propEq(value, 'value'))

    if (potentialOption && search !== potentialOption.label) {
      setSearch(potentialOption.label)
    }
  }, [value])

  return (
    <div
      className="relative transition-all"
      style={{
        width: minimized ? `calc(40px + ${Icon ? 36 : 16}px + ${String(value).length}ch)` : '',
      }}
      ref={ref}
    >
      {Icon && (
        <Icon
          width={16}
          height={16}
          className="absolute left-4 cursor-pointer top-4"
          onClick={focusedState.open}
        />
      )}
      <Input
        value={minimized ? String(value) : search}
        onChange={handleSearch}
        style={{
          transition: `all 0.4s cubic-bezier(0.4, 0, 0.2, 1), ${
            focusedState.isOpen
              ? 'font-weight 0s cubic-bezier(0.4, 0, 0.2, 1) 0s'
              : 'font-weight 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0.4s'
          }`,
        }}
        className={`pr-8 ${
          focusedState.isOpen
            ? reversed
              ? 'rounded-t-[0]'
              : 'rounded-b-[0]'
            : minimized
            ? 'font-bold !bg-[transparent] !border-[transparent] !border-b-2 !border-b-b30 rounded-b-[0]'
            : 'backdrop-filter-[20px] shadow-[0_0.5px_0_0_#ECEFF0,0_6px_14px_0_rgba(0,0,0,0.05)]'
        } ${Icon ? 'pl-9' : 'pl-4'}`}
        disabled={disabled}
        onClick={focusedState.open}
        {...props}
      />
      <ArrowLeft
        onClick={focusedState.open}
        width={16}
        height={16}
        fill="var(--b50)"
        className={`absolute right-4 cursor-pointer top-4 transition-all duration-[400ms] ${
          focusedState.isOpen
            ? reversed
              ? '-rotate-90'
              : 'rotate-90'
            : reversed
            ? 'rotate-90'
            : '-rotate-90'
        }`}
      />
      {focusedState.isOpen && !disabled && (
        <div
          className={`absolute max-h-[180px] overflow-y-auto w-full backdrop-filter-[20px] shadow-[0_0.5px_0_0_#ECEFF0,0_6px_14px_0_rgba(0,0,0,0.05)] transition-all duration-[400ms] border-[0.5px] border-sec30 bg-b0 z-[1000] ${
            reversed ? 'rounded-t-[8px] bottom-[48px]' : 'rounded-b-[8px]'
          }`}
        >
          {filterOptions.map((option) => (
            <div
              key={JSON.stringify(option.value)}
              className={`h-[48px] px-4 flex items-center text-placeholder border-t-[0.5px] first:border-t-0 border-sec30 ${
                option.value === value ? 'font-bold' : ''
              } ${
                isOptionDisabled(option)
                  ? 'cursor-not-allowed text-b30'
                  : 'cursor-pointer text-b100 hover:bg-sec0'
              }`}
              onClick={() => handleOptionClick(option)}
            >
              <span className="ellipsis">{option.label}</span>
            </div>
          ))}
          {!filterOptions.length && (
            <div className="h-[48px] px-4 flex items-center text-placeholder">
              {messages.noResults}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
