export type Path = typeof paths[keyof typeof paths]

export const paths = {
  auth: 'auth',
  home: 'home',
  login: 'login',
  register: 'register',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  verifyEmail: 'verify-email',
  confirmEmailChange: 'confirm-email-change',
  dashboard: 'dashboard',
  chat: 'chat',
  mySpace: 'my-space',
  icuDiary: 'icu-diary',
  onboarding: 'onboarding',
  profile: 'profile',
  imprintAndDataSecurity: 'imprint-and-data-security',
  settings: 'settings',
  survey: 'survey',
  relatives: 'relatives',
  invite: 'invite',
  media: 'media',
  videoCall: 'video-call',
  passwordChange: 'password-change',
} as const
