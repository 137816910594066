import { IcuDiaryBody } from '@/api'
import { ReactComponent as PlayIcon } from '@/assets/play-audio.svg'
import {
  FileInput,
  HorizontalScrollerWithDots,
  Modal,
  SecondaryButton,
  TextArea,
} from '@/components'
import { FormItem, FormProvider, FormSubmit, useAuthContext, useSnackbar } from '@/providers'
import { useAddIcuDiary } from '@/services'
import { useModal } from '@/utils'
import dayjs from 'dayjs'
import { FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { DiaryHelpModal } from './DiaryHelpModal'

const useMessages = () => {
  const { t } = useTranslation()
  const { currentPatient } = useAuthContext()
  const patientName = `${currentPatient?.first_name} ${currentPatient?.last_name}`

  return {
    date: t('global.date'),
    cancel: t('global.cancel'),
    snackError: t('global.snackError'),
    snackSuccess: t('icuDiaryPage.addState.snackSuccess'),
    addFormTitle: t('icuDiaryPage.addState.title', { patientName }),
    addFormTextLabel: t('icuDiaryPage.addState.textAreaLabel', { patientName }),
    addFormTextPlaceholder: t('icuDiaryPage.addState.textAreaPlaceholder', { patientName }),
    addFormTextError: t('icuDiaryPage.addState.textAreaError'),
    addFormHint: t('icuDiaryPage.addState.hint'),
    addFormButton: t('icuDiaryPage.addState.button'),
    addFormImageLabel: t('icuDiaryPage.addState.imageLabel'),
    addFormImageUpload: t('icuDiaryPage.addState.imageUpload'),
  }
}

const validationSchema = (messages: ReturnType<typeof useMessages>) =>
  yup.object({
    text: yup.string().required(messages.addFormTextError),
  })

export const AddIcuDiary = ({ onClose }: { onClose: () => void }) => {
  const { patientId } = useParams<{ patientId: string }>()
  const snackbar = useSnackbar()
  const messages = useMessages()
  const exampleState = useModal(false)
  const submitMutation = useAddIcuDiary(patientId!)

  const handleSubmit: FormikConfig<IcuDiaryBody>['onSubmit'] = (values) =>
    submitMutation.mutateAsync(values, {
      onSuccess: () => {
        snackbar.success(messages.snackSuccess)
        onClose()
      },
      onError: () => {
        snackbar.error(messages.snackError)
      },
    })

  return (
    <FormProvider<IcuDiaryBody>
      className="max-w-[488px] py-4 px-5 bg-int-white h-full flex flex-col gap-4 items-center"
      onSubmit={handleSubmit}
      validationSchema={validationSchema(messages)}
      initialValues={{ text: '' }}
    >
      <DiaryHelpModal />
      <div className="flex flex-col w-full items-center gap-2">
        <div className="text-h2 text-center">{messages.addFormTitle}</div>
        <div className="text-b90">
          {messages.date}: {dayjs().format('DD.MM.YYYY')}
        </div>
      </div>
      <div className="flex flex-col gap-2 w-full">
        <div className="text-h4 text-b100">{messages.addFormImageLabel}</div>
        <FormItem<typeof FileInput>
          name="image"
          component={FileInput}
          componentProps={{
            accept: '.jpg,.jpeg,.webp,.png',
          }}
        >
          <SecondaryButton
            type="button"
            block
            className="pointer-events-none max-w-[328px] sm:max-w-none my-4"
          >
            <PlayIcon fill="var(--primary)" width={16} height={16} />
            {messages.addFormImageUpload}
          </SecondaryButton>
        </FormItem>
      </div>
      <div className="flex flex-col gap-2 w-full">
        <label htmlFor="text" className="text-b100 !text-h4">
          {messages.addFormTextLabel}
        </label>
        <FormItem<typeof TextArea>
          component={TextArea}
          name="text"
          componentProps={{
            placeholder: messages.addFormTextPlaceholder,
            rows: 12,
          }}
        />
      </div>
      <div
        className="underline text-primary text-left w-full cursor-pointer"
        onClick={exampleState.open}
      >
        {messages.addFormHint}
      </div>
      <div className="flex flex-row-reverse sm:flex-col child:flex-1 sm:child:flex-none w-full gap-2">
        <FormSubmit<IcuDiaryBody> block>{messages.addFormButton}</FormSubmit>
        <SecondaryButton block type="button" onClick={onClose}>
          {messages.cancel}
        </SecondaryButton>
      </div>
      {exampleState.isOpen && (
        <Modal onClose={exampleState.close}>
          <HorizontalScrollerWithDots
            gap={12}
            count={3}
            containerClassName="sm:max-h-[70vh]"
            style={{ fontStyle: 'italic' }}
          >
            <div className="w-[100%] shrink-0">
              Mein Liebster, <br />
              es ist alles so anders. Du fehlst mir und ich habe Angst um Dich. Du bist ganz warm,
              wenn ich Deine Hand halte, aber Deine Seele ist weit fern. Du hast immer gesagt, wir
              stehen alles zusammen durch und jetzt sind wir doch auf getrennten Seiten und jeder
              ist alleine. Komm zurück.
              <br />
              <br />
              Doris
            </div>
            <div className="w-[100%] shrink-0">
              Lieber Papa, <br />
              heute haben Louise und Dennis Bilder für Dich mitgegeben, die haben wir im Zimmer
              aufgehängt! Ich hoffe, dass Du sie bald sehen kannst! Die Ärzte sagen, dass das letzte
              Kopfbild ganz gut aussehen würde und sie würden morgen versuchen Dich wach werden zu
              lassen. Was das wohl heißt? Die Pflegenden sind ganz emsig und fragen mich immer, ob
              alles in Ordnung ist und so. Ich glaube Du bist hier ganz gut aufgehoben. Eben ist
              Mama gekommen und wir tratschen etwas, wie wir eben so sind. <br /> <br />
              Hab Dich lieb! <br />
              Sabine
            </div>
            <div className="w-[100%] shrink-0">
              Liebster Knut, <br /> was für ein toller Tag, heute hast Du mich angeschaut und
              gelächelt!!! Ich glaube, dass wir das Schlimmste hinter uns haben und ich bin so
              erleichtert! Gleich kommt noch Stefan und wir sind bei Dir! Ich habe Dich lieb.
              <br />
              <br />
              Deine Doris
            </div>
          </HorizontalScrollerWithDots>
        </Modal>
      )}
    </FormProvider>
  )
}
