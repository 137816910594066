import { InviteRelativeBody } from '@/api'
import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { Checkbox, PhoneInput, PrimaryButton, Select } from '@/components'
import { config } from '@/config'
import {
  FormItem,
  FormProvider,
  useAuthContext,
  useFormContext,
  useSnackbar,
  useSurveyContext,
} from '@/providers'
import { paths } from '@/routes/paths'
import { useCurrentPatient, useInviteRelative } from '@/services'
import { useModal, useNavigateToPreviousLocation } from '@/utils'
import { FormikConfig } from 'formik'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { mergeLeft, omit } from 'ramda'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Location, useLocation, useParams } from 'react-router-dom'
import { boolean, object, string } from 'yup'

type InviteFormValues = InviteRelativeBody & {
  data_confirm: boolean
  terms_confirm: boolean
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('inviteRelativePage.title'),
    text: t('inviteRelativePage.text'),
    button: t('inviteRelativePage.button'),
    messageError: t('global.messageError'),
    messageSuccess: t('inviteRelativePage.messageSuccess'),
    patientCheckbox: t('inviteRelativePage.patientCheckbox'),
    checkbox1Text: t('inviteRelativePage.checkbox1Text'),
    relationshipType: t('inviteRelativePage.relationshipType'),
    firstNameLabel: t('inviteRelativePage.firstNameLabel'),
    lastNameLabel: t('inviteRelativePage.lastNameLabel'),
    mailLabel: t('inviteRelativePage.mailLabel'),
    phoneLabel: t('inviteRelativePage.phoneLabel'),
    inviteByMail: t('inviteRelativePage.inviteByMail'),
    inviteByPhone: t('inviteRelativePage.inviteByPhone'),
    validations: t('inviteRelativePage.validations', { returnObjects: true }),
  }
}

const validationSchema = (validations: {
  firstName: string
  lastName: string
  relationshipType: string
  email: string
  phone: string
}) => {
  return object({
    first_name: string().required(validations.firstName),
    last_name: string().required(validations.lastName),
    relation: string().test(
      'test-relation',
      validations.relationshipType,
      (value, { parent }: { parent: InviteFormValues }) => {
        return Boolean(parent.patient_account_invite || value?.length)
      },
    ),
    email: string()
      .email(validations.email)
      .test('email-check', validations.email, (value, { parent }: { parent: InviteFormValues }) => {
        return Boolean(parent.phone || value?.length)
      }),
    phone: string().test(
      'phone-check',
      validations.phone,
      (value, { parent }: { parent: InviteFormValues }) => {
        return Boolean(parent.email || (value && isValidPhoneNumber(value)))
      },
    ),
    data_confirm: boolean().required('').oneOf([true], ''),
    terms_confirm: boolean().required('').oneOf([true], ''),
    patient_account_invite: boolean(),
  })
}

export const InviteForm = () => {
  const {
    values,
    setFieldValue,
    isValid,
    isSubmitting,
    hasChanges,
    initialValues,
  } = useFormContext<InviteFormValues>()
  const { settings, currentPatient } = useAuthContext()
  const { data, isLoading } = useCurrentPatient()
  const showEmailState = useModal(false)
  const messages = useMessages()

  useEffect(() => {
    if (showEmailState.isOpen && values.phone) {
      setFieldValue('phone', '')
    }

    if (!showEmailState.isOpen && values.email) {
      setFieldValue('email', '')
    }
  }, [showEmailState.isOpen, values.phone, values.email])

  useEffect(() => {
    if (values.patient_account_invite) {
      setFieldValue('first_name', currentPatient?.first_name)
      setFieldValue('last_name', currentPatient?.last_name)
    }
  }, [values.patient_account_invite])

  return (
    <div className="flex flex-col gap-4 h-full">
      {!initialValues.patient_account_invite &&
        !currentPatient?.is_patient_user &&
        !isLoading &&
        !data?.patient_invite_disabled && (
          <div className="pb-6">
            <FormItem<typeof Checkbox>
              component={Checkbox}
              valuePropName="checked"
              name="patient_account_invite"
              componentProps={{
                label: messages.patientCheckbox,
              }}
            />
          </div>
        )}
      <FormItem
        name="first_name"
        label={`${messages.firstNameLabel}*`}
        componentProps={{ inputType: 'name', readOnly: values.patient_account_invite }}
      />
      <FormItem
        name="last_name"
        label={`${messages.lastNameLabel}*`}
        componentProps={{ inputType: 'name', readOnly: values.patient_account_invite }}
      />
      {!values.patient_account_invite && (
        <FormItem<typeof Select>
          component={Select}
          name="relation"
          componentProps={{
            readOnly: true,
            placeholder: `${messages.relationshipType}*`,
            options: settings.relationships,
          }}
        />
      )}
      {showEmailState.isOpen ? (
        <FormItem
          name="email"
          label={`${messages.mailLabel}*`}
          componentProps={{ inputType: 'email' }}
        />
      ) : (
        <FormItem<typeof PhoneInput>
          component={PhoneInput}
          animateLabel={false}
          name="phone"
          label={`${messages.phoneLabel}*`}
        />
      )}
      <div className="flex justify-center">
        <span
          className="text-[#55A0BE] text-[16px] font-semibold leading-6 underline cursor-pointer"
          onClick={showEmailState.toggle}
        >
          {showEmailState.isOpen ? messages.inviteByPhone : messages.inviteByMail}
        </span>
      </div>
      <FormItem<typeof Checkbox>
        component={Checkbox}
        valuePropName="checked"
        name="data_confirm"
        componentProps={{
          label: messages.checkbox1Text,
        }}
      />
      <FormItem<typeof Checkbox>
        component={Checkbox}
        valuePropName="checked"
        name="terms_confirm"
        componentProps={{
          label: (
            <Trans
              i18nKey={'inviteRelativePage.checkbox2Text'}
              components={[<a href={config.termsUrl} target="_blank" rel="noreferrer" />]}
            />
          ),
        }}
      />
      <div className="flex-1" />
      <PrimaryButton type="submit" block disabled={isSubmitting || !isValid || !hasChanges}>
        {messages.button}
      </PrimaryButton>
    </div>
  )
}

export const InvitePage = () => {
  const snackbar = useSnackbar()
  const { state } = useLocation() as Omit<Location, 'state'> & {
    state?: Pick<InviteFormValues, 'patient_account_invite' | 'email' | 'phone'>
  }
  const { patientId } = useParams<{ patientId: string }>()
  const surveyContext = useSurveyContext()
  const navigate = useNavigateToPreviousLocation()

  const inviteRelativeMutation = useInviteRelative(parseInt(patientId as string))
  const messages = useMessages()

  const handleBack = () => {
    navigate(window.location.pathname.replace(`/${paths.invite}`, ''))
  }

  const handleSubmit: FormikConfig<Partial<InviteFormValues>>['onSubmit'] = (
    body,
    { setSubmitting },
  ) => {
    inviteRelativeMutation.mutate(
      omit(['data_confirm', 'terms_confirm'], body) as InviteRelativeBody,
      {
        onError: (err) => {
          snackbar.error(err.response?.data?.detail || messages.messageError)
          setSubmitting(false)
        },
        onSuccess: () => {
          snackbar.success(messages.messageSuccess)
          surveyContext.triggerSurvey()
          handleBack()
        },
      },
    )
  }

  return (
    <div className="page flex justify-center pt-[60px] bg-sec0">
      <div className="subpage-header">
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={handleBack}
        />
        <div className="text-textM">{messages.title}</div>
        <div />
      </div>
      <div className="max-w-[488px] w-full flex flex-col gap-8 items-center p-6">
        <div className="text-textS text-b90">{messages.text}</div>
        <FormProvider<Partial<InviteFormValues>>
          initialValues={mergeLeft(omit(['from'], state || {}), {
            data_confirm: false,
            terms_confirm: false,
            patient_account_invite: false,
          })}
          onSubmit={handleSubmit}
          validationSchema={validationSchema(messages.validations)}
          className="w-full flex flex-col gap-8 h-full"
        >
          <InviteForm />
        </FormProvider>
      </div>
    </div>
  )
}
