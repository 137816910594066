import { ReactComponent as Close } from '@/assets/close.svg'
import { ReactComponent as FullLogo } from '@/assets/full-logo.svg'
import { config } from '@/config'
import { useLogout } from '@/services'
import { ParseKeys } from 'i18next'
import { PropsWithChildren } from 'react'
import { Trans } from 'react-i18next'

type AccessDeniedProps = PropsWithChildren<{
  pageTitle?: string
  title: string
  content: string
  contactI18nKey: ParseKeys<'translation'>
}>

export const AccessDenied = ({
  children,
  pageTitle,
  title,
  content,
  contactI18nKey,
}: AccessDeniedProps) => {
  const logout = useLogout()

  return (
    <div className="page flex items-center justify-center pt-[60px] bg-sec0 sm:items-start">
      <div className="subpage-header">
        <div />
        <div className="text-textM">{pageTitle}</div>
        <Close
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={logout}
        />
      </div>
      <div className="max-w-[488px] flex flex-col gap-8 items-center p-6">
        <FullLogo width={100} height={51} className="shrink-0 sm:hidden" />
        <h2 className="text-h2reg text-center">{title}</h2>
        <span className="text-center text-b90">{content}</span>
        {children}
        <div className="text-center text-textS text-b90">
          <Trans
            // @ts-ignore
            i18nKey={contactI18nKey}
            values={{ mail: config.supportEmail, phone: config.contactPhone }}
            components={[
              <a className="underline" href={`mailto:${config.supportEmail}`} />,
              <a className="underline whitespace-nowrap" href={`tel:${config.contactPhone}`} />,
            ]}
          />
        </div>
      </div>
    </div>
  )
}
