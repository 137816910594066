import { DocumentType, RelativeListItem } from '@/api'
import { ReactComponent as ChatIcon } from '@/assets/chat.svg'
import { ReactComponent as DiaryIcon } from '@/assets/diary.svg'
import { ReactComponent as ListIcon } from '@/assets/list.svg'
import { ReactComponent as PatientIcon } from '@/assets/patient.svg'
import { SideBar, sideBarItem } from '@/components'
import { useAuthContext } from '@/providers'
import {
  useCheckCurrentSuperRelative,
  useCurrentPatient,
  useDocumentTypes,
  useRelativesList,
} from '@/services'
import { complement, includes, pipe, pluck, prop } from 'ramda'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    patient: t('mainMenu.patient'),
    chat: t('mainMenu.chat'),
    myArea: t('mainMenu.myArea'),
    diary: t('mainMenu.diary'),
  }
}

export const doesMatchRequestExist = pipe(
  pluck('status') as (relatives: RelativeListItem[]) => RelativeListItem['status'][],
  includes<RelativeListItem['status']>('need_to_accept'),
)

export const doesDocumentNotificationExist = (documents: DocumentType[]) =>
  Boolean(documents.length && documents.find(complement(prop('has_an_uploaded_document'))))

export const HomePageNavBar = () => {
  const messages = useMessages()
  const { relatives, currentPatient } = useAuthContext()
  const patientResponse = useCurrentPatient()
  const { data } = useCheckCurrentSuperRelative()
  useRelativesList(currentPatient)

  const documentsResponse = useDocumentTypes(
    currentPatient?.connection_status === 'assigned' ? currentPatient?.id.toString() : undefined,
  )

  const dashboardBadge = useMemo(() => data && doesMatchRequestExist(relatives), [
    data,
    JSON.stringify(relatives),
  ])

  const mySpaceBadge = useMemo(
    () =>
      doesDocumentNotificationExist(documentsResponse.data || []) ||
      (!patientResponse.data?.patient_invite_disabled &&
        !currentPatient?.active &&
        !currentPatient?.is_patient_user),
    [
      JSON.stringify(documentsResponse.data),
      currentPatient?.is_patient_user,
      currentPatient?.active,
      patientResponse.data?.patient_invite_disabled,
    ],
  )

  const items = useMemo<sideBarItem[]>(
    () => [
      {
        icon: PatientIcon,
        text: messages.patient,
        to: 'dashboard',
        badge: dashboardBadge,
      },

      {
        icon: DiaryIcon,
        text: messages.diary,
        to: 'icu-diary',
        hidden: !patientResponse.data?.icu_diary_enabled,
      },
      {
        icon: ChatIcon,
        text: messages.chat,
        to: 'chat',
        stroke: true,
      },
      {
        icon: ListIcon,
        text: messages.myArea,
        to: 'my-space',
        badge: mySpaceBadge,
      },
    ],
    [dashboardBadge, mySpaceBadge, patientResponse.data?.icu_diary_enabled],
  )
  return <SideBar items={items} />
}
