type detailType = {
  value?: string
  label: string
}

const DetailRow = ({ value, label }: detailType) =>
  value && (
    <div className="flex items-center justify-between gap-2 px-4 h-[48px] border-t-[0.5px] border-t-sec10 first:border-0">
      <span className="grow-1 shrink-0">{label}</span>
      <span className="text-b90 ellipsis">{value}</span>
    </div>
  )

export const DetailsCard = ({ details }: { details: detailType[] }) => (
  <div className="flex flex-col bg-b0 border-[0.5px] border-sec10 rounded-[8px] w-full shadow-[0_0.5px_0_0_rgba(16,34,40,0.02)]">
    {details.map((item) => (
      <DetailRow key={item.label} {...item} />
    ))}
  </div>
)
