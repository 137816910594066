import { ReactComponent as FullLogo } from '@/assets/full-logo.svg'
import { AuthLayout, Divider, PrimaryButton, SecondaryButton } from '@/components'
import { config } from '@/config'
import { LanguageSelectProps } from '@/routes'
import { paths } from '@/routes/paths'
import { FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('authPage.title'),
    text: t('authPage.text'),
    hintEUSecurity: t('authPage.hintEUSecurity'),
    btnRegister: t('authPage.btnRegister'),
    btnSignIn: t('authPage.btnSignIn'),
  }
}

export const AuthPage = () => {
  const { LanguageSelect } = useOutletContext() as {
    LanguageSelect: FunctionComponent<LanguageSelectProps>
  }
  const navigate = useNavigate()
  const { state } = useLocation()
  const messages = useMessages()

  return (
    <AuthLayout
      className="sm:items-end"
      paperClassName="max-w-[488px] !min-h-max items-center sm:bg-[transparent] sm:border-0 sm:shadow-none"
    >
      <FullLogo width={100} height={51} className="shrink-0" />
      <h2 className="text-center">{messages.title}</h2>
      <span className="text-center text-b90">{messages.text}</span>
      <div className="flex flex-col gap-2 w-full">
        <PrimaryButton onClick={() => navigate(paths.register)} block>
          {messages.btnRegister}
        </PrimaryButton>
        <SecondaryButton onClick={() => navigate(paths.login, { state })} block>
          {messages.btnSignIn}
        </SecondaryButton>
      </div>
      <span className="text-center text-b50 text-textS">🔒 {messages.hintEUSecurity}</span>
      <div className="md:hidden z-[10000]">
        <LanguageSelect />
      </div>
      <Divider />
      <div className="text-center text-textS text-b90">
        <Trans
          i18nKey={'authPage.textBottom'}
          values={{ phone: config.privacyPolicyUrl }}
          components={[
            <a href={config.termsUrl} target="_blank" rel="noreferrer" />,
            <a href={config.privacyPolicyUrl} target="_blank" rel="noreferrer" />,
          ]}
        />
      </div>
    </AuthLayout>
  )
}
