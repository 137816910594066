import { resources } from '@/@types/resources'
import { api } from './api'

export type Me = {
  id: number
  first_name: string | null
  email: string
  phone: string | null
  last_name: string | null
  is_active: boolean
  trial_days: number | null
  created_at: string
}

type UserSettingsType = {
  auto_translation_enabled: boolean
}

export type Profile = {
  exceeded_onboarding_quota: boolean
  language: keyof typeof resources
  user_settings: UserSettingsType
}

export type User = Me & Partial<Profile> & { avatar: string | null }

export const getAuthenticatedUser = () => api.get<Me>('/auth/users/me/')

export const getProfilePicture = () => api.get<Pick<User, 'avatar'>>('/auth/profile_picture/me/')

export const getProfile = () => api.get<Profile>('/relatives/relatives_profile/me/')

export type UpdateUserBody = Pick<User, 'email' | 'first_name' | 'last_name' | 'phone'>

export const updateUser = (body: Partial<UpdateUserBody>) => api.patch('/auth/users/me/', body)

export const deleteProfilePicture = () => api.delete('/auth/profile_picture/me/')

export const updateProfilePicture = (avatar: Blob) => {
  const userFileAvatar = new File([avatar], 'avatar.jpg', { type: 'image/jpeg' })

  const formData = new FormData()
  formData.append('avatar', userFileAvatar)

  return api.post('/auth/profile_picture/', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const deleteAccount = () => api.delete('/auth/users/me/')

export type UpdateProfileBody = Partial<Pick<Profile, 'language' | 'user_settings'>>

export const updateProfile = (relative_profile: UpdateProfileBody) =>
  api.patch('/relatives/relatives_profile/me/', { relative_profile })

type ConsumeOTPBody = {
  confirmation_code: string
}

export const consumePhoneOTP = (body: ConsumeOTPBody) =>
  api.post('/auth/users/phone-confirm/', body)

export const queryEmailChangeOTP = () =>
  api.get<string | 204>('/auth/users/check_email_change_pin_code_presence/')

export const deleteEmailChangeOTP = () => api.delete('/auth/users/delete_email_change_pin_code/')
