import { AuthTroubleshootLayout, PageLoader } from '@/components'
import { useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import { useConfirmEmailChange } from '@/services'
import { useModal } from '@/utils'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('confirmEmailChangePage.title'),
    text: t('confirmEmailChangePage.text'),
    button: t('confirmEmailChangePage.button'),
    emailChangeSuccess: t('confirmEmailChangePage.emailChangeSuccess'),
    phoneChangeSuccess: t('confirmEmailChangePage.phoneChangeSuccess'),
  }
}

export const ConfirmEmailChangePage = () => {
  const messages = useMessages()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { token } = useParams<{ token: string }>()
  const confirmEmailChangeMutation = useConfirmEmailChange(token as string)
  const mfaState = useModal(false)

  const goToDashboard = () => {
    navigate(`/${paths.home}`)
  }

  useEffect(() => {
    if (token) {
      confirmEmailChangeMutation.mutate(
        {},
        {
          onSuccess: (e) => {
            if (e.email_change_with_additional_sms) {
              mfaState.open()
            } else {
              if (e.email_is_changed) {
                snackbar.success(messages.emailChangeSuccess)
              }
              if (e.phone_is_changed) {
                snackbar.success(messages.phoneChangeSuccess)
              }
              goToDashboard()
            }
          },
          onError: () => {
            navigate(`/${paths.verifyEmail}`)
          },
        },
      )
    } else {
      navigate(`/${paths.verifyEmail}`)
    }
  }, [token])

  if (mfaState.isOpen)
    return (
      <AuthTroubleshootLayout
        title={messages.title}
        buttonText={messages.button}
        redirectPath={`/${paths.profile}`}
      >
        {messages.text}
      </AuthTroubleshootLayout>
    )

  return <PageLoader />
}
