// todo cleanup
import { ChatMessageType } from '@/api'
import bellIcon from '@/assets/bell.svg'
import { ReactComponent as DiaryIcon } from '@/assets/diary.svg'
import { ReactComponent as MessageBottomLeft } from '@/assets/message-bubble-arrow.svg'
import { Avatar } from '@/components'
import { useAuthContext } from '@/providers'
import { useCheckCurrentSuperRelative } from '@/services'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatMessageContent } from './ChatMessageContent'
import { LikeMessage } from './LikeMessage'

type ChatMessagePropsTypes = ChatMessageType & { translation?: string }

const useMessages = () => {
  const { t } = useTranslation()

  return {
    onlyForSuperRelative: t('chatMessage.onlyForSuperRelative'),
    caregiver: t('chatMessage.caregiver'),
  }
}

const formatTime = (send_at: string) => {
  const date = new Date(send_at as string)
  const hours = date.getHours()
  const minutes = date.getMinutes()

  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

export const ChatMessage = ({
  medical_document,
  send_at,
  send_by,
  media,
  text,
  nurse_avatar,
  likes,
  liked,
  for_super_relative_only,
  is_notification,
  id,
  original_language,
  needs_to_be_translated,
  translation,
  is_icu_diary_message,
}: ChatMessagePropsTypes) => {
  const { user } = useAuthContext()
  const { data } = useCheckCurrentSuperRelative()

  const messageTime = useMemo(() => formatTime(send_at as string), [send_at])
  const messages = useMessages()

  if (!text && !medical_document && !media?.type) return null

  const isRelative = typeof send_by !== 'number' && send_by?.id
  const isMe = Boolean(isRelative && send_by.id === user.id)

  return (
    <div
      className={`flex gap-x-2 relative ${is_notification ? 'items-start' : 'items-end'} ${
        isMe ? 'flex-row-reverse' : ''
      }`}
    >
      {isRelative ? (
        <Avatar
          size={30}
          src={send_by.avatar}
          first_name={send_by.full_name.split(' ')[0]}
          last_name={send_by.full_name.split(' ')[1]}
        />
      ) : (
        <Avatar
          size={30}
          src={
            is_notification
              ? bellIcon
              : send_by === null || !nurse_avatar
              ? '/favicon.ico'
              : nurse_avatar
          }
        />
      )}
      <div
        className={`relative text-b90 flex flex-col gap-4 ${medical_document && 'min-w-[50%]'} ${
          is_notification
            ? ' text-[16px] pb-2'
            : `p-4 rounded-[18px] leading-[22px] text-[16px] ${isRelative ? 'bg-sec10' : 'bg-b10 '}`
        }`}
        style={{
          wordBreak: 'break-word',
          width: media?.type === 'audio' ? '100%' : '',
        }}
      >
        {!is_notification && send_by && (
          <div className="text-[12px] font-bold flex justify-between gap-2">
            {isRelative ? send_by.full_name : messages.caregiver}
            {is_icu_diary_message && <DiaryIcon width={24} height={24} fill="var(--primary)" />}
          </div>
        )}
        <ChatMessageContent
          text={text}
          media={media}
          is_notification={is_notification}
          medical_document={medical_document}
          original_language={original_language}
          translation={translation}
          needs_to_be_translated={!isMe && needs_to_be_translated}
          is_icu_diary_message={is_icu_diary_message}
        />
        <div className="text-primary text-textXS pr-6">
          {data && for_super_relative_only && messages.onlyForSuperRelative}
        </div>
        <div className="absolute right-3 bottom-3 text-textXS">{messageTime}</div>
        {!is_notification && !is_icu_diary_message && Boolean(send_by) && (
          <LikeMessage id={id} liked={liked} likes={likes} disabled={isMe} />
        )}
      </div>
      {!is_notification && !is_icu_diary_message && (
        <MessageBottomLeft
          className={`absolute bottom-0 ${isMe ? 'right-[33px] scale-x-[-1]' : 'left-[33px]'}`}
          width={17}
          height={21}
          fill={isRelative ? 'var(--sec10)' : 'var(--b10)'}
        />
      )}
    </div>
  )
}
