import { PaginatedResponseType } from '@/utils'
import { api } from './api'

export type AssetType = 'image' | 'audio' | 'video'

export type Media = {
  id: number
  asset: string
  asset_converted: string | null
  asset_optimized: string
  asset_type: AssetType
  name: string
  uploaded_at: string
  thumbnail?: string
  sound_wave?: string
}

export const listMedia = (patientId: number) =>
  api.get<PaginatedResponseType<Media>>(`/contacts/assets/?patient=${patientId}`)

export const deleteMedia = (id: Media['id']) => api.delete(`/contacts/assets/${id}/`)

export const uploadMedia = (id: number, file: File, asset_type: AssetType) => {
  const formData = new FormData()
  formData.append('asset', file)
  formData.append('asset_type', asset_type)
  formData.append('name', file.name || '')
  formData.append('patient', String(id))

  return api.post(`/contacts/assets/?patient=${id}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
