import { PageLoader } from '@/components'
import { useSnackbar } from '@/providers'
import { useUpdateProfilePicture } from '@/services'
import { getCroppedImg } from '@/utils'
import { useMemo, useRef, useState } from 'react'
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    back: t('imageUpload.back'),
    save: t('imageUpload.save'),
    messageError: t('imageUpload.messageError'),
  }
}

const centerCropSelection = (width: number, height: number) =>
  centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width: width / 2,
      },
      1,
      width,
      height,
    ),
    width,
    height,
  )

type CropsicleProps = {
  image: File
  onClose: () => void
}

export const Cropsicle = ({ image, onClose }: CropsicleProps) => {
  const ref = useRef<HTMLImageElement>(null)
  const snackbar = useSnackbar()
  const [crop, setCrop] = useState<Crop>()
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const updateAvatarMutation = useUpdateProfilePicture()
  const messages = useMessages()

  const src = useMemo(() => URL.createObjectURL(image), [])

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    if (!dimensions.width) setDimensions({ width, height })
    if (!crop) setCrop(centerCropSelection(width, height))
  }

  const handleSave = async () => {
    if (ref.current && !updateAvatarMutation.isLoading) {
      getCroppedImg(ref.current, crop as Crop).then((blob) => {
        updateAvatarMutation.mutate(blob as Blob, {
          onSuccess: onClose,
          onError: () => {
            snackbar.error(messages.messageError)
            onClose()
          },
        })
      })
    }
  }

  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 bg-b100 items-center justify-center flex ">
      <div className="max-w-screen" style={dimensions.width ? dimensions : {}}>
        <ReactCrop crop={crop} onChange={setCrop} circularCrop aspect={1}>
          <img
            ref={ref}
            src={src}
            alt="chosen-image"
            onLoad={onImageLoad}
            className="object-contain !max-h-[calc(100vh-60px)] !max-w-screen"
          />
        </ReactCrop>
      </div>
      <div className="absolute bottom-0 left-0 right-0 h-[60px] px-4 flex justify-between items-center backdrop-blur-[5px]">
        <div className="text-button text-b0 cursor-pointer uppercase" onClick={onClose}>
          {messages.back}
        </div>
        <div className="text-button text-warning cursor-pointer uppercase" onClick={handleSave}>
          {messages.save}
        </div>
      </div>
      {updateAvatarMutation.isLoading && <PageLoader />}
    </div>
  )
}
