import {
  LoginBody,
  RegisterBody,
  RegisterResponse,
  ResetPasswordBody,
  confirmEmailChange,
  doesEmailExist,
  doesPhoneExist,
  getInviteData,
  login,
  register,
  requestResetPassword,
  resendEmailChangeVerification,
  resendRegisterOTP,
  resendVerificationEmail,
  resetPassword,
  updatePassword,
  validateResetPasswordToken,
  verifyEmail,
} from '@/api'
import { getTokenFromLocalStorage, storeToken } from '@/api/api'
import { useSnackbar } from '@/providers'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
  isTokenValid,
  postMessageToWebview,
  reactQueryKeys,
  useInvalidateQuery,
  useMutation,
} from '../utils'

type SessionResponse = {
  isAuthenticated: boolean
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    generalError: t('global.messageError'),
    resendVerificationEmailSuccess: t('verifyEmailPage.resendSuccess'),
    errorOccurred: t('resetPasswordPage.snackError'),
    emailNotFound: t('forgotPasswordPage.emailNotFound'),
  }
}

export const useSession = () =>
  useQuery<SessionResponse>(reactQueryKeys.session, async () => {
    try {
      const token = getTokenFromLocalStorage()
      const isAuthenticated = isTokenValid(token)

      return { isAuthenticated }
    } catch (e) {
      return { isAuthenticated: false }
    }
  })

export const useLogout = () => {
  const invalidateQuery = useInvalidateQuery()

  return async () => {
    postMessageToWebview({
      type: 'logout',
    })
    localStorage.clear()
    await invalidateQuery('session')
  }
}

export const useAuthenticate = <Values>(
  authFn: (body: Values) => Promise<RegisterResponse>,
  invalidateSession: boolean = true,
) => {
  const invalidateQuery = useInvalidateQuery()
  const { mutate, ...data } = useMutation(authFn)

  const authService: typeof mutate = (props, { onSuccess, ...options } = {}) =>
    mutate(props, {
      onSuccess: (data, body, context) => {
        if (data.onesignal_token) {
          postMessageToWebview({
            type: 'login',
            params: { onesignal_token: data.onesignal_token },
          })
        }
        if (data.access) {
          storeToken(data.access)
        }
        onSuccess?.(data, body, context)
        if (invalidateSession) invalidateQuery('session')
      },
      ...options,
    })

  return { mutate: authService, ...data }
}

export const useLogin = () => useAuthenticate<LoginBody>(login)

export const useRegister = (invalidateSession?: boolean) =>
  useAuthenticate<RegisterBody>(register, invalidateSession)

export const useResendRegisterOTP = () => useMutation(resendRegisterOTP)

export const useDoesEmailExist = () => useMutation(doesEmailExist)

export const useDoesPhoneExist = () => useMutation(doesPhoneExist)

export const useRequestResetPassword = () => {
  const messages = useMessages()

  return useMutation(async (email: string) => {
    try {
      await doesEmailExist(email)
      throw new Error(messages.emailNotFound)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.message === messages.emailNotFound) throw new Error(e.message)
      if (e.response.status === 406) return await requestResetPassword(email)
      else throw new Error(messages.errorOccurred)
    }
  })
}

export const useValidateResetPasswordToken = (token: string) =>
  useQuery(['reset-password-token', token], () => validateResetPasswordToken(token), {
    enabled: Boolean(token),
  })

export const useResetPassword = (params: Omit<ResetPasswordBody, 'password'>) =>
  useMutation((password: string) => resetPassword({ ...params, password }))

export const useResendVerificationEmail = () => {
  const snackbar = useSnackbar()
  const messages = useMessages()

  return useMutation(resendVerificationEmail, {
    onError: () => {
      snackbar.error(messages.generalError)
    },
    onSuccess: () => {
      snackbar.success(messages.resendVerificationEmailSuccess)
    },
  })
}

export const useResendEmailChangeVerification = () => useMutation(resendEmailChangeVerification)

export const useVerifyEmail = (token: string) => useMutation(() => verifyEmail(token))

export const useConfirmEmailChange = (token: string) => useMutation(() => confirmEmailChange(token))

export const useInvite = (invite_code?: string) =>
  useQuery([reactQueryKeys.invite, invite_code], () => getInviteData(invite_code), {
    enabled: Boolean(invite_code),
  })

export const useUpdatePassword = () => useMutation(updatePassword)
