import { useFormContext } from '@/providers'
import { FunctionComponent } from 'react'
import { AnyObject } from 'yup'
import { StepperFormStepType, stepperHandlers } from './Component'
import { StepLayout, StepLayoutBaseProps } from './StepLayout'

type StepWrapperPropsBase<Values, StepKey extends string> = Pick<
  StepLayoutBaseProps<Values, StepKey>,
  'onBack' | 'layout' | 'percentage' | 'stepTitle' | 'submitButtonText' | 'title' | 'children'
> & {
  onClose: (values?: Values) => void
  validateFieldsToDisable?: StepperFormStepType<Values, StepKey>['validateFieldsToDisable']
} & Omit<stepperHandlers<StepKey>, 'back'>

type StepWrapperPage<Values, StepKey extends string> = StepWrapperPropsBase<Values, StepKey> & {
  layout?: 'page'
  errorComponent?: false | FunctionComponent<{ onReset: () => void }>
  hideProgress?: boolean
  skip?: StepperFormStepType<Values, StepKey>['skip']
}

export type StepWrapperProps<Values, StepKey extends string> =
  | (StepWrapperPropsBase<Values, StepKey> & {
      errorComponent?: never
      hideProgress?: never
      skip?: never
    })
  | StepWrapperPage<Values, StepKey>

export const StepWrapper = <Values extends AnyObject = AnyObject, StepKey extends string = string>({
  children,
  onBack,
  title,
  onClose,
  percentage,
  stepTitle,
  submitButtonText,
  validateFieldsToDisable,
  goTo,
  skip,
  next,
  reset,
  errorComponent: ErrorComponent,
  hideProgress,
  layout,
}: StepWrapperProps<Values, StepKey>) => {
  const {
    values,
    isSubmitting,
    areFieldsValid,
    resetForm,
    initialValues,
    setFieldValue,
  } = useFormContext<Values>()

  const handleClose = () => {
    onClose(values)
  }

  const handleSkip = () => {
    if (skip?.type === 'reset') {
      resetForm()
    }
    if (skip?.fields) {
      skip.fields.forEach((field) => {
        setFieldValue(field as string, initialValues[field])
      })
    }
    if (skip?.to) {
      goTo(skip.to)
    } else {
      next()
    }
    skip?.onSkip?.()
  }

  const onReset = () => {
    resetForm()
    reset()
    if (onBack) {
      onBack?.()
    }
  }

  return (
    // @ts-ignore
    <StepLayout<Values, StepKey>
      layout={layout}
      onBack={onBack}
      title={title}
      onClose={handleClose}
      percentage={percentage}
      stepTitle={stepTitle}
      submitButtonText={submitButtonText}
      submitButtonDisabled={
        isSubmitting || Boolean(validateFieldsToDisable && !areFieldsValid(validateFieldsToDisable))
      }
      errorComponent={ErrorComponent && <ErrorComponent onReset={onReset} />}
      hideProgress={hideProgress}
      onSkip={handleSkip}
      skip={skip}
    >
      {children}
    </StepLayout>
  )
}
