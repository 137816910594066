import { paths } from '@/routes/paths'
import { FunctionComponent, SVGProps } from 'react'
import { NavLink } from 'react-router-dom'

export type sideBarItem = {
  to?: typeof paths[keyof typeof paths]
  text: string
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  badge?: boolean
  stroke?: boolean
  hidden?: boolean
  onClick?: () => void
}

type SideBarSectionProps = {
  items: sideBarItem[]
}

export const SideBar = ({ items }: SideBarSectionProps) => (
  <div className="flex flex-col gap-[56px] justify-center items-center w-[108px] border-r-[1px] border-sec10 fixed top-0 left-0 bottom-0 sm:flex-row sm:top-[unset] sm:w-screen sm:px-4 sm:gap-2 sm:justify-evenly sm:border-t-[1px] sm:border-r-0 sm:h-[92px] bg-sec0">
    {items
      .filter(({ hidden }) => !hidden)
      .map(({ to, text, icon: Icon, badge, stroke, onClick }: sideBarItem) => (
        <NavLink key={text} to={to || ''} className="!no-underline" onClick={onClick}>
          {({ isActive }) => (
            <div className="flex flex-col items-center">
              <div className="relative">
                <Icon
                  width={32}
                  height={32}
                  className="shrink-0 transition-all"
                  fill={`var(--${isActive ? 'primary' : 'b50'})`}
                  stroke={stroke ? `var(--${isActive ? 'primary' : 'b50'})` : undefined}
                />
                {badge && <div className="badge-danger absolute -right-3 -top-1" />}
              </div>
              <span
                className={`text-textXS transition-all ${isActive ? 'text-primary' : 'text-b50'}`}
              >
                {text}
              </span>
            </div>
          )}
        </NavLink>
      ))}
  </div>
)
