import { PrimaryButton, StepperFormProps } from '@/components'
import { config } from '@/config'
import { AnyObject } from 'yup'
import { Trans, useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    texts: t('onboardingPage.failedSearchModal', { returnObjects: true }),
  }
}

export const OnboardingFailedSearch: StepperFormProps<AnyObject, string>['errorComponent'] = ({
  onReset,
}) => {
  const messages = useMessages()
  return (
    <>
      <span className="text-h2reg text-center">{messages.texts.title}</span>
      <span className="text-b90 text-center">
        {messages.texts.text1}
        <br />
        <br />
        {messages.texts.text2}
      </span>
      <PrimaryButton onClick={onReset} type="reset" block>
        {messages.texts.button}
      </PrimaryButton>
      <div className="text-center text-textS text-b90">
        <Trans
          i18nKey={'onboardingPage.failedSearchModal.hint'}
          values={{
            mail: config.supportEmail,
            phone: config.contactPhone,
          }}
          components={[
            <a className="underline" href={`mailto:${config.supportEmail}`} />,
            <a className="underline" href={`tel:${config.contactPhone}`} />,
          ]}
        />
      </div>
    </>
  )
}
