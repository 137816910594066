import { is, mergeDeepLeft, omit } from 'ramda'
import { useCallback, useMemo, useState } from 'react'
import { warn } from './log'

export const getLocalStorageItem = (key: string) => {
  const item = localStorage.getItem(key)
  try {
    const jsonItem = JSON.parse(item!)
    return jsonItem
  } catch (e) {
    return item
  }
}

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = getLocalStorageItem(key)

      return item || initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = (value: T | ((x: T) => T), stringify = true) => {
    try {
      const valueToStore = is(Function, value) ? value(storedValue) : value

      setStoredValue(valueToStore)
      localStorage.setItem(key, stringify ? JSON.stringify(valueToStore) : (valueToStore as string))
    } catch (error) {
      warn(error)
    }
  }

  return [storedValue, setValue]
}

export const useLocalStorageObject = <T>({
  parent,
  key,
  initialValue,
}: {
  parent: string
  key: string
  initialValue: T
}) => {
  const [object, setObject] = useLocalStorage<Record<string, T>>(parent, {})

  const value = useMemo(() => object[key] || initialValue, [key, object])

  const setValue = useCallback(
    (newValue: T) => {
      setObject(mergeDeepLeft({ [key]: newValue }))
    },
    [key],
  )
  const clearValue = useCallback(() => setObject(omit([key])), [key])

  return {
    value,
    setValue,
    clearValue,
  }
}
