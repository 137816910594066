import { InviteData, RegisterBody } from '@/api'
import {
  AuthTroubleshootLayout,
  OTPInputRef,
  PageLoader,
  StepperForm,
  StepperFormProps,
} from '@/components'
import { useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import { useDoesPhoneExist, useInvite, useRegister } from '@/services'
import { useItemModal, useModal, useSearchParams } from '@/utils'
import { mergeRight, omit, pipe } from 'ramda'
import { useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { InviteOTPStep } from './InviteOTPStep'
import { RegisterProfileStep } from './ProfileStep'
import { RegisterStep } from './RegisterStep'
import { RegisterSuccess } from './RegisterSuccess'
import { stepKeys, validationSchema } from './validationSchema'

type handleSubmitProfile = StepperFormProps<RegisterBody, stepKeys>['steps'][number]['onSubmit']

type stepsType = StepperFormProps<RegisterBody, stepKeys>['steps']

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  phone: '',
}

const makeInitialValues = pipe<
  [InviteData & { invite_code?: string }],
  Omit<InviteData, 'already_registered'>,
  RegisterBody
>(omit(['already_registered']), mergeRight(initialValues))

export const useMessages = () => {
  const { t } = useTranslation()

  return {
    validations: {
      firstName: t('profilePage.validations.firstName'),
      firstNameInvalid: t('profilePage.validations.firstNameInvalid'),
      lastName: t('profilePage.validations.lastName'),
      lastNameInvalid: t('profilePage.validations.lastNameInvalid'),
      inputPhoneNo: t('profilePage.validations.inputPhoneNo'),
      inputEmail: t('profilePage.validations.inputEmail'),
      emailInvalid: t('profilePage.validations.emailInvalid'),
      password: t('changePasswordPage.validations.currentPassLabel'),
      passwordInvalid: t('changePasswordPage.validations.newPassHint'),
    },
    incorrectPinCode: t('registerPage.incorrectPinCode'),
    emailExist: t('registerPage.emailExist'),
    phoneExist: t('registerPage.phoneExist'),
    button: t('registerPage.button'),
    title: t('registerPage.title'),
    step1Title: t('registerPage.step1Title'),
    step2Title: t('registerPage.step2Title'),
    step3Title: t('registerPage.step3Title'),
    errorButton: t('registerPage.errorState.button'),
    errorTitle: t('registerPage.errorState.title'),
  }
}

export const RegisterPage = () => {
  const messages = useMessages()
  const snackbar = useSnackbar()
  const { invite_code } = useSearchParams<{ invite_code?: string }>()
  const { data, isLoading, isError } = useInvite(invite_code)
  const doesPhoneExistMutation = useDoesPhoneExist()

  const navigate = useNavigate()
  const otpInputRef = useRef<OTPInputRef>(null)
  const successState = useModal(false)
  const inviteState = useItemModal<'patient' | 'relative'>()
  const registerMutation = useRegister(false)

  const handleClose: StepperFormProps<RegisterBody, stepKeys>['onClose'] = () => {
    navigate('/')
  }

  const handleSubmit: StepperFormProps<RegisterBody, stepKeys>['onFinish'] = (
    body,
    { goTo },
    { setErrors },
  ) =>
    new Promise((resolve, reject) => {
      if (inviteState.isOpen) {
        body.pin_code = otpInputRef.current?.value

        switch (inviteState.item) {
          case 'patient':
            body.search = { patient_account_creation: true }
            break
          case 'relative':
            body.search = { register_with_existing_invitations: true }
            break
        }
      } else {
        delete body.search
        delete body.pin_code
      }

      registerMutation.mutate(body, {
        onSuccess: ({ invitations_found, patient_account_found }) => {
          if (invitations_found || patient_account_found) {
            inviteState.openItemModal(patient_account_found ? 'patient' : 'relative')
            goTo('invite_otp')
          } else {
            successState.open()
          }
          resolve()
        },
        onError: () => {
          if (inviteState.isOpen) {
            snackbar.error(messages.incorrectPinCode)
            otpInputRef.current?.reset()
          } else {
            setErrors({ email: messages.emailExist })
          }
          // todo snackbar
          reject()
        },
      })
    })

  const handleSubmitProfile: handleSubmitProfile = (values, { next }, { setErrors }) =>
    new Promise<void>((resolve, reject) => {
      doesPhoneExistMutation.mutate(values.phone, {
        onError: (err) => {
          if (err.response?.status === 409) {
            reject()
            setErrors({ phone: messages.phoneExist })
          } else {
            resolve()
            next()
          }
        },
      })
    })

  const steps = useMemo<(stepsType[number] | false)[]>(
    () => [
      {
        key: 'profile',
        component: RegisterProfileStep,
        stepTitle: messages.step1Title,
        onSubmit: handleSubmitProfile,
      },
      {
        key: 'register',
        component: RegisterStep,
        stepTitle: messages.step2Title,
      },
      inviteState.isOpen && {
        key: 'invite_otp',
        component: (props) => <InviteOTPStep {...props} ref={otpInputRef} />,
        stepTitle: messages.step3Title,
      },
    ],
    [inviteState.isOpen],
  )

  if (isLoading) return <PageLoader />

  if (isError)
    return (
      <AuthTroubleshootLayout
        title={messages.errorTitle}
        buttonText={messages.errorButton}
        redirectPath={`/${paths.auth}/${paths.register}`}
      >
        <Trans i18nKey="registerPage.errorState.text" />
      </AuthTroubleshootLayout>
    )

  if (data && data.already_registered) return <Navigate to={`/${paths.auth}/${paths.login}`} /> // todo snackbar

  if (successState.isOpen) return <RegisterSuccess />

  return (
    <StepperForm<RegisterBody, stepKeys>
      initialValues={makeInitialValues({ invite_code, ...(data as InviteData) })}
      onClose={handleClose}
      submitButtonText={messages.button}
      title={messages.title}
      onFinish={handleSubmit}
      steps={steps.filter(Boolean) as stepsType}
      validationSchema={validationSchema(messages.validations)}
      onBack={(step) => {
        if (step === 'invite_otp') {
          inviteState.closeItemModal()
        }
      }}
    />
  )
}
