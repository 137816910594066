import { UpdatePasswordBody } from '@/api'
import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { Alert, ButtonLink, Password } from '@/components'
import { FormItem, useFormContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useModal, useNavigateToPreviousLocation } from '@/utils'
import { isEmpty, map } from 'ramda'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('changePasswordPage.title'),
    currentPassword: t('changePasswordPage.currentPassword'),
    currentPasswordLabel: t('changePasswordPage.currentPasswordLabel'),
    newPassword: t('changePasswordPage.newPassword'),
    newPasswordLabel: t('changePasswordPage.newPasswordLabel'),
    button: t('changePasswordPage.button'),
    save: t('changePasswordPage.save'),
    modalTitle: t('changePasswordPage.confirmModal.title'),
    modalText1: t('changePasswordPage.confirmModal.text1'),
    modalText2: t('changePasswordPage.confirmModal.text2'),
    modalSave: t('changePasswordPage.confirmModal.save'),
    cancel: t('changePasswordPage.confirmModal.cancel'),
  }
}

export const ChangePasswordForm = () => {
  const navigate = useNavigateToPreviousLocation()

  const {
    isValid,
    hasChanges,
    isSubmitting,
    submitForm,
    validateForm,
    setTouched,
  } = useFormContext<UpdatePasswordBody>()

  const disabled = isSubmitting || !isValid || !hasChanges

  const goBack = () => {
    navigate(`/${paths.settings}`)
  }

  const handleSubmit = () =>
    validateForm().then((errors) => {
      if (isEmpty(errors)) {
        submitForm().then(goBack)
      } else {
        setTouched(map(() => true, errors))
      }
    })

  const handleBack = () => {
    if (hasChanges && isValid) {
      alertState.open()
    } else {
      goBack()
    }
  }

  const alertState = useModal(false)
  const messages = useMessages()

  return (
    <div className="page flex justify-center pt-[60px] bg-sec0">
      <div className="subpage-header justify-between">
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={handleBack}
        />
        {messages.title}
        <ButtonLink onClick={handleSubmit} disabled={disabled} type="button">
          {messages.save}
        </ButtonLink>
      </div>
      <div className="max-w-[488px] flex flex-col gap-3 items-center p-4 w-full">
        <div className="w-full text-left text-base">{messages.currentPassword}:</div>
        <FormItem name="old_password" label={`${messages.currentPassword}*`} component={Password} />
        <div className="w-full text-left text-base">{messages.newPassword}:</div>
        <FormItem name="new_password" label={messages.newPasswordLabel} component={Password} />
      </div>
      {alertState.isOpen && (
        <Alert
          title={messages.modalTitle}
          contents={[messages.modalText1, messages.modalText2]}
          actions={[
            { text: messages.modalSave, onClick: () => handleSubmit().finally(alertState.close) },
            { text: messages.cancel, variant: 'secondary', onClick: () => goBack() },
          ]}
        />
      )}
    </div>
  )
}
