import { Snackbar, SnackbarProps } from '@/components'
import { useItemModal } from '@/utils'
import { PropsWithChildren, createContext, useCallback, useContext, useMemo } from 'react'

type SnackbarContextType = Record<
  SnackbarProps['status'],
  (message: SnackbarProps['message'], duration?: number) => void
>

const SnackbarContext = createContext<SnackbarContextType>({
  success: () => {},
  info: () => {},
  error: () => {},
  accent: () => {},
})

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const { isOpen, item, openItemModal, closeItemModal } = useItemModal<
    Omit<SnackbarProps, 'onClose'>
  >()

  const memoizedChildren = useMemo(() => children, [])

  const success = (message: SnackbarProps['message'], duration?: number) => {
    openItemModal({ status: 'success', message, duration })
  }

  const info = (message: SnackbarProps['message'], duration?: number) => {
    openItemModal({ status: 'info', message, duration })
  }

  const error = (message: SnackbarProps['message'], duration?: number) => {
    openItemModal({ status: 'error', message, duration })
  }

  const accent = (message: SnackbarProps['message'], duration?: number) => {
    openItemModal({ status: 'accent', message, duration })
  }

  const memoizedValue = useMemo(() => ({ success, info, error, accent }), [])

  const Component = useCallback(() => {
    if (isOpen) {
      return <Snackbar {...(item as Omit<SnackbarProps, 'onClose'>)} onClose={closeItemModal} />
    }

    return null
  }, [JSON.stringify(item)])

  return (
    <SnackbarContext.Provider value={memoizedValue}>
      <Component />
      {memoizedChildren}
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = () => useContext(SnackbarContext)
