import { api } from './api'

export type NotificationActionType = 'request_confirmed' | 'request_declined'

type NotificationTypeBase = {
  id: string
}

export type OnboardingNotification = NotificationTypeBase & {
  action: 'request_confirmed' | 'request_declined' | 'removed_from_relatives'
  extra_data: {
    patient_first_name: string
    patient_last_name: string
    hospital_name: string
  }
}

export type NotificationType = OnboardingNotification

export const getNotifications = () =>
  api.get<NotificationType[]>('/relatives/notifications_config/get_notifications/')

export const readNotification = (notification_id: string) =>
  api.delete(`/relatives/notifications_config/${notification_id}/read_notification/`)

export type NotificationConfigType = {
  email: boolean
  push: boolean
  sms: boolean
  icu_diary_notifications: boolean
}

export const getNotificationsConfig = () =>
  api.get<NotificationConfigType>('/relatives/notifications_config/me/')

export const updateNotificationConfig = (body: Partial<NotificationConfigType>) =>
  api.patch('/relatives/notifications_config/me/', body)
