import { ChatMessageType } from '@/api'
import { Loader } from '@/components'
import { useAuthContext } from '@/providers'
import { useChat, useTranslate } from '@/services'
import { useLocalStorageObject, useModal } from '@/utils'
import dayjs from 'dayjs'
import { pluck, prop, sortBy } from 'ramda'
import { UIEvent, useCallback, useEffect, useMemo, useRef } from 'react'
import { Location, useLocation, useParams } from 'react-router-dom'
import { ChatMessage } from './ChatMessage'
import { ChatPanel } from './ChatPanel'
import { MeetingPrompt } from './MeetingPrompt'
import { PinnedMessages } from './PinnedMessages'

type TransformedMessageType = ChatMessageType & { firstDayMessage?: string }

export const ChatPage = () => {
  const { state } = useLocation() as Omit<Location, 'state'> & {
    state?: {
      meetingPrompt?: boolean
    }
  }
  const { user, currentPatient } = useAuthContext()
  const { patientId } = useParams<{ patientId: string }>()
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const translateMutation = useTranslate(patientId!)
  const { value, setValue } = useLocalStorageObject<Record<string, string>>({
    parent: 'translations',
    key: user.language!,
    initialValue: {},
  })
  const meetingPrompt = useModal(state?.meetingPrompt!)

  // todo cleanup
  const onFetch = useCallback(
    (data: ChatMessageType[]) => {
      const toBeTranslated = data
        .filter(
          ({ needs_to_be_translated, send_by, id, text }) =>
            id &&
            text &&
            !value?.[id] &&
            needs_to_be_translated &&
            (typeof send_by !== 'object' || send_by?.id !== user.id),
        )
        .map(({ id, text }) => ({ id: id!, text: text! }))

      const textToBeTranslated = pluck('text', toBeTranslated)

      if (textToBeTranslated.length) {
        translateMutation.mutate(
          { messages: textToBeTranslated },
          {
            onSuccess: (data) => {
              setValue({
                ...value,
                ...toBeTranslated
                  .map(({ id }, i) => ({ id, text: data[i] }))
                  .reduce((acc, cur) => ({ ...acc, [cur.id]: cur.text }), {}),
              })
            },
          },
        )
      }
    },
    [JSON.stringify(value)],
  )

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage, isFetched } = useChat(
    patientId as string,
    {
      select: sortBy(prop('send_at')),
      onSuccess: onFetch,
      refetchInterval: currentPatient?.active && 15000,
    },
  )

  const { messages = [], chat_communication_method_for_relatives } = data || {}

  const scrollToBottom = (behavior?: ScrollBehavior) => {
    messagesEndRef.current?.scrollIntoView({ behavior })
  }

  const handleScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      if (e.currentTarget.scrollTop < 3) {
        e.currentTarget.scrollTop = 3
      }

      if (e.currentTarget.scrollTop < 200 && hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    },
    [hasNextPage, isFetchingNextPage],
  )

  const transformedMessages = useMemo<TransformedMessageType[]>(() => {
    const datesBuffer: string[] = []
    return messages.map((message) => {
      const formattedDate = dayjs(message.send_at).format('dd. DD. MMMM')
      if (!datesBuffer.includes(formattedDate as string)) {
        datesBuffer.push(formattedDate as string)
        return { ...message, firstDayMessage: formattedDate }
      }

      return message
    })
  }, [JSON.stringify(pluck('id', messages))])

  useEffect(() => {
    // on new message
    if (messages?.[messages.length - 1]) {
      scrollToBottom('smooth')
    }
  }, [messages?.[messages.length - 1]?.id])

  useEffect(() => {
    if (isFetched) {
      scrollToBottom()
    }
  }, [isFetched])

  useEffect(() => {
    if (state?.meetingPrompt && !meetingPrompt.isOpen) {
      meetingPrompt.open()
    }
  }, [state?.meetingPrompt])

  return (
    <div
      className={`overflow-auto flex justify-center w-full ${
        chat_communication_method_for_relatives && !currentPatient?.is_patient_user
          ? chat_communication_method_for_relatives === 'FREE_TEXT_AND_TEXT_ELEMENTS'
            ? 'h-[calc(100%-112px)]'
            : 'h-[calc(100%-74px)]'
          : ''
      }`}
      onScroll={handleScroll}
    >
      {meetingPrompt.isOpen && <MeetingPrompt onClose={meetingPrompt.close} />}
      <div className="max-w-[488px] flex flex-col gap-8 p-4 w-full h-full pt-[60px]">
        <PinnedMessages />
        {(isFetching || isFetchingNextPage) && <Loader />}
        {transformedMessages.map((message) => {
          return (
            <div key={`${message.id}.${user.language}`}>
              {message.firstDayMessage && (
                <div className="text-center mb-6 text-b90 text-textS">
                  {message.firstDayMessage}
                </div>
              )}
              <ChatMessage {...message} translation={value[message.id!]} />
            </div>
          )
        })}
        <div ref={messagesEndRef} />
        {chat_communication_method_for_relatives && !currentPatient?.is_patient_user && (
          <ChatPanel type={chat_communication_method_for_relatives} />
        )}
      </div>
    </div>
  )
}
