type RadioProps = {
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}

export const Switch = ({ checked, onChange, disabled }: RadioProps) => {
  const handleClick = () => {
    if (!disabled) onChange(!checked)
  }

  return (
    <div
      className={`w-[52px] h-[32px] rounded-[28px] p-[2px] transition-all 
      ${checked ? 'bg-success' : 'bg-b30'}
      ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
      `}
      onClick={handleClick}
    >
      <div
        className={`w-[28px] h-[28px] rounded-[50%] bg-b0 transition-all ${
          checked ? 'translate-x-[20px]' : ''
        }`}
      />
    </div>
  )
}
