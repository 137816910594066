import { both, complement, curry, is } from 'ramda'

export const changeDateFormat = curry(
  (oldSeparator: '.' | '-', newSeparator: '-' | '.', datestring: string) => {
    if (!datestring) return ''

    const [day, month, year] = datestring.split(oldSeparator)

    if (day && month && year) return [year, month, day].join(newSeparator)

    return datestring
  },
)
export const validateDate = (datestring = '') => {
  const [year, month, day] = datestring.split('-')

  const date = new Date(datestring)

  return (
    Boolean(day && month && year) &&
    year.length === 4 &&
    isValidDate(date) &&
    date <= new Date() &&
    date > new Date('1900')
  )
}

export const isValidDate = both(is(Date), complement(isNaN)) as (date: Date | number) => boolean

export const getDaysDiff = (date1: number | string | Date, date2: number | string | Date) => {
  const distance = new Date(date2).getTime() - new Date(date1).getTime()

  return Math.abs(Math.ceil(distance / (1000 * 60 * 60 * 24)))
}

export const getDaysPassed = (pastDate: number | string | Date) => getDaysDiff(pastDate, new Date())
