import {
  MatchingInviteRespondType,
  Patient,
  PatientInviteType,
  RelationType,
  Survey,
  SurveyBody,
  acceptMatchingInvite,
  addSurveyData,
  checkSuperRelative,
  createSurveyData,
  declineMatchingInvite,
  getPatient,
  getPatientInvite,
  getSurveyData,
  listPatients,
  queryPatientByBirthdate,
  queryPatientByFirstName,
  queryPatientByLastName,
  removePatient,
  resendPatientInvite,
  submitOnboarding,
} from '@/api'
import { useAuthContext } from '@/providers'
import {
  ReactQueryOptions,
  reactQueryKeys,
  useInvalidateOnSuccessMutation,
  useMutation,
} from '@/utils'
import { AxiosError } from 'axios'
import { or, pipe, prop } from 'ramda'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

export const usePatientsList = () => useQuery(reactQueryKeys.patients, listPatients)

export const usePatient = <T>(
  patientId: string,
  options: ReactQueryOptions<Patient, ['patients', string], T> = {},
) =>
  useQuery([reactQueryKeys.patients, patientId], () => getPatient(patientId as string), {
    enabled: Boolean(patientId),
    ...options,
  })

export const useCurrentPatient = <T = Patient>(
  options?: ReactQueryOptions<Patient, ['patients', string], T>,
) => {
  const { patientId } = useParams()

  return usePatient(patientId!, options)
}

export const useCheckSuperRelative = (
  patientId?: number,
  options?: ReactQueryOptions<{ super_relative: boolean }, ['superRelative', string], boolean>,
) =>
  useQuery(
    [reactQueryKeys.superRelative, String(patientId)],
    () => checkSuperRelative(String(patientId)),
    options,
  )

export const useCheckCurrentSuperRelative = () => {
  const { currentPatient } = useAuthContext()

  return useCheckSuperRelative(currentPatient?.id, {
    enabled: Boolean(currentPatient?.id && currentPatient.connection_status === 'assigned'),
    select: pipe(prop('super_relative'), or(currentPatient?.is_patient_user)),
  })
}

export const useQueryPatientByBirthdate = () => useMutation(queryPatientByBirthdate)
export const useQueryPatientByLastName = () => useMutation(queryPatientByLastName)
export const useQueryPatientByFirstName = () => useMutation(queryPatientByFirstName)

export const useSubmitOnboarding = () =>
  useInvalidateOnSuccessMutation('patients', submitOnboarding, true)

export const useAcceptMatchingInvite = (params: MatchingInviteRespondType) =>
  useInvalidateOnSuccessMutation(
    'patients',
    ({ relation }: { relation?: RelationType }) => acceptMatchingInvite({ ...params, relation }),
    true,
  )

export const useDeclineMatchingInvite = (params: MatchingInviteRespondType) =>
  useInvalidateOnSuccessMutation('patients', () => declineMatchingInvite(params), true)

export const useRemovePatient = (patientId: number) =>
  useInvalidateOnSuccessMutation('patients', () => removePatient(patientId), true)

export const useQueryPatientSurvey = (patientId?: number) =>
  useQuery<Survey, AxiosError<{ first_name: string; last_name: string }>>(
    [reactQueryKeys.survey, String(patientId)],
    () => getSurveyData(patientId!),
    {
      enabled: Boolean(patientId),
    },
  )

export const useCreatePatientSurvey = (patientId: number) =>
  useInvalidateOnSuccessMutation(['survey', String(patientId)], (body: SurveyBody) =>
    createSurveyData(patientId, body),
  )

export const useUpdatePatientSurvey = (patientId: number, surveyId: string) =>
  useMutation((body: SurveyBody) => addSurveyData(patientId, surveyId, body))

export const usePatientInvite = (
  patientId: number,
  options?: ReactQueryOptions<PatientInviteType, ['patientInvite', string]>,
) =>
  useQuery(
    [reactQueryKeys.patientInvite, String(patientId)],
    () => getPatientInvite(patientId),
    options,
  )

export const useResendPatientInvite = (patientId: number) =>
  useInvalidateOnSuccessMutation(['patientInvite', String(patientId)], () =>
    resendPatientInvite(patientId),
  )
