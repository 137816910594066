import { resources } from '@/@types/resources'
import { RelationType, SettingsType, getDocumentTypes, getSettings, uploadDocuments } from '@/api'
import { reactQueryKeys, useCachedQuery, useInvalidateOnSuccessMutation } from '@/utils'
import { evolve, flip, map, or, pipe } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

type relation = SettingsType['relationships'][number]

const relationsToKeysMap: Record<RelationType, keyof typeof resources.DE.translation.relations> = {
  'Partner:in': 'partner',
  'Ehepartner:in': 'spouse',
  Elternteil: 'parent',
  'Sohn / Tochter': 'children',
  'Schwester / Bruder': 'sibling',
  'Enkel:in': 'grandchildren',
  'Freund:in': 'friend',
  'andere:r Verwandte:r': 'relative',
  'Bekannte:r': 'known',
  Unbekannt: 'unknown',
}

export const useSettings = () => {
  const { t } = useTranslation()

  const relations = t('relations', { returnObjects: true })

  return useCachedQuery(reactQueryKeys.settings, getSettings, {
    select: pipe(
      flip<SettingsType, SettingsType, SettingsType>(or)({
        relationships: [],
        languages: [],
      }),
      evolve({
        relationships: map<relation, relation>(({ value }) => ({
          value,
          label: relations[relationsToKeysMap[value]],
        })),
      }),
    ),
  })
}

export const useDocumentTypes = (patientId?: string) =>
  useQuery([reactQueryKeys.documentTypes, patientId], () => getDocumentTypes(patientId!), {
    enabled: Boolean(patientId),
  })

export const useUploadDocuments = (patientId: string) =>
  useInvalidateOnSuccessMutation(
    ['documentTypes', String(patientId)],
    ({ file, document_request }: { file: File; document_request: string }) =>
      uploadDocuments(patientId, file, document_request),
  )
