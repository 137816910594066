import { AccessDenied } from '@/components'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('exceededOnboardingQuotaPage.title'),
    text: t('exceededOnboardingQuotaPage.text'),
  }
}
export const ExceededOnboardingQuotaPage = () => {
  const messages = useMessages()

  return (
    <AccessDenied
      title={messages.title}
      content={messages.text}
      contactI18nKey="exceededOnboardingQuotaPage.text2"
    />
  )
}
