/* eslint-disable @typescript-eslint/no-explicit-any */
import { Hospital, Station, listHospitals, listStations } from '@/api'
import { PaginatedResponseType, ReactQueryOptions, reactQueryKeys } from '@/utils'
import { AxiosError } from 'axios'
import { pipe, prop } from 'ramda'
import { useQuery } from 'react-query'

type listHospitalsOptionsType = ReactQueryOptions<
  PaginatedResponseType<Hospital>,
  'hospitals',
  Hospital[]
>

export const useHospitalList = <T extends any[] = Hospital[]>({
  select = (a) => a as T,
  ...options
}: Omit<listHospitalsOptionsType, 'select'> & {
  select?: (hospitals: Hospital[]) => T
} = {}) =>
  useQuery<PaginatedResponseType<Hospital>, AxiosError, T, 'hospitals'>(
    reactQueryKeys.hospitals,
    listHospitals,
    {
      ...options,
      select: pipe<PaginatedResponseType<Hospital>[], Hospital[], T>(prop('results'), select),
    },
  )

export const useStationList = <T extends any[] = Station[]>(
  hospitalId: string,
  options: Omit<ReactQueryOptions<Station[], ['stations', string]>, 'select'> & {
    select?: (stations: Station[]) => T
  } = {},
) =>
  useQuery<Station[], AxiosError, T, ['stations', string]>(
    [reactQueryKeys.stations, hospitalId],
    () => listStations(hospitalId),
    options,
  )
