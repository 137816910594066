import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [pathname])
}

export const usePreventMobileZoom = () => {
  useEffect(() => {
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      const el = document.querySelector('meta[name=viewport]')

      if (el !== null) {
        let content = el.getAttribute('content')
        const re = /maximum-scale=[0-9.]+/g

        if (content && re.test(content)) {
          content = content.replace(re, 'maximum-scale=1.0')
        } else {
          content = [content, 'maximum-scale=1.0'].join(', ')
        }

        el.setAttribute('content', content)
      }
    }
  })
}

export const useOutsideClick = <T extends HTMLDivElement = HTMLDivElement>(
  ref: T | null,
  onOutsideClick: () => void,
) =>
  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent) => {
      if (ref && !ref.contains(target as Node)) {
        onOutsideClick()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

export const useFloatPositioning = (
  ref: HTMLDivElement | null,
  containerRef: HTMLDivElement | null,
) => {
  useEffect(() => {
    if (ref && containerRef && window.innerWidth > 600) {
      const elementBounds = containerRef.getBoundingClientRect()

      const top = elementBounds.bottom + 16

      ref.style.position = 'fixed'
      ref.style.left = `${elementBounds.left}px`
      ref.style.top = `${top}px`
      ref.style.maxHeight = `${window.innerHeight - top}px`

      if (window.innerWidth - elementBounds.left <= ref.offsetWidth) {
        ref.style.left = 'unset'
        ref.style.right = `${window.innerWidth - elementBounds.right}px`
      }
    }
  }, [ref, containerRef])
}

export const loadScript = (src: string) => {
  return new Promise<void>((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve()
    }

    script.onerror = () => {
      reject()
    }
    document.body.appendChild(script)
  })
}
