import { PatientListItem } from '@/api'
import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { ReactComponent as PlusIcon } from '@/assets/plus.svg'
import { Alert, Popover, PrimaryButton, Radio } from '@/components'
import { useAuthContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useItemModal } from '@/utils'
import { includes, pipe, pluck } from 'ramda'
import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type InactiveAlertState = {
  id: number
  days: number
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    button: t('patientDropdown.button'),
    statusAssigned: t('patientDropdown.statusAssigned'),
    statusInProgress: t('patientDropdown.statusInProgress'),
    statusWaiting: t('patientDropdown.statusWaiting'),
    statusDefault: (days: number) => t('patientDropdown.statusDefault', { days }),
    inactiveAlertTitle: t('patientDropdown.inactiveAlertTitle'),
    inactiveAlertPrimaryButton: t('patientDropdown.inactiveAlertPrimaryButton'),
    cancel: t('global.cancel'),
    inactiveAlertContent1: t('patientDropdown.inactiveAlertContent1'),
    inactiveAlertContent3: t('patientDropdown.inactiveAlertContent3'),
  }
}

const doesMatchInviteExist = pipe(
  pluck('connection_status') as (
    patients: PatientListItem[],
  ) => PatientListItem['connection_status'][],
  includes<PatientListItem['connection_status']>('need_to_accept'),
)

const CurrentPatientDropdown = ({ onClick }: { onClick?: () => void }) => {
  const { patients, currentPatient } = useAuthContext()

  return (
    <div className="flex gap-2 items-center cursor-pointer" onClick={onClick}>
      {doesMatchInviteExist(patients) && <div className="badge-danger" />}
      <span className="text-h3 ellipsis">
        {currentPatient?.first_name} {currentPatient?.last_name}
      </span>
      <ArrowLeft className="shrink-0 -rotate-90" width={16} height={16} fill="var(--primary)" />
    </div>
  )
}

const getColor = (active: boolean, status: PatientListItem['connection_status']) => {
  switch (status) {
    case 'assigned':
      if (active) return 'success'
      return 'danger'
    case 'in_progress':
      return 'warning'
    case 'need_to_accept':
      return 'primary'
  }
}

const getPatientStatus = ({
  active,
  messages,
  days_left_until_read_only_mode_ends,
  connection_status,
}: Pick<PatientListItem, 'active' | 'connection_status' | 'days_left_until_read_only_mode_ends'> & {
  messages: ReturnType<typeof useMessages>
}) => {
  switch (connection_status) {
    case 'assigned':
      if (active) return messages.statusAssigned
      return messages.statusDefault(days_left_until_read_only_mode_ends!)
    case 'in_progress':
      return messages.statusInProgress
    case 'need_to_accept':
      return messages.statusWaiting
  }
}

type PatientPopoverContentProps = {
  onClose: () => void
  onInactiveSelect: ({ id, days }: InactiveAlertState) => void
}

const PatientPopoverContent = ({ onClose, onInactiveSelect }: PatientPopoverContentProps) => {
  const navigate = useNavigate()
  const { patients, currentPatient } = useAuthContext()

  const handleAddPatient = () => {
    navigate(`/${paths.onboarding}`)
  }

  const handleChangePatient = (id: number, active: boolean, days: number) => {
    if (id !== currentPatient?.id) {
      if (active) {
        navigate(window.location.pathname.replace(`/${currentPatient?.id}/`, `/${id}/`))
      } else {
        onInactiveSelect({ id, days })
      }
    }
    onClose()
  }
  const messages = useMessages()

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col">
        {patients.map(
          ({
            id,
            first_name,
            last_name,
            hospital,
            station,
            connection_status,
            active,
            days_left_until_read_only_mode_ends,
          }) => (
            <div
              className="flex gap-2 py-4 items-center border-t-[1px] border-t-sec10 first:border-0 cursor-pointer"
              onClick={() => handleChangePatient(id, active, days_left_until_read_only_mode_ends!)}
            >
              <Radio
                checked={currentPatient?.id === id}
                onChange={() =>
                  handleChangePatient(id, active, days_left_until_read_only_mode_ends!)
                }
              />
              <div className="flex flex-col gap-2 overflow-hidden flex-1">
                <span className="text-h4 ellipsis">
                  {first_name} {last_name}
                </span>
                <div className="flex flex-col gap-1 text-textS">
                  <span className="ellipsis text-b50">
                    {hospital} - {station}
                  </span>
                  <span
                    style={{
                      color: `var(--${getColor(
                        active,
                        connection_status as PatientListItem['connection_status'],
                      )})`,
                    }}
                  >
                    {getPatientStatus({
                      active,
                      connection_status,
                      days_left_until_read_only_mode_ends,
                      messages,
                    })}
                  </span>
                </div>
              </div>
              {connection_status === 'need_to_accept' && <div className="badge-danger" />}
            </div>
          ),
        )}
      </div>
      <PrimaryButton onClick={handleAddPatient} block>
        <PlusIcon width={16} height={16} />
        {messages.button}
      </PrimaryButton>
    </div>
  )
}

export const PatientsDropdown = () => {
  const messages = useMessages()
  const inactiveAlertState = useItemModal<InactiveAlertState>()
  const { currentPatient } = useAuthContext()
  const navigate = useNavigate()

  const memoizedPopoverContent = useCallback(
    (props: Pick<PatientPopoverContentProps, 'onClose'>) => (
      <PatientPopoverContent {...props} onInactiveSelect={inactiveAlertState.openItemModal} />
    ),
    [],
  )

  const handleNavigate = () => {
    navigate(
      window.location.pathname.replace(
        `/${currentPatient?.id}/`,
        `/${inactiveAlertState.item?.id}/`,
      ),
    )
    inactiveAlertState.closeItemModal()
  }

  if (inactiveAlertState.isOpen)
    return (
      <Alert
        title={messages.inactiveAlertTitle}
        contents={[
          messages.inactiveAlertContent1,
          <Trans
            i18nKey="patientDropdown.inactiveAlertContent2"
            values={{
              days: inactiveAlertState.item?.days,
            }}
          />,
          messages.inactiveAlertContent3,
        ]}
        actions={[
          {
            text: messages.inactiveAlertPrimaryButton,
            onClick: handleNavigate,
            variant: 'primary',
          },
          {
            text: messages.cancel,
            onClick: inactiveAlertState.closeItemModal,
            variant: 'secondary',
          },
        ]}
      />
    )

  return (
    <Popover customPopoverContent={memoizedPopoverContent} className="flex-1 overflow-hidden">
      <CurrentPatientDropdown />
    </Popover>
  )
}
