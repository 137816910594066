import { AssetType } from '@/api'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type PreviewProps = {
  assetType: AssetType
  onClose: () => void
  onConfirm?: () => void
} & ({ file: File; url?: never } | { url: string; file?: never })

const useMessages = () => {
  const { t } = useTranslation()

  return {
    save: t('mediaPage.save'),
    cancel: t('mediaPage.cancel'),
  }
}

export const Preview = ({ file, url, assetType, onClose, onConfirm }: PreviewProps) => {
  const src = useMemo(() => (file ? URL.createObjectURL(file) : url), [])
  const messages = useMessages()

  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 bg-b100 items-center justify-center flex z-[1000]">
      {assetType === 'image' ? (
        <img
          src={src}
          alt="chosen-image"
          className="object-contain w-full !max-h-[calc(100vh-60px)] !max-w-screen"
        />
      ) : assetType === 'video' ? (
        <video
          src={src}
          loop
          autoPlay
          controls
          className="object-contain w-full !max-h-[calc(100vh-60px)] !max-w-screen"
        />
      ) : null}
      <div className="absolute bottom-0 left-0 right-0 h-[60px] px-4 flex justify-between items-center backdrop-blur-[5px]">
        <div className="text-button text-b0 cursor-pointer uppercase" onClick={onClose}>
          {messages.cancel}
        </div>
        {onConfirm && (
          <div className="text-button text-warning cursor-pointer uppercase" onClick={onConfirm}>
            {messages.save}
          </div>
        )}
      </div>
    </div>
  )
}
