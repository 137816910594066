import { ReactComponent as TickIcon } from '@/assets/tick.svg'
import { InputHTMLAttributes } from 'react'

type CheckboxProps = {
  checked: boolean
  onChange: InputHTMLAttributes<HTMLInputElement>['onChange']
  label: string | JSX.Element
  error?: boolean
  id?: string
}

export const Checkbox = ({ checked, onChange, label, error, id, ...props }: CheckboxProps) => {
  return (
    <div className="flex gap-2">
      <div
        className={`w-[20px] h-[20px] rounded-[4px] flex items-center justify-center 
                cursor-pointer transition-all border-[1px] shrink-0
                ${
                  checked
                    ? 'bg-primary border-primary'
                    : error
                    ? 'bg-b0 border-danger'
                    : 'bg-b0 border-sec50'
                }`}
      >
        <input
          className="opacity-0 absolute"
          id={id}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          {...props}
        />
        {checked && <TickIcon width={16} height={16} fill="var(--b0)" className="shrink-0" />}
      </div>
      <label
        htmlFor={id}
        className={`text-textBase transition-all -mt-1 ${error ? 'text-danger' : 'text-b50'}`}
      >
        {label}
      </label>
    </div>
  )
}
