import { Loader, PageLoader } from '@/components'
import { useAuthContext, useSnackbar, useSurveyContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useMeeting } from '@/services'
import { postMessageToWebview, useModal, useNavigateToPreviousLocation } from '@/utils'
import { JitsiMeeting } from '@jitsi/react-sdk'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    snackError: t('global.snackError'),
  }
}

export const VideoCallPage = () => {
  const snackbar = useSnackbar()
  const { currentPatient, user } = useAuthContext()
  const surveyContext = useSurveyContext()
  const navigate = useNavigateToPreviousLocation()
  const readyState = useModal(false)
  const messages = useMessages()

  const { data, isLoading } = useMeeting(currentPatient?.id!)

  const handleError = () => {
    snackbar.error(messages.snackError)
    navigate(`/${paths.home}`)
  }

  useEffect(() => {
    if (!isLoading) {
      if (data?.status && data.status !== 'inactive') {
        postMessageToWebview({
          type: 'joinMeeting',
          params: {
            meetingId: data.id,
            meetingToken: data.signature,
            meetingRoom: data.room_name,
            userInfo: {
              email: user.email,
              displayName: user.first_name,
              avatarURL: user.avatar,
            },
            language: user.language?.toLowerCase(),
          },
          callback: async () => {
            surveyContext.setMeetingSurveyId(data.id)
            readyState.open()
          },
        })
        // @ts-ignore
        if (window.ReactNativeWebView) {
          navigate(`/${paths.home}`)
        }
      } else {
        handleError()
      }
    }
  }, [isLoading])

  if (isLoading || !readyState.isOpen) return <PageLoader />

  if (data?.status && data.status !== 'inactive') {
    return (
      <JitsiMeeting
        roomName={data.room_name}
        jwt={data.signature}
        configOverwrite={{
          disableThirdPartyRequests: true,
          disableLocalVideoFlip: true,
          backgroundAlpha: 0.5,
          hiddenPremeetingButtons: ['microphone'],
          startWithAudioMuted: true,
        }}
        interfaceConfigOverwrite={{
          VIDEO_LAYOUT_FIT: 'nocrop',
          MOBILE_APP_PROMO: false,
          TILE_VIEW_MAX_COLUMNS: 4,
        }}
        spinner={Loader}
        getIFrameRef={(node) => (node.style.height = '800px')}
        lang={user.language?.toLowerCase()}
        userInfo={{
          email: user.email,
          displayName: `${user.first_name || 'Relativ'} ${user.last_name}`,
        }}
        onReadyToClose={() => {
          navigate(`/${paths.home}`, { meetingSurveyId: data.id })
        }}
      />
    )
  }

  return null
}
