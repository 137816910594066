import { ReactComponent as CalendarIcon } from '@/assets/calendar.svg'
import { changeDateFormat } from '@/utils'
import { ComponentProps } from 'react'
import { Input } from '../Input'

const r = /^([0-3]$|(0[1-9]|[12][0-9]|3[01])\.?($|\.([01]$|(0[1-9]|1[0-2])\.?($|\.[12]$|(19|20)\d{0,2}$))))/

type DateInputProps = Omit<ComponentProps<typeof Input>, 'type' | 'value'> & {
  value?: string
}

const Component = ({
  onChange,
  value,
  ...props
}: Omit<DateInputProps, 'value'> & { value: string }) => {
  const handleChange: DateInputProps['onChange'] = (e) => {
    const { value: newValue } = e.target

    if (!newValue || r.test(newValue)) {
      if (value.length < newValue.length) {
        if ([2, 5].includes(newValue.length)) {
          e.target.value += '.'
        }
      } else {
        if (value.endsWith('.')) {
          e.target.value = e.target.value.slice(0, -1)
        }
      }

      e.target.value = changeDateFormat('.', '-', e.target.value)
      onChange?.(e)
    }
  }

  return (
    <Input
      inputMode="numeric"
      placeholder="tt.mm.jjjj"
      {...props}
      value={value}
      onChange={handleChange}
    />
  )
}

export const DateInput = ({ value, ...props }: DateInputProps) => (
  <div className="relative">
    <Component value={changeDateFormat('-', '.', value || '')} {...props} className="pr-8" />
    <CalendarIcon className="absolute right-4 cursor-pointer top-4" width={16} height={16} />
  </div>
)
