import { ReactComponent as ArrowIcon } from '@/assets/arrow-left.svg'
import { ButtonHTMLAttributes, DetailedHTMLProps, FunctionComponent, SVGProps } from 'react'

type ButtonVariant = 'contained' | 'outlined'

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  variant: ButtonVariant
  block?: boolean
  danger?: boolean
}

const variantStyles: Record<ButtonVariant, string> = {
  contained:
    'bg-primary text-b0 active:bg-b0 active:text-primary svg:fill-b0 svg:active:fill-primary',
  outlined:
    'bg-b0 text-primary active:bg-primary active:text-b0 svg:fill-primary svg:active:fill-b0',
}

const dangerVariantStyles: Record<ButtonVariant, string> = {
  contained:
    'bg-danger text-b0 active:bg-b0 active:text-danger !border-danger svg:fill-b0 svg:active:fill-primary',
  outlined:
    'bg-b0 text-danger active:bg-danger active:text-b0 !border-danger svg:fill-primary svg:active:fill-b0',
}

export const ButtonBase = ({
  className = '',
  children,
  variant,
  block,
  danger,
  disabled,
  ...props
}: ButtonProps) => (
  <button
    disabled={disabled}
    className={`h-[48px] flex items-center justify-center gap-2 shrink-0
                text-button uppercase rounded-[8px] border-[1px] border-primary 
                cursor-pointer transition-all duration-[400ms] 
                ${disabled ? '' : (danger ? dangerVariantStyles : variantStyles)[variant]} 
                ${block ? 'w-full' : ''} ${className}`}
    {...props}
  >
    {children}
  </button>
)

export const PrimaryButton = (props: Omit<ButtonProps, 'variant'>) => (
  <ButtonBase variant="contained" {...props} />
)

export const SecondaryButton = (props: Omit<ButtonProps, 'variant'>) => (
  <ButtonBase variant="outlined" {...props} />
)

export const ButtonLink = ({
  onClick,
  disabled,
  children,
  ...props
}: Omit<ButtonProps, 'variant'>) => {
  const handleClick: typeof onClick = (e) => {
    if (!disabled) {
      onClick?.(e)
    }
  }

  return (
    <button
      {...props}
      onClick={handleClick}
      className={`text-button ${
        disabled ? 'text-b50 cursor-not-allowed' : 'text-primary cursor-pointer'
      } ${props.className}`}
    >
      {children}
    </button>
  )
}

export const BannerButton = ({
  text,
  icon: Icon,
}: {
  text: string
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
}) => (
  <div className="flex items-center justify-between w-full px-4">
    <div className="flex items-center gap-2 normal-case text-b100 text-h4">
      {Icon && <Icon width={16} height={16} className="shrink-0" fill="var(--primary)" />}
      {text}
    </div>
    <ArrowIcon width={16} height={16} className="shrink-0 rotate-180" fill="var(--b50)" />
  </div>
)
