import { TextElementType } from '@/api'
import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { ReactComponent as SendIcon } from '@/assets/send.svg'
import { HorizontalScrollerWithDots, IconButton } from '@/components'
import { useAuthContext } from '@/providers'
import { useTextElements } from '@/services'
import { length, pipe, reject, when } from 'ramda'
import { useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

type TextElementsProps = {
  onSubmit: (text: string) => void
  onClose: () => void
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('chatMessage.textElementsTitle'),
    text: t('chatMessage.textElementsText'),
  }
}

const TextElement = ({ text, onSend }: { text: string; onSend: (text: string) => void }) => {
  const { currentPatient } = useAuthContext()

  const message = useMemo(
    () =>
      text
        .replace(/{patient_name}/g, `${currentPatient?.first_name} ${currentPatient?.last_name}`)
        .replace(/{hospital_name}/g, currentPatient?.hospital!),
    [text],
  )

  const handleSend = () => {
    onSend(message)
  }

  return (
    <div className="flex flex-col gap-2 rounded-[8px] border-[0.5px] border-sec30 bg-sec10 p-2 justify-between w-[240px] sm:max-w-[214px] shrink-0">
      <span>{message}</span>
      <IconButton
        icon={SendIcon}
        size={42}
        variant="contained"
        onClick={handleSend}
        className="ml-auto"
        iconProps={{
          stroke: 'var(--sec10)',
          width: 24,
          height: 24,
        }}
      />
    </div>
  )
}

const TextElementsCategory = ({
  id,
  name,
  text,
  options,
  onSubmit,
}: TextElementType & Pick<TextElementsProps, 'onSubmit'>) => (
  <div className="flex flex-col gap-2">
    <div className="text-h4">{name}</div>
    <HorizontalScrollerWithDots
      gap={8}
      count={options?.length || 0}
      scrollDisabled={document.body.offsetWidth > 600}
      containerClassName="flex-wrap sm:flex-nowrap"
    >
      {text && text.length ? (
        <TextElement key={id} text={text[0]} onSend={onSubmit} />
      ) : (
        options?.map(({ id, text }) => <TextElement key={id} text={text[0]} onSend={onSubmit} />)
      )}
    </HorizontalScrollerWithDots>
  </div>
)

const transform = when(
  pipe(length, Boolean),
  reject<TextElementType>(({ text, options }) => !text && !options?.length),
)

export const TextElements = ({ onSubmit, onClose }: TextElementsProps) => {
  const { currentPatient } = useAuthContext()
  const { data = [] } = useTextElements(currentPatient?.id!, { select: transform })
  const messages = useMessages()

  return createPortal(
    <div className="fixed top-0 bottom-0 left-0 right-0 animate-slide-up flex justify-center bg-sec0 z-[10000] pt-[60px] overflow-y-auto">
      <div className="subpage-header">
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={onClose}
        />
        {messages.title}
        <div />
      </div>
      <div className="max-w-[488px] flex flex-col gap-6 py-4 sm:p-4 w-full overflow-y-auto">
        <div className="text-b90 text-textS">{messages.text}:</div>
        {data.map((item) => (
          <TextElementsCategory key={item.id} {...item} onSubmit={onSubmit} />
        ))}
      </div>
    </div>,
    document.body,
  )
}
