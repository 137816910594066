import { range } from 'ramda'
import { PropsWithChildren, useMemo, useRef, useState } from 'react'

type HorizontalScrollerWithDotsProps = PropsWithChildren<{
  count: number
  gap: number
  containerClassName?: string
  scrollDisabled?: boolean
  style?: object
}>

export const HorizontalScrollerWithDots = ({
  count,
  gap,
  containerClassName,
  children,
  scrollDisabled = false,
  style,
}: HorizontalScrollerWithDotsProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [current, setCurrent] = useState(0)

  const elementWidth = useMemo(() => {
    if (ref.current && count) return (ref.current.scrollWidth - gap * (count - 1)) / count
    return 0
  }, [ref.current, count, gap])

  return (
    <div className="flex flex-col gap-2" style={style}>
      <div
        ref={ref}
        style={{ gap: `${gap}px` }}
        className={`flex shrink-0 overflow-x-auto no-scrollbar ${containerClassName}`}
        onScroll={(e) => {
          if (!scrollDisabled) {
            if (e.currentTarget.clientWidth === elementWidth) {
              setCurrent(Math.floor(e.currentTarget.scrollLeft / elementWidth))
            } else {
              const sum = e.currentTarget.clientWidth + e.currentTarget.scrollLeft
              setCurrent(Math.floor(sum / elementWidth) - 1)
            }
          }
        }}
      >
        {children}
      </div>
      {Boolean(count) && !scrollDisabled && (
        <div className="gap-2 justify-center flex">
          {range(0, count).map((i) => (
            <div
              key={i}
              className={`w-[6px] h-[6px] rounded-[50%] transition-all duration-[400ms] ${
                current === i ? 'bg-primary' : 'bg-b10'
              }`}
              onClick={() => {
                if (ref.current) {
                  ref.current.scroll({
                    behavior: 'smooth',
                    left: i * elementWidth + i * gap,
                  })
                }
              }}
            />
          ))}
        </div>
      )}
    </div>
  )
}
