import { PageLoader } from '@/components'
import { AuthenticatedOutlet, useAuthContext } from '@/providers'
import { Navigate } from 'react-router-dom'

export const ActivePatientGuard = () => {
  const { currentPatient, arePatientsLoading } = useAuthContext()

  if (arePatientsLoading) return <PageLoader />

  if (currentPatient?.id && currentPatient.connection_status === 'assigned')
    return <AuthenticatedOutlet />

  return <Navigate to="/home" />
}
