import { UpdateUserBody } from '@/api'
import { ReactComponent as CameraCircle } from '@/assets/camera-circle.svg'
import { ReactComponent as CameraIcon } from '@/assets/camera.svg'
import { ReactComponent as DeleteIcon } from '@/assets/delete-profile.svg'
import { ReactComponent as GalleryIcon } from '@/assets/gallery.svg'
import { Avatar, Camera, Popover } from '@/components'
import { useAuthContext, useFormContext } from '@/providers'
import { useDeleteProfilePicture } from '@/services'
import { useItemModal, useModal } from '@/utils'
import { InputHTMLAttributes, useRef } from 'react'
import { Cropsicle } from './Cropsicle'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    takePhoto: t('mediaPage.uploadModal.camera'),
    selectProfilePhoto: t('profilePage.selectProfilePhoto'),
    deleteProfilePhoto: t('profilePage.deleteProfilePhoto'),
  }
}

const ProfileAvatarBase = ({ onClick }: { onClick?: () => void }) => {
  const { user } = useAuthContext()
  const { values } = useFormContext<UpdateUserBody>()

  return (
    <div className="relative" onClick={onClick}>
      <Avatar
        size={120}
        first_name={values.first_name as string}
        last_name={values.last_name as string}
        src={user.avatar}
      />
      <CameraCircle width={40} height={40} className="absolute -bottom-1 right-0" />
    </div>
  )
}

export const ProfileAvatar = () => {
  const { user } = useAuthContext()
  const cameraState = useModal(false)
  const imageState = useItemModal<File | Blob>()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const deleteAvatarMutation = useDeleteProfilePicture()

  const handleDelete = () => {
    deleteAvatarMutation.mutate({})
  }

  const handleGalleryClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = ''
      inputFileRef.current.click()
    }
  }

  const handleFileChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) => {
    const inputFile = e.target.files?.[0]
    if (inputFile) {
      imageState.openItemModal(inputFile)
    }
  }
  const messages = useMessages()

  return (
    <>
      <Popover
        actions={[
          {
            icon: CameraIcon,
            text: messages.takePhoto,
            onClick: cameraState.open,
          },
          {
            icon: GalleryIcon,
            text: messages.selectProfilePhoto,
            onClick: handleGalleryClick,
          },
          {
            icon: DeleteIcon,
            text: messages.deleteProfilePhoto,
            onClick: handleDelete,
            hide: !user.avatar,
          },
        ]}
      >
        <ProfileAvatarBase />
      </Popover>
      {cameraState.isOpen && (
        <Camera onCapture={imageState.openItemModal} onClose={cameraState.close} />
      )}
      {imageState.isOpen && (
        <Cropsicle image={imageState.item as File} onClose={imageState.closeItemModal} />
      )}
      <input hidden type="file" accept="image/*" ref={inputFileRef} onChange={handleFileChange} />
    </>
  )
}
