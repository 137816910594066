import { PageLoader } from '@/components'
import { AuthenticatedOutlet, useAuthContext } from '@/providers'
import { HomePageHeader } from './HomePageHeader'
import { HomePageNavBar } from './HomePageNavBar'

export const HomePage = () => {
  const { arePatientsLoading } = useAuthContext()

  if (arePatientsLoading) return <PageLoader />

  return (
    <div className="page bg-sec0">
      <HomePageHeader />
      <div className="fixed flex justify-center overflow-y-auto left-[108px] top-[60px] h-[calc(100%-60px)] w-[calc(100vw-108px)] sm:left-0 sm:w-screen sm:bottom-[92px] sm:h-[calc(100%-60px-92px)]">
        <AuthenticatedOutlet />
      </div>
      <HomePageNavBar />
    </div>
  )
}
