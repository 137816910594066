import { PaginatedQueryParams, PaginatedResponseType, makeQueryString } from '@/utils'
import { keys } from 'ramda'
import { api } from './api'

export type IcuDiaryItem = {
  id: string
  creation_time: string
  text: string
  image: string
}

export type IcuDiaryBody = {
  text: string
  image?: File
}

export const getIcuDiary = (patientId: string, queryParams: PaginatedQueryParams) =>
  api.get<PaginatedResponseType<IcuDiaryItem>>(
    `/contacts/icu_diary_items/${patientId}/${makeQueryString(queryParams)}`,
  )

export const addIcuDiary = (patientId: string, body: IcuDiaryBody) => {
  const formData = new FormData()
  keys(body).forEach((item) => {
    if (body[item]) {
      formData.append(item, body[item]!)
    }
  })

  return api.post(`/contacts/icu_diary_items/${patientId}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
