import { useAuthContext } from '@/providers'
import { useCreatePatientSurvey, useQueryPatientSurvey, useUpdatePatientSurvey } from '@/services'
import { useCallback, useMemo } from 'react'
import { Model } from 'survey-core'
import 'survey-core/defaultV2.min.css'
import { Survey } from 'survey-react-ui'
import surveyJsonData from './anamnese.json'

export const SurveyPage = () => {
  const { currentPatient } = useAuthContext()
  const surveyData = useQueryPatientSurvey(currentPatient?.id!)
  const createSurveyMutation = useCreatePatientSurvey(currentPatient?.id!)
  const addSurveyMutation = useUpdatePatientSurvey(currentPatient?.id!, surveyData.data?.id!)

  const saveResults = useCallback(
    (sender: Model) => {
      if (surveyData.data?.id) {
        addSurveyMutation.mutate({ step: survey.currentPageNo, data: sender.data })
      } else {
        createSurveyMutation.mutate({ step: survey.currentPageNo, data: sender.data })
      }
    },
    [surveyData.data?.id],
  )

  const survey = useMemo(() => {
    const memSurvey = new Model(surveyJsonData)
    memSurvey.sendResultOnPageNext = true
    memSurvey.onPartialSend.add(saveResults)
    if (surveyData.data?.data) {
      memSurvey.data = surveyData.data.data
      if (typeof surveyData.data?.step === 'number') {
        memSurvey.currentPageNo = surveyData.data?.step + 1
      }
    }
    return memSurvey
  }, [saveResults])

  return (
    <div
      className="w-[100%] flex items-center justify-center pb-[88px] md:p-0 sm:items-end survey"
      style={{}}
    >
      <Survey model={survey} />
    </div>
  )
}
