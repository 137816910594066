import { RegisterBody } from '@/api'
import { OTPInput, OTPInputRef } from '@/components'
import { useFormContext } from '@/providers'
import { useResendRegisterOTP } from '@/services'
import { useCountDown } from '@/utils'
import { pick } from 'ramda'
import { ForwardedRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('registerPage.inviteOtpStep.title'),
    hint: t('registerPage.inviteOtpStep.hint'),
    noCodeReceived: t('registerPage.inviteOtpStep.noCodeReceived'),
    resendCode: t('registerPage.inviteOtpStep.resendCode'),
    resendCountDown: (seconds: number) =>
      t('registerPage.inviteOtpStep.resendCountDown', { seconds }),
  }
}

export const InviteOTPStep = forwardRef((_, ref: ForwardedRef<OTPInputRef>) => {
  const messages = useMessages()
  const { values } = useFormContext<RegisterBody>()
  const resendOTPMutation = useResendRegisterOTP()
  const countDown = useCountDown({ duration: 60, initialStart: true })

  const handleResendOTP = () => {
    if (!countDown.playing) {
      resendOTPMutation.mutate(pick(['email', 'first_name', 'last_name', 'phone'], values), {
        onSuccess: countDown.start,
      })
    }
  }

  return (
    <div className="flex flex-col gap-4 items-center">
      <div className="text-b100 font-bold text-center">{messages.title}</div>
      <OTPInput ref={ref} />
      <div className="text-b90 text-center">{messages.noCodeReceived}</div>
      <div className="text-b90 text-center">{messages.hint}</div>
      <div className="flex flex-col items-center">
        <button
          type="button"
          className={`underline ${
            countDown.playing
              ? 'text-b50 cursor-not-allowed !bg-[transparent]'
              : 'text-primary cursor-pointer'
          }`}
          onClick={handleResendOTP}
        >
          {messages.resendCode}
        </button>
        {countDown.playing && <strong>{messages.resendCountDown(countDown.value)}</strong>}
      </div>
    </div>
  )
})
