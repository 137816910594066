import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { config } from '@/config'
import { paths } from '@/routes/paths'
import { useNavigateToPreviousLocation } from '@/utils'
import { Trans, useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('dataPrivacyPage.title'),
    subtitle: t('dataPrivacyPage.subtitle'),
    text1: t('dataPrivacyPage.text1'),
    privacyPolicyText: t('dataPrivacyPage.privacyPolicyText'),
    imprintTitle: t('dataPrivacyPage.imprintTitle'),
    imprintSubtitle: t('dataPrivacyPage.imprintSubtitle'),
    imprintUrlText: t('dataPrivacyPage.imprintUrlText'),
  }
}

export const ImprintAndDataSecurityPage = () => {
  const navigate = useNavigateToPreviousLocation()

  const handleBack = () => {
    navigate(`/${paths.home}`)
  }
  const messages = useMessages()

  return (
    <div className="page flex justify-center pt-[60px] bg-sec0">
      <div className="subpage-header">
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={handleBack}
        />
        <div className="text-textM">{messages.title}</div>
        <div />
      </div>
      <div className="max-w-[488px] flex flex-col items-center">
        <div className="flex flex-col gap-[10px] p-4 w-full">
          <div className="text-h3">{messages.subtitle}</div>
          <div className="text-b50">
            {messages.text1}
            <br />
            <Trans
              i18nKey={'dataPrivacyPage.text2'}
              values={{ mail: config.supportEmail }}
              components={[
                <a
                  className="font-bold underline"
                  href={config.faqsUrl}
                  target="_blank"
                  rel="noreferrer"
                />,
                <a className="font-bold underline" href={`mailto:${config.supportEmail}`} />,
              ]}
            />
          </div>
          <a
            className="font-bold underline"
            href={config.privacyPolicyUrl}
            target="_blank"
            rel="noreferrer"
          >
            {messages.privacyPolicyText}
          </a>
        </div>
        <div className="flex flex-col gap-[10px] p-4 w-full">
          <div className="text-h3">{messages.imprintTitle}</div>
          <div className="text-h4">{messages.imprintSubtitle}</div>
          <div className="text-b90">
            <Trans i18nKey={'dataPrivacyPage.imprintText1'} />
            <Trans i18nKey={'dataPrivacyPage.imprintText2'} />
            <Trans i18nKey={'dataPrivacyPage.imprintText3'} />
            <Trans i18nKey={'dataPrivacyPage.imprintText4'} />
            <Trans i18nKey={'dataPrivacyPage.imprintText5'} />
            <Trans i18nKey={'dataPrivacyPage.imprintText6'} />
            <br />
            <br />
            <a
              className="font-bold underline"
              href={config.imprintUrl}
              target="_blank"
              rel="noreferrer"
            >
              {messages.imprintUrlText}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
