import { SurveyAnswers, SurveyGroupTarget, SurveyType, getSurvey, submitSurveyAnswers } from '@/api'
import { ReactQueryOptions, reactQueryKeys, useInvalidateOnSuccessMutation } from '@/utils'
import { useQuery } from 'react-query'

export const useSurvey = (
  targetGroup: SurveyGroupTarget = 'relative',
  options: ReactQueryOptions<SurveyType, ['appSurvey', string]> = {},
) => useQuery([reactQueryKeys.appSurvey, targetGroup], () => getSurvey(targetGroup), options)

export const useSubmitSurveyAnswers = (
  surveyId: string,
  targetGroup: SurveyGroupTarget = 'relative',
) =>
  useInvalidateOnSuccessMutation(['appSurvey', targetGroup], (body: SurveyAnswers) =>
    submitSurveyAnswers(surveyId, body, targetGroup),
  )
