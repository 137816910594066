import { resources } from '@/@types/resources'
import { PaginatedQueryParams, PaginatedResponseType, makeQueryString } from '@/utils'
import { api } from './api'
import { AssetType } from './media'

export type ChatType = {
  id: string
  chat_communication_method_for_relatives:
    | null
    | 'FREE_TEXT_AND_TEXT_ELEMENTS'
    | 'ONLY_TEXT_ELEMENTS'
}

export type RelativeSendBy = {
  id: number
  full_name: string
  avatar: string | null
}

export type ChatMessageType = {
  id?: string
  likes: number
  liked: boolean
  text?: string
  send_at?: string
  media?: {
    id: string
    link: string
    type: AssetType
    sound_wave?: string
  }
  send_by: number | RelativeSendBy
  nurse_avatar: string | null
  for_super_relative_only?: boolean
  is_notification?: boolean
  medical_document: string
  needs_to_be_translated: boolean
  original_language: keyof typeof resources['DE']['translation']['languages'] | null
  is_icu_diary_message: boolean
}

export type TextElementType = {
  id: string
  name: string
} & (
  | {
      text: string[]
      options?: never
    }
  | {
      options: {
        id: number
        text: string[]
        name: string
      }[]
      text?: never
    }
)

export type PinnedMessage = Record<
  'station_message' | 'welcome_message',
  { text: string; time: string }
>

export const getChat = (patientId: string) =>
  api.get<ChatType>(`/patient_chat/patient_chats/${patientId}/get_by_patient_id/`)

export const getChatMessages = (chatId: string, queryParams: PaginatedQueryParams) =>
  api.get<PaginatedResponseType<ChatMessageType>>(
    `/patient_chat/chat_messages/${chatId}/${makeQueryString(queryParams)}`,
  )

export const getPinnedMessages = (patientId: string) =>
  api.get<PinnedMessage>(`/patient_chat/patient_chats/${patientId}/pinned_messages/`)

export const updateLikeMessage = (id: ChatMessageType['id'], like: boolean) =>
  api.patch(`/patient_chat/chat_messages/${id}/${like ? 'like_message' : 'unlike_message'}/`)

export const sendMessage = (patientId: number, text: string) =>
  api.post(`/reactions/text-messages/post-text-message/${patientId}/`, { text })

export const getTextElements = (patientId: number) =>
  api.get<TextElementType[]>(`/reactions/text-messages/relatives_text_messages/${patientId}/`)

export type TranslateBody = {
  messages: string[]
}

export const translateMessages = (patientId: string, body: TranslateBody) =>
  api.post<TranslateBody, string[]>(
    `/patient_chat/patient_chats/${patientId}/translate_messages/`,
    body,
  )
