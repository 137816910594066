import { ReactComponent as FacebookLogo } from '@/assets/facebook.svg'
import { ReactComponent as FullLogo } from '@/assets/full-logo.svg'
import { ReactComponent as InstagramLogo } from '@/assets/instagram.svg'
import { ReactComponent as LinkedInLogo } from '@/assets/linkedIn.svg'
import { config } from '@/config'
import { LanguageSelectProps } from '@/routes'
import { FunctionComponent, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { PrimaryButton } from '../'
import { Paper } from '../Paper'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    imprint: t('authPage.footer.imprint'),
    privacyPolicy: t('authPage.footer.privacyPolicy'),
  }
}

export const AuthLayout = ({
  children,
  className,
  paperClassName,
}: PropsWithChildren<{ className?: string; paperClassName?: string }>) => {
  const messages = useMessages()

  return (
    <div
      className={`page md:z-[50] z-[100] flex items-center justify-center pb-[88px] md:p-0 ${className}`}
      style={{
        backgroundImage: 'url(/assets/auth-background.png)',
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top',
        backgroundColor: '#eaeeef',
      }}
    >
      <Paper className={`p-6 gap-6 !rounded-[32px] bg-sec0 ${paperClassName}`}>{children}</Paper>
      <div className="md:hidden fixed h-[88px] bottom-0 left-0 right-0 bg-primary flex items-center justify-between text-b0 child:text-b0 text-textXS px-[80px]">
        <div className="flex gap-4 items-center">
          <a href={config.igUrl} target="_blank" rel="noreferrer">
            <InstagramLogo width={24} height={24} className="shrink-0" />
          </a>
          <a href={config.linkedInUrl} target="_blank" rel="noreferrer">
            <LinkedInLogo width={24} height={24} className="shrink-0" />
          </a>
          <a href={config.fbUrl} target="_blank" rel="noreferrer">
            <FacebookLogo width={24} height={24} className="shrink-0" />
          </a>
        </div>
        IntensivKontakt® 2024
        <a href={`mailto:${config.contactEmail}`}>{config.contactEmail}</a>
        <a href={`tel:${config.contactPhone}`}>{config.contactPhone}</a>
        <a href={config.imprintUrl} target="_blank" rel="noreferrer">
          {messages.imprint}
        </a>
        <a href={config.privacyPolicyUrl} target="_blank" rel="noreferrer">
          {messages.privacyPolicy}
        </a>
      </div>
    </div>
  )
}

type AuthTroubleshootLayoutProps = {
  title: string
  buttonText: string
  linkBottomText?: string
  linkBottomRedirectPath?: string
} & (
  | {
      redirectPath: string
      onClick?: never
    }
  | {
      redirectPath?: never
      onClick: () => void
    }
)

export const AuthTroubleshootLayout = ({
  title,
  buttonText,
  redirectPath,
  children,
  onClick,
  linkBottomText,
  linkBottomRedirectPath,
}: PropsWithChildren<AuthTroubleshootLayoutProps>) => {
  const navigate = useNavigate()
  const { LanguageSelect } = useOutletContext() as {
    LanguageSelect: FunctionComponent<LanguageSelectProps>
  }

  return (
    <AuthLayout paperClassName="max-w-[854px] mx-4 items-center gap-[42px]">
      <FullLogo width={100} height={51} className="shrink-0" />
      <div className="text-h2reg text-center text-b90">{title}</div>
      <div className="text-left text-b90">{children}</div>
      <PrimaryButton
        block
        className="max-w-[325px]"
        onClick={() => (redirectPath ? navigate(redirectPath) : onClick)}
      >
        {buttonText}
      </PrimaryButton>
      {linkBottomText && linkBottomRedirectPath ? (
        <div
          className="underline cursor-pointer text-primary"
          onClick={() => navigate(linkBottomRedirectPath)}
        >
          {linkBottomText}
        </div>
      ) : (
        ''
      )}
      <div className="md:hidden">
        <LanguageSelect reversed />
      </div>
    </AuthLayout>
  )
}
