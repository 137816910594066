export const config = {
  contactEmail: 'info@intensivkontakt.de',
  supportEmail: 'support@intensivkontakt.de',
  contactPhone: '+49 40 524796034',
  igUrl: 'https://www.instagram.com/intensivkontakt/',
  linkedInUrl: 'https://www.linkedin.com/company/intensivkontakt/',
  fbUrl: 'https://www.facebook.com/people/IntensivKontakt/100092273649855/',
  imprintUrl: 'https://www.intensivkontakt.de/impressum',
  privacyPolicyUrl: 'https://www.intensivkontakt.de/datenschutzerklaerung',
  termsUrl:
    'https://uploads-ssl.webflow.com/63c54f2e0d5e9296700a8ed5/645b787032f73d36cf4097f4_Nutzungsbedingung_IntensivKontakt_web.pdf',
  faqsUrl: 'https://www.intensivkontakt.de/support',
}
