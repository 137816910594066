/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react'
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from 'axios'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { assoc, curry, mergeLeft, pick, pipe, prop } from 'ramda'
import { log } from './log'

class ApiRequestError extends Error {
  constructor(baseUrl: string) {
    super(`Request to ${baseUrl} returned error`)
    this.name = ApiRequestError.name
  }
}

const assocHeaders = curry(
  (additionalHeaders: () => RawAxiosRequestHeaders, config: InternalAxiosRequestConfig) => {
    const headers = mergeLeft<AxiosRequestHeaders, RawAxiosRequestHeaders>(
      config.headers,
      additionalHeaders(),
    ) as AxiosRequestHeaders

    return assoc<AxiosRequestHeaders, 'headers', InternalAxiosRequestConfig>(
      'headers',
      headers,
      config,
    )
  },
)

interface CustomAxiosInstance extends AxiosInstance {
  get<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<T>
}

export const createMultiTenantApi = (
  baseURL: string,
  getHeaders?: () => RawAxiosRequestHeaders,
) => {
  /**
   * @param {import('axios').AxiosRequestConfig} config
   */
  const debugInterceptor = (config: AxiosRequestConfig) => (data: any) => {
    log(config.method?.toUpperCase(), config.baseURL || '' + config.url, config.data || data)

    return data
  }

  /**
   * @param {import('axios').AxiosResponse<any>} response
   */
  const responseInterceptor = (response: AxiosResponse) =>
    pipe(prop('data'), debugInterceptor(response.config))(response)

  /**
   * @param {import('axios').InternalAxiosRequestConfig} config
   */
  const contentInterceptor = assocHeaders(() => ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }))

  const authInterceptor = assocHeaders(getHeaders)

  /**
   * @param {import('axios').AxiosError} config
   */
  const responseErrorInterceptor = (error: AxiosError) => {
    if (error.response) {
      const captureContext = {
        extra: {
          response: {
            config: pick(['baseURL', 'url', 'data', 'method'], error.response.config),
            ...pick(['status', 'statusText', 'data'], error.response),
          },
        },
      }

      Sentry.captureException(new ApiRequestError(baseURL), captureContext)
    }
    return Promise.reject(error)
  }

  log('Api baseUrl:', baseURL)

  const multiTenantApi = axios.create({
    baseURL,
  })

  multiTenantApi.interceptors.request.use(contentInterceptor)
  multiTenantApi.interceptors.request.use(authInterceptor)
  multiTenantApi.interceptors.response.use(responseInterceptor, responseErrorInterceptor)

  return multiTenantApi as CustomAxiosInstance
}

export const isTokenValid = (token: string | null) => {
  if (!token) return false

  const decodedToken = jwt_decode<JwtPayload>(token)
  if (!decodedToken) return false

  return (decodedToken.exp || 0) * 1000 > new Date().getTime()
}

export const mockApiResponse = <T>(data: T, delay = 10) =>
  new Promise<T>((resolve) => {
    setTimeout(() => {
      resolve(data)
    }, delay)
  })
