import { RegisterBody } from '@/api'
import { StepperFormProps } from '@/components'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { object, string } from 'yup'
import { useMessages } from './Page'

export type stepKeys = 'profile' | 'register' | 'invite_otp'

export const validationSchema: (
  messages: ReturnType<typeof useMessages>['validations'],
) => StepperFormProps<RegisterBody, stepKeys>['validationSchema'] = (messages) => ({
  profile: object({
    first_name: string().required(messages.firstName).min(2, messages.firstNameInvalid),
    last_name: string().required(messages.lastName).min(2, messages.lastNameInvalid),
    phone: string().test('phone-check', messages.inputPhoneNo, (value) => {
      return Boolean(value && isValidPhoneNumber(value))
    }),
  }),
  register: object({
    email: string().required(messages.inputEmail).email(messages.emailInvalid),
    password: string()
      .required(messages.password)
      .matches(/^.*(?=.{8,})((?=.*\d)+)((?=.*[A-z]){1}).*$/, messages.passwordInvalid),
  }),
})
