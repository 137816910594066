import loader from '@/assets/spinner.svg'
import { DetailedHTMLProps, ImgHTMLAttributes } from 'react'

type LoaderProps = Omit<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  'width' | 'height' | 'src' | 'alt'
> & {
  size?: number
}

export const Loader = ({ size = 30, ...props }: LoaderProps) => (
  <div className="m-auto">
    <img width={size} height={size} src={loader} alt="loading..." {...props} />
  </div>
)

export const PageLoader = () => (
  <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
    <img src={loader} alt="loading..." />
  </div>
)
