import { RelativeListItem } from '@/api'
import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { ReactComponent as InfoIcon } from '@/assets/info.svg'
import { ReactComponent as PendingIcon } from '@/assets/pending.svg'
import { ReactComponent as PlusIcon } from '@/assets/plus.svg'
import { ReactComponent as StarIcon } from '@/assets/star.svg'
import { ReactComponent as TickIcon } from '@/assets/tick.svg'
import { Banner } from '@/components'
import { useAuthContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useCheckCurrentSuperRelative, useRelativesList } from '@/services'
import { useNavigateToPreviousLocation, useNavigateWithFromState } from '@/utils'
import { filter, groupBy, keys, propEq } from 'ramda'
import { FunctionComponent, SVGProps, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

type RelativeGroupProps = {
  title: string
  noDataText?: string
  description?: string
  relatives: RelativeListItem[]
  patientAccount?: RelativeListItem
}

type RelativeRowProps = RelativeListItem & {
  me: boolean
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('relativePage.title'),
    text: t('relativePage.inviteText'),
    button: t('relativePage.inviteButton'),
    waitingTitle: t('relativePage.waitingTitle'),
    waitingText: t('relativePage.waitingText'),
    waitingNoData: t('relativePage.waitingNoData'),
    assignedTitle: t('relativePage.assignedTitle'),
    inProgressTitle: t('relativePage.inProgressTitle'),
    inProgressText: t('relativePage.inProgressText'),
    me: t('relativePage.me'),
    patient: t('global.patient'),
    relatives: t('global.relatives'),
  }
}

const relativeStatusToIcon: Record<
  RelativeListItem['status'],
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  assigned: TickIcon,
  in_progress: PendingIcon,
  need_to_accept: InfoIcon,
}

const relativesStatusToColor: Record<RelativeListItem['status'], string> = {
  assigned: 'var(--primary)',
  need_to_accept: 'var(--danger)',
  in_progress: 'var(--warning)',
}

const RelativeRow = ({
  me,
  status,
  first_name,
  last_name,
  email,
  phone,
  id,
  request_id,
  is_patient_user,
  is_super_relative,
}: RelativeRowProps) => {
  const { currentPatient } = useAuthContext()
  const { data: isSuperRelative } = useCheckCurrentSuperRelative()
  const navigate = useNavigateWithFromState()

  const Icon = useMemo(() => relativeStatusToIcon[status], [status])

  const handleClick = () => {
    if (me) {
      navigate(`/${paths.profile}`)
    } else if (isSuperRelative) {
      if (is_patient_user) {
        navigate(`${window.location.pathname}/${id || 'patient-invite'}`, { is_patient_user })
      } else {
        navigate(`${window.location.pathname}/${request_id || id}`)
      }
    }
  }
  const messages = useMessages()

  return (
    <div
      className={`px-4 h-[48px] flex items-center gap-2 justify-between border-t-[1px] border-t-sec10 first:border-0 
      ${isSuperRelative || me ? 'cursor-pointer' : ''}`}
      onClick={handleClick}
    >
      <span className="ellipsis">
        {me && `(${messages.me}) `}
        {first_name || last_name
          ? `${first_name || ''}${first_name && last_name && ' '}${last_name || ''}`
          : email || phone}
      </span>
      <div className="flex items-center gap-2 shrink-0">
        {currentPatient?.is_patient_user && is_super_relative && (
          <StarIcon width={24} height={24} className="shrink-0" />
        )}
        {isSuperRelative && status === 'need_to_accept' ? (
          <div className="badge-danger" />
        ) : (
          <Icon width={24} height={24} className="shrink-0" fill={relativesStatusToColor[status]} />
        )}
        {(isSuperRelative || me) && (
          <ArrowLeft width={24} height={24} className="shrink-0 rotate-180" fill="var(--b50)" />
        )}
      </div>
    </div>
  )
}

const RelativeGroup = ({
  title,
  noDataText,
  description,
  relatives,
  patientAccount,
}: RelativeGroupProps) => {
  const messages = useMessages()
  const { user } = useAuthContext()

  if (!noDataText && !relatives.length && !patientAccount) return null

  return (
    <div className="flex flex-col gap-2">
      <div className="text-h4">{title}</div>
      <span className="text-textS text-b50">
        {relatives.length || !!patientAccount ? description : noDataText}
      </span>
      {!!patientAccount && (
        <>
          <span className="text-button text-b50">{messages.patient}:</span>
          <div className="flex flex-col w-full rounded-[10px] border-[0.5px] border-sec10 bg-b0 shadow-[0_0.5px_0_0_rgba(16,34,40,0.02)]">
            <RelativeRow me={patientAccount.id === user.id} {...patientAccount} />
          </div>
        </>
      )}
      {Boolean(relatives.length) && (
        <>
          <span className="text-button text-b50">{messages.relatives}:</span>
          <div className="flex flex-col w-full rounded-[10px] border-[0.5px] border-sec10 bg-b0 shadow-[0_0.5px_0_0_rgba(16,34,40,0.02)]">
            {relatives.map((item) => (
              <RelativeRow key={item.id} me={item.id === user.id} {...item} />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export const RelativesPage = () => {
  const { currentPatient, relatives: allRelatives } = useAuthContext()
  useRelativesList(currentPatient)
  const messages = useMessages()
  const navigate = useNavigateWithFromState()
  const navigateBack = useNavigateToPreviousLocation()

  const handleBack = () => {
    navigateBack(`/${paths.home}/${currentPatient?.id}`)
  }

  const handleInvite = () => {
    navigate(`${window.location.pathname}/${paths.invite}`)
  }

  const { relatives = [], patients = [] } = useMemo(
    () =>
      groupBy(({ is_patient_user }) => (is_patient_user ? 'patients' : 'relatives'), allRelatives),
    [allRelatives],
  )

  const statuses: Record<RelativeListItem['status'], Omit<RelativeGroupProps, 'relatives'>> = {
    need_to_accept: {
      title: messages.waitingTitle,
      description: messages.waitingText,
      noDataText: messages.waitingNoData,
    },
    in_progress: {
      title: messages.inProgressTitle,
      description: messages.inProgressText,
    },
    assigned: {
      title: messages.assignedTitle,
    },
  }

  return (
    <div className="page flex justify-center pt-[60px] bg-sec0">
      <div className="subpage-header">
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={handleBack}
        />
        {messages.title}
        <div />
      </div>
      <div className="max-w-[488px] flex flex-col gap-8 p-4 w-full">
        <Banner
          actions={[
            {
              variant: 'contained',
              text: (
                <>
                  <PlusIcon width={16} height={16} className="shrink-0" />
                  {messages.button}
                </>
              ),
              onClick: handleInvite,
            },
          ]}
        >
          {messages.text}
        </Banner>
        {keys(statuses).map((item) => (
          <RelativeGroup
            key={item}
            {...statuses[item]}
            relatives={filter(propEq(item, 'status'), relatives)}
            patientAccount={patients.find(propEq(item, 'status'))}
          />
        ))}
      </div>
    </div>
  )
}
