/* eslint-disable @typescript-eslint/no-explicit-any */
import { AssetType, Media, deleteMedia, listMedia, uploadMedia } from '@/api'
import {
  PaginatedResponseType,
  ReactQueryOptions,
  reactQueryKeys,
  useInvalidateOnSuccessMutation,
} from '@/utils'
import { AxiosError } from 'axios'
import { pipe, prop } from 'ramda'
import { useQuery } from 'react-query'

type listMediaResponseType = ReactQueryOptions<
  PaginatedResponseType<Media>,
  ['media', string],
  Media[]
>

export const useMediaList = <T extends any[] = Media[]>(
  patientId?: number,
  {
    select = (a) => a as T,
    ...options
  }: Omit<listMediaResponseType, 'select'> & {
    select?: (hospitals: Media[]) => T
  } = {},
) =>
  useQuery<PaginatedResponseType<Media>, AxiosError, T, ['media', string]>(
    [reactQueryKeys.media, String(patientId)],
    () => listMedia(patientId as number),
    {
      ...options,
      enabled: Boolean(patientId),
      select: pipe<PaginatedResponseType<Media>[], Media[], T>(prop('results'), select),
    },
  )

export const useDeleteMedia = (patientId: number, id: Media['id']) =>
  useInvalidateOnSuccessMutation(['media', String(patientId)], () => deleteMedia(id))

export const useUploadMedia = (patientId: number) =>
  useInvalidateOnSuccessMutation(
    ['media', String(patientId)],
    ({ file, asset_type }: { file: File; asset_type: AssetType }) =>
      uploadMedia(patientId, file, asset_type),
  )
