import { NotificationConfigType, Profile } from '@/api'
import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { ReactComponent as InfoIcon } from '@/assets/info.svg'
import {
  Alert,
  Banner,
  BannerButton,
  ButtonBase,
  Divider,
  Paper,
  SecondaryButton,
  Select,
  Switch,
} from '@/components'
import { useAuthContext, useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import {
  useDeleteAccount,
  useNotificationConfig,
  useResendVerificationEmail,
  useUpdateNotificationConfig,
  useUpdateProfile,
} from '@/services'
import {
  reactQueryKeys,
  useModal,
  useNavigateToPreviousLocation,
  useNavigateWithFromState,
} from '@/utils'
import i18next from 'i18next'
import { is, mergeRight, propSatisfies, when } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

type SettingsRowType = {
  label: string
  configKey: keyof NotificationConfigType
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('settings.title'),
    language: t('global.language'),
    chooseLanguageText: t('settings.chooseLanguageText'),
    notifications: t('settings.notifications'),
    notificationsText: t('settings.notificationsText'),
    notificationsPush: t('settings.notificationsPush'),
    notificationsSMS: t('settings.notificationsSMS'),
    notificationsMail: t('settings.notificationsMail'),
    notificationsDiary: t('settings.notificationsDiary'),
    verificationMailText: t('settings.verificationMailText'),
    verificationMailDays: (days: number) => t('settings.verificationMailDays', { days: days }),
    verificationMailCta: t('settings.verificationMailCta'),
    chatTranslationTitle: t('settings.chatTranslationTitle'),
    chatTranslationText: t('settings.chatTranslationText'),
    chatTranslationSwitch: t('settings.chatTranslationSwitch'),
    changePassword: t('settings.changePassword'),
    deleteAccountButton: t('settings.deleteAccountModal.button'),
    deleteAccountTitle: t('settings.deleteAccountModal.title'),
    deleteAccountText1: t('settings.deleteAccountModal.text1'),
    deleteAccountText2: t('settings.deleteAccountModal.text2'),
    deleteAccountConfirm: t('settings.deleteAccountModal.confirmCta'),
    deleteAccountDecline: t('settings.deleteAccountModal.declineCta'),
    deleteSuccess: t('settings.deleteAccountModal.deleteSuccess'),
  }
}

const SettingsRow = ({ label, configKey }: SettingsRowType) => {
  const { data } = useNotificationConfig()
  const updateNotificationConfigMutation = useUpdateNotificationConfig()
  const handleUpdateNotificationConfig = (checked: boolean) => {
    updateNotificationConfigMutation.mutate(
      when(propSatisfies(is(Boolean), 'icu_diary_notifications'), mergeRight(data!), {
        [configKey]: checked,
      }),
    )
  }

  return (
    <>
      <Divider />
      <div className="text-b90 flex items-center justify-between">
        {label}
        <Switch
          checked={data?.[configKey]!}
          onChange={handleUpdateNotificationConfig}
          disabled={configKey === 'icu_diary_notifications' && !data?.push}
        />
      </div>
    </>
  )
}

export const SettingsPage = () => {
  const snackbar = useSnackbar()
  const alertState = useModal(false)
  const { user, settings, patients } = useAuthContext()
  const navigate = useNavigateToPreviousLocation()
  const navigateFrom = useNavigateWithFromState()
  const queryCache = useQueryClient()
  const deleteAccountMutation = useDeleteAccount()
  const updateProfileMutation = useUpdateProfile()
  const resendVerificationEmailMutation = useResendVerificationEmail()
  const messages = useMessages()

  const handleResetEmail = () => {
    resendVerificationEmailMutation.mutate({})
  }
  const handleBack = () => {
    navigate(`/${paths.home}`)
  }

  const navigateToPasswordChangePage = () => {
    navigateFrom(`/${paths.passwordChange}/`)
  }

  const handleDeleteAccount = () => {
    deleteAccountMutation.mutate(
      {},
      {
        onSuccess: () => {
          snackbar.success(`${messages.deleteSuccess}`)
          alertState.close()
        },
      },
    )
  }

  const handleUpdateLanguage = (language: Profile['language']) => {
    updateProfileMutation.mutate(
      { language },
      {
        onSuccess: () => {
          patients.forEach(({ id }) =>
            queryCache.setQueryData([reactQueryKeys.textElements, String(id)], undefined),
          )
        },
      },
    )
    i18next.changeLanguage(language)
  }

  const handleUpdateSettings = (user_settings: Profile['user_settings']) => {
    updateProfileMutation.mutate({ user_settings })
  }

  return (
    <div className="page flex justify-center pt-[60px] bg-sec0">
      <div className="subpage-header">
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={handleBack}
        />
        {messages.title}
        <div />
      </div>
      <div className="max-w-[488px] flex flex-col gap-6 p-4 w-full">
        {!user.is_active && (
          <Banner
            icon={<InfoIcon fill="var(--danger)" className="shrink-0" width={26} height={26} />}
            actions={[
              {
                variant: 'outlined',
                text: messages.verificationMailCta,
                onClick: handleResetEmail,
              },
            ]}
          >
            {messages.verificationMailText}
            <strong>
              {messages.verificationMailDays(user.trial_days !== null ? 14 - user.trial_days : 0)}
            </strong>
          </Banner>
        )}
        {Boolean(settings.languages.length) && (
          <Paper>
            <div className="text-h4">{messages.language}</div>
            <div className="text-b90 text-textS">{messages.chooseLanguageText}:</div>
            <Select<Profile['language']>
              readOnly
              value={user.language}
              onChange={(language) => handleUpdateLanguage(language!)}
              options={settings.languages}
            />
          </Paper>
        )}
        {user.language !== 'DE' && (
          <Paper>
            <div className="text-h4">{messages.chatTranslationTitle}</div>
            <div className="text-b90 text-textS">{messages.chatTranslationText}:</div>
            <Divider />
            <div className="text-b90 flex items-center justify-between">
              {messages.chatTranslationSwitch}
              <Switch
                checked={user.user_settings?.auto_translation_enabled!}
                onChange={(auto_translation_enabled) =>
                  handleUpdateSettings({ auto_translation_enabled })
                }
              />
            </div>
          </Paper>
        )}
        <Paper>
          <div className="text-h4">{messages.notifications}</div>
          <div className="text-b90 text-textS">{messages.chooseLanguageText}:</div>
          <SettingsRow label={messages.notificationsPush} configKey="push" />
          <SettingsRow label={messages.notificationsSMS} configKey="sms" />
          <SettingsRow label={messages.notificationsMail} configKey="email" />
          <SettingsRow label={messages.notificationsDiary} configKey="icu_diary_notifications" />
        </Paper>
        <ButtonBase variant="outlined" onClick={navigateToPasswordChangePage}>
          <BannerButton text={messages.changePassword} />
        </ButtonBase>
        <SecondaryButton
          block
          danger
          className="mt-auto"
          disabled={deleteAccountMutation.isLoading}
          onClick={alertState.open}
        >
          {messages.deleteAccountButton}
        </SecondaryButton>
        {alertState.isOpen && (
          <Alert
            title="Account löschen?"
            contents={[messages.deleteAccountText1, messages.deleteAccountText2]}
            actions={[
              { text: messages.deleteAccountDecline, onClick: alertState.close },
              {
                text: messages.deleteAccountConfirm,
                variant: 'danger',
                onClick: handleDeleteAccount,
              },
            ]}
          />
        )}
      </div>
    </div>
  )
}
