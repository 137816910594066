import { InfiniteQueryData } from '@/utils'
import { FunctionComponent, UIEventHandler, useCallback } from 'react'
import { AnyObject } from 'yup'
import { Loader } from '../Loader'

type InfiniteScrollerProps<T> = {
  offset: number
  noData?: JSX.Element | null
  component: FunctionComponent<T>
  componentKey: keyof T
  queryState: InfiniteQueryData<T>
  maxHeight: string | number
  containerClassName?: string
}

export const InfiniteScroller = <T extends AnyObject = AnyObject>({
  queryState,
  offset,
  noData = null,
  component: Component,
  componentKey,
  maxHeight,
  containerClassName = '',
}: InfiniteScrollerProps<T>) => {
  const { data = [], isFetchingNextPage, hasNextPage, fetchNextPage, isFetched } = queryState

  const scrollHandler = useCallback<UIEventHandler<HTMLDivElement>>(
    (e) => {
      const possibleScroll = e.currentTarget.scrollHeight - e.currentTarget.offsetHeight

      const scrollBottom = possibleScroll - e.currentTarget.scrollTop

      if (scrollBottom < offset && hasNextPage && !isFetchingNextPage) {
        fetchNextPage()
      }
    },
    [hasNextPage, isFetchingNextPage, offset],
  )

  if (!isFetched) return <Loader />

  if (!data.length) return noData

  return (
    <div
      onScroll={scrollHandler}
      className={`flex flex-col gap-4 w-full h-full overflow-y-auto ${containerClassName}`}
      style={{ maxHeight }}
    >
      {data.map((item) => (
        <Component key={item[componentKey]} {...item} />
      ))}
      {hasNextPage && (
        <div className="h-[100px] shrink-0 flex items-center justify-center child:scale-150">
          <Loader />
        </div>
      )}
    </div>
  )
}
