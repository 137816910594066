import { ReactComponent as BrokenLogo } from '@/assets/broken-logo.svg'
import { PrimaryButton, SecondaryButton } from '@/components'
import { config } from '@/config'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('global.ups'),
    subtitle: t('global.snackError'),
    reload: t('global.reload'),
    back: t('global.back'),
    contactSupport: t('global.contactSupport'),
  }
}

export const ErrorBoundary = () => {
  const messages = useMessages()

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag('url', window.location.href)
      }}
      fallback={({ resetError }) => {
        const handleBack = () => {
          window.history.back()
          resetError()
        }

        const handleReload = () => {
          window.location.reload()
          resetError()
        }

        return (
          <div className="page flex items-end justify-center bg-sec0">
            <div className="max-w-[488px] flex flex-col gap-8 items-center p-6 w-full">
              <div className="text-h2 text-b90">{messages.title}</div>
              <div className="text-b90">{messages.subtitle}</div>
              <BrokenLogo className="shrink-0" height="auto" width={267} />
              <PrimaryButton onClick={handleReload} block>
                {messages.reload}
              </PrimaryButton>
              <SecondaryButton onClick={handleBack} block>
                {messages.back}
              </SecondaryButton>
              <a
                className="uppercase text-primary font-bold"
                href={`mailto:${config.supportEmail}`}
              >
                {messages.contactSupport}
              </a>
            </div>
          </div>
        )
      }}
    >
      <Outlet />
    </Sentry.ErrorBoundary>
  )
}
