/* eslint-disable @typescript-eslint/no-explicit-any */
import { assoc, filter, fromPairs, keys, map, pipe, reverse, toPairs } from 'ramda'

export const disunion = <T extends Record<string, any>>(
  obj1: T,
  obj2: Record<keyof T, any>,
): Partial<T> =>
  filter((prop: keyof T) => obj1[prop] !== obj2[prop], keys(obj1)).reduce(
    (acc, cur) => assoc(cur as string, obj1[cur], acc),
    {},
  )

export const flipObject = <T extends string | number, U extends string | number>(
  object: Record<T, U>,
) =>
  pipe<[Record<T, U>], [T, U][], [U, T][], Record<U, T>>(
    toPairs,
    // @ts-ignore
    map<[T, U], [U, T]>(reverse),
    fromPairs,
  )(object)
