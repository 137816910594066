import { ReactComponent as CloseIcon } from '@/assets/close.svg'
import { useOutsideClick } from '@/utils'
import { intersperse } from 'ramda'
import { useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

type AlertActionType = {
  text: string
  variant?: 'success' | 'danger' | 'primary' | 'secondary'
} & ({ onClick?: never; customAction: JSX.Element } | { onClick: () => void; customAction?: never })

export interface AlertProps {
  title: string
  contents: (JSX.Element | string)[]
  variant?: 'success' | 'danger' | 'default'
}

interface AlertWithActions extends AlertProps {
  actions: AlertActionType[]
  onClose?: never
  closeable?: never
}

interface AlertPropsBase extends AlertProps {
  onClose: () => void
  closeable?: boolean
  actions?: never
}

interface CloseableAlertProps extends AlertProps {
  closeable: true
  onClose: () => void
  actions?: AlertActionType[]
}

const getColor = (variant: AlertProps['variant'] | AlertActionType['variant']) => {
  switch (variant) {
    case 'default':
      return 'b100'
    case 'secondary':
      return 'b50'
    default:
      return variant
  }
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    ok: t('global.modalButtonOk'),
  }
}

export const Alert = ({
  title,
  variant = 'default',
  contents,
  onClose,
  actions,
  closeable,
}: AlertPropsBase | AlertWithActions | CloseableAlertProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const messages = useMessages()

  useOutsideClick(ref.current, closeable ? onClose : () => {})

  return createPortal(
    <div className="mask">
      <div
        className="flex flex-col bg-b0 rounded-[14px] border-[0.5px] border-sec30 backdrop-blur-[20px] shadow-[0_0.5px_0_0_#ECEFF0,0_6px_14px_0_rgba(0,0,0,0.05)] max-w-[440px] relative"
        ref={ref}
      >
        {closeable && (
          <CloseIcon
            width={24}
            height={24}
            fill="var(--b0)"
            onClick={onClose}
            className="absolute right-0 -top-2 -translate-y-full"
          />
        )}
        <div className="flex flex-col gap-6 items-center p-4">
          <div
            className="text-h3 text-center"
            style={{
              color: `var(--${getColor(variant)})`,
            }}
          >
            {title}
          </div>
          <div className="text-center w-full">
            {intersperse(
              <>
                <br />
                <br />
              </>,
              contents,
            )}
          </div>
        </div>
        {(
          actions || [
            { text: messages.ok, onClick: onClose, variant: 'primary', customAction: undefined },
          ]
        ).map((action) => (
          <div
            key={action.text}
            className={`flex flex-col items-center gap-4 text-center cursor-pointer 
            text-${getColor(action.variant || 'primary')}
            border-t-[0.5px] border-t-sec30 p-4`}
            onClick={action.onClick}
          >
            {action.text}
            {action.customAction}
          </div>
        ))}
      </div>
    </div>,
    document.body,
  )
}
