import { OnboardingNotification } from '@/api'
import { Alert, AlertProps } from '@/components'
import { config } from '@/config'
import { UnmatchedPage, UpcomingInvitePage, WaitingConfirmationPage } from '@/pages'
import { AuthenticatedOutlet, useAuthContext, useSurveyContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useReadNotifications } from '@/services'
import { find, flip, includes, last, pipe, pluck, propSatisfies } from 'ramda'
import { Trans, useTranslation } from 'react-i18next'
import { Navigate, useLocation, useParams } from 'react-router-dom'

type currentPagePathnames = Pick<typeof paths, 'chat' | 'dashboard' | 'mySpace' | 'icuDiary'>

const useMessages = () => {
  const { t } = useTranslation()

  return {
    confirmed: t('matchRequestModals.confirmed', { returnObjects: true }),
    declined: t('matchRequestModals.declined', { returnObjects: true }),
    removed: t('matchRequestModals.removed', { returnObjects: true }),
  }
}

const makeOnboardingAlertProps: (
  extra_data: OnboardingNotification['extra_data'],
  messages: ReturnType<typeof useMessages>,
) => Record<OnboardingNotification['action'], AlertProps> = (
  { patient_first_name, patient_last_name, hospital_name },
  messages,
) => {
  return {
    request_confirmed: {
      title: messages.confirmed.title,
      variant: 'success',
      contents: [
        <span>
          <Trans
            i18nKey={'matchRequestModals.confirmed.text1'}
            values={{
              patient_name: `${patient_first_name} ${patient_last_name}`,
              hospital_name: hospital_name,
            }}
          />
        </span>,
        <span>
          <Trans
            i18nKey={'matchRequestModals.confirmed.text2'}
            values={{
              patient_name: `${patient_first_name}`,
            }}
          />
        </span>,
        messages.confirmed.text3,
      ],
    },
    request_declined: {
      title: messages.declined.title,
      variant: 'danger',
      contents: [
        <span>
          <Trans
            i18nKey={'matchRequestModals.declined.text1'}
            values={{
              patient_name: `${patient_first_name} ${patient_last_name}`,
            }}
          />
        </span>,
        messages.declined.text2,
        <span>
          <Trans
            i18nKey={'matchRequestModals.declined.text3'}
            components={[<a href={`mailto:${config.supportEmail}`} />]}
          />
        </span>,
      ],
    },
    removed_from_relatives: {
      title: messages.removed.title,
      variant: 'danger',
      contents: [
        <span>
          <Trans
            i18nKey={'matchRequestModals.removed.text1'}
            values={{
              patient_name: `${patient_first_name} ${patient_last_name}`,
            }}
          />
        </span>,
        <span>
          <Trans
            i18nKey={'matchRequestModals.removed.text2'}
            components={[<a href={`mailto:${config.supportEmail}`} />]}
          />
        </span>,
      ],
    },
  }
}

export const MatchGuard = () => {
  const { patients, currentPatient, notifications } = useAuthContext()
  const surveyContext = useSurveyContext()
  const { patientId } = useParams<{ patientId: string }>()
  const { pathname } = useLocation()
  const readOnboardingNotificationMutation = useReadNotifications()
  const messages = useMessages()

  const unreadOnboardingNotification = find(
    pipe(
      propSatisfies(
        flip(includes)(['request_confirmed', 'request_declined', 'removed_from_relatives']),
        'action',
      ),
    ),
    notifications,
  ) as OnboardingNotification

  const currentPage = last(pathname.split('/')) as currentPagePathnames[keyof currentPagePathnames]

  const defaultPatientId = patients[0]?.id

  // set :patientId if missing and there is at least one patient
  if (!patientId && defaultPatientId)
    return <Navigate to={`/${paths.home}/${defaultPatientId}/${currentPage}`} />

  // replace or remove invalid :patientId
  if (patientId && !pluck('id', patients).includes(parseInt(patientId))) {
    if (defaultPatientId)
      return <Navigate to={`/${paths.home}/${defaultPatientId}/${currentPage}`} />

    return <Navigate to={`/${paths.home}/${currentPage}`} />
  }

  // show onboarding accepted or declined notification
  if (unreadOnboardingNotification) {
    const handleClick = () => {
      readOnboardingNotificationMutation.mutate(unreadOnboardingNotification.id)

      if (unreadOnboardingNotification.action === 'removed_from_relatives') {
        surveyContext.triggerSurvey()
      }
    }

    return (
      <Alert
        {...makeOnboardingAlertProps(unreadOnboardingNotification.extra_data, messages)[
          unreadOnboardingNotification.action
        ]}
        onClose={handleClick}
      />
    )
  }

  if (patientId)
    switch (currentPatient?.connection_status) {
      case 'need_to_accept':
        return <UpcomingInvitePage />
      case 'in_progress':
        return <WaitingConfirmationPage />
      case 'assigned':
        return <AuthenticatedOutlet />
    }

  return <UnmatchedPage />
}
