type RadioProps = {
  checked: boolean
  onChange: (checked: true) => void
}

export const Radio = ({ checked, onChange }: RadioProps) => {
  const handleClick = () => {
    if (!checked) onChange(true)
  }

  return (
    <div
      className="w-[20px] h-[20px] flex items-center justify-center shrink-0 rounded-[50%] bg-b0 border-[1px] border-sec30 cursor-pointer"
      onClick={handleClick}
    >
      {checked && <div className="w-[12px] h-[12px] shrink-0 rounded-[50%] bg-primary" />}
    </div>
  )
}
