import { __, both, complement, equals, ifElse, includes, prop, propSatisfies, reject } from 'ramda'
import { useCallback, useMemo } from 'react'
import { selectOptionType } from '../Select'

type cardSelectOption<Value> = Omit<selectOptionType<Value>, 'label'> & {
  label: string | JSX.Element
}

type CardSelectProps<Value> = {
  disabled?: boolean
  options: cardSelectOption<Value>[]
  optionClassName?: string
  layout?: 'vertical' | 'horizontal'
} & (
  | {
      multiple: true
      onChange: (value?: Value[]) => void
      value?: Value[]
    }
  | {
      multiple?: false
      onChange: (value?: Value) => void
      value?: Value
    }
)

export const CardSelect = <Value extends selectOptionType['value'] = selectOptionType['value']>({
  value,
  onChange,
  options,
  disabled,
  optionClassName,
  multiple,
  layout = 'vertical',
}: CardSelectProps<Value>) => {
  const defaultValue = useMemo(() => value, [])

  const isOptionSelected = useCallback<(option: cardSelectOption<Value>) => boolean>(
    (option) => {
      if (multiple && value?.length) {
        return value.includes(option.value)
      }

      return value === option.value
    },
    [value],
  )

  const isOptionDisabled = useCallback(
    both(
      prop('disabled') as (option: cardSelectOption<Value>) => boolean,
      complement(
        propSatisfies(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ifElse((_) => multiple!, includes(__, defaultValue as Value[]), equals(defaultValue)),
          'value',
        ),
      ),
    ),
    [],
  )

  const handleOptionClick = (option: cardSelectOption<Value>) => {
    if (!disabled && !isOptionDisabled(option)) {
      if (multiple) {
        const safeValue = value || []
        if (isOptionSelected(option)) {
          onChange(reject(equals(option.value), safeValue))
        } else {
          onChange([option.value, ...safeValue])
        }
      } else {
        onChange(option.value)
      }
    }
  }

  return (
    <div className={`w-full flex gap-2 ${layout === 'vertical' ? 'flex-col' : ''}`}>
      {options.map((option) => (
        <div
          key={JSON.stringify(option.value)}
          className={`h-[38px] px-4 flex items-center text-placeholder rounded-[10px] transition-all duration-[200ms] ${
            isOptionSelected(option) ? 'border-2 border-accent' : 'border-[0.5px] border-sec30'
          } ${
            isOptionDisabled(option)
              ? 'cursor-not-allowed text-b30'
              : 'cursor-pointer text-b100 hover:bg-sec0'
          } ${layout === 'horizontal' ? `flex-1 shrink-0` : ''} ${optionClassName}`}
          onClick={() => handleOptionClick(option)}
        >
          <span className="ellipsis">{option.label}</span>
        </div>
      ))}
    </div>
  )
}
