import { FormProvider, FormSubmitType } from '@/providers'
import { useModal, useSteps } from '@/utils'
import { FormikHelpers } from 'formik'
import { FunctionComponent, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { AnyObject, ObjectSchema } from 'yup'
import { Modal } from '../Modal'
import { StepWrapper, StepWrapperProps } from './StepWrapper'

export type stepperHandlers<StepKey> = {
  back: () => void
  next: () => void
  reset: () => void
  goTo: (stepTitle: StepKey) => void
}

export type StepperFormStepType<Values, StepKey extends string> = {
  component: FunctionComponent<stepperHandlers<StepKey>>
  key: StepKey
  stepTitle?: string
  onSubmit?: StepperFormProps<Values, StepKey>['onFinish']
  validateFieldsToDisable?: [keyof Values, ...(keyof Values)[]]
  skip?: {
    text: string
    to?: StepKey
    type?: 'reset' | 'button'
    fields?: (keyof Values)[]
    onSkip?: () => void
  }
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    next: t('onboardingPage.next'),
  }
}

export type StepperFormProps<Values, StepKey extends string> = {
  steps: StepperFormStepType<Values, StepKey>[]
  initialStep?: StepperFormProps<Values, StepKey>['steps'][number]['key']
  initialValues: Values
  onBack?: (step: StepKey) => void
  onFinish: (
    values: Values,
    stepperState: stepperHandlers<StepKey> & {
      openError: () => void
    },
    formikState: FormikHelpers<Values>,
  ) => Promise<void>
  validationSchema: Partial<Record<StepKey, ObjectSchema<AnyObject>>>
} & Pick<
  StepWrapperProps<Values, StepKey>,
  'onClose' | 'submitButtonText' | 'title' | 'errorComponent' | 'layout'
> &
  (
    | {
        layout?: 'page'
        hideLastStepProgress?: boolean
      }
    | {
        hideLastStepProgress?: never
      }
  )

const StepperContainer = <Values extends AnyObject = AnyObject, StepKey extends string = string>({
  layout = 'page',
  children,
}: PropsWithChildren<Pick<StepperFormProps<Values, StepKey>, 'layout'>>) => {
  if (layout === 'page') {
    return (
      <div className="page pt-[60px] bg-sec0 flex justify-center child:pt-4 child:px-7 child:pb-8">
        {children}
      </div>
    )
  }

  if (layout === 'modal') {
    return <Modal>{children}</Modal>
  }
}

export const StepperForm = <Values extends AnyObject = AnyObject, StepKey extends string = string>({
  steps,
  initialStep = steps[0].key,
  initialValues,
  onBack,
  onClose,
  onFinish,
  submitButtonText,
  validationSchema,
  title,
  errorComponent,
  hideLastStepProgress = false,
  layout,
}: StepperFormProps<Values, StepKey>) => {
  const errorState = useModal(false)
  const { isFirst, isLast, data, back, next, goTo, index, total, reset } = useSteps<
    StepperFormStepType<Values, StepKey>
  >({
    initialStep,
    keyPropName: 'key',
    steps,
  })

  const { key, component: Component, stepTitle, onSubmit, validateFieldsToDisable, skip } =
    data || {}

  const handleSubmit: FormSubmitType<Values> = (values, formikState) =>
    new Promise<void>((resolve, reject) => {
      if (onSubmit) {
        onSubmit(values, { next, back, goTo, reset, openError: errorState.open }, formikState)
          .then(resolve)
          .catch(reject)
      } else if (!isLast) {
        next()
        resolve()
      } else {
        onFinish(values, { next, back, goTo, reset, openError: errorState.open }, formikState)
          .then(resolve)
          .catch(reject)
      }
    })
  const messages = useMessages()

  return (
    <StepperContainer layout={layout}>
      <FormProvider<Values>
        className="max-w-[488px] w-full flex flex-col gap-8"
        initialValues={initialValues}
        validationSchema={validationSchema[key as StepKey]}
        onSubmit={handleSubmit}
      >
        {/* @ts-ignore */}
        <StepWrapper<Values, StepKey>
          onBack={
            !isFirst &&
            (errorState.isOpen
              ? errorState.close
              : () => {
                  back()
                  onBack?.(key!)
                })
          }
          title={title}
          onClose={onClose}
          percentage={((index + 1) / total) * 100}
          stepTitle={stepTitle}
          submitButtonText={isLast ? submitButtonText : messages.next}
          validateFieldsToDisable={validateFieldsToDisable}
          errorComponent={errorState.isOpen && errorComponent}
          skip={skip}
          next={next}
          goTo={goTo}
          reset={reset}
          hideProgress={isLast && hideLastStepProgress}
          layout={layout}
        >
          {Component && <Component back={back} next={next} goTo={goTo} reset={reset} />}
        </StepWrapper>
      </FormProvider>
    </StepperContainer>
  )
}
