import { resources } from '@/@types/resources'
import { SettingsType } from '@/api'
import { ReactComponent as GlobeIcon } from '@/assets/globe.svg'
import { PageLoader, Select } from '@/components'
import { ActivePatientGuard } from '@/guards/ActivePatientGuard'
import { AuthGuard } from '@/guards/AuthGuard'
import { ErrorBoundary } from '@/guards/ErrorBoundary'
import { MatchGuard } from '@/guards/MatchGuard'
import { NotAuthGuard } from '@/guards/NotAuthGuard'
import { RouteGuard } from '@/guards/RouteGuard'
import { SuperRelativeGuard } from '@/guards/SuperRelativeGuard'
import {
  AuthPage,
  ChatPage,
  ConfirmEmailChangePage,
  DashboardPage,
  ForgotPasswordPage,
  HomePage,
  IcuDiaryPage,
  ImprintAndDataSecurityPage,
  InvitePage,
  LoginPage,
  ManageRelativePage,
  MediaPage,
  MySpacePage,
  OnboardingPage,
  PasswordChangePage,
  ProfilePage,
  RegisterPage,
  RelativesPage,
  ResetPasswordPage,
  SettingsPage,
  SurveyPage,
  VerifyEmailPage,
} from '@/pages'
import { VideoCallPage } from '@/pages/VideoCallPage'
import { AuthProvider, SurveyProvider } from '@/providers'
import { useSettings } from '@/services'
import {
  error,
  useInvalidateQuery,
  usePreventMobileZoom,
  useTrackTime,
  useWebviewMessageListener,
  webviewMessageTypes,
} from '@/utils'
import * as Sentry from '@sentry/react'
import { init, replace, split, when } from 'ramda'
import { ComponentProps, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { paths } from './paths'

const AppWrapper = () => {
  useTrackTime()

  return <ErrorBoundary />
}

export type LanguageSelectProps = Pick<ComponentProps<typeof Select>, 'reversed' | 'inputValueType'>

const PublicPageWrapper = () => {
  const { i18n } = useTranslation()
  const language = i18n.language

  const { data, isLoading } = useSettings() as ReturnType<typeof useSettings> & {
    data?: SettingsType
  }

  const LanguageSelect = useCallback(
    ({ inputValueType, reversed }: LanguageSelectProps) => (
      <Select<SettingsType['languages'][number]['value']>
        readOnly
        reversed={reversed}
        inputValueType={inputValueType}
        options={data?.languages || []}
        icon={GlobeIcon}
        value={
          when(
            () => inputValueType === 'value',
            replace('UK', 'UA'),
            language,
          ) as keyof typeof resources
        }
        onChange={i18n.changeLanguage}
      />
    ),
    [language, JSON.stringify(data?.languages)],
  )

  if (isLoading) return <PageLoader />

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 first:child:pb-[64px]">
      <Outlet context={{ LanguageSelect }} />
      <div className="fixed bottom-2 w-full flex justify-center z-50">
        <LanguageSelect reversed inputValueType="value" />
      </div>
    </div>
  )
}

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const BaseRouter = () => {
  const navigate = useNavigate()
  const invalidateQuery = useInvalidateQuery()

  const handleNativeMessage = ({ data }: MessageEvent) => {
    try {
      const { type, meetingId, patientId } = JSON.parse(data)
      switch (type as keyof typeof webviewMessageTypes) {
        case 'joinMeeting':
          localStorage.setItem('meeting-survey-id', meetingId)
          break
        case 'meetingEnded':
          invalidateQuery('chatMessages')
          break
        case 'meetingPrompt':
          navigate(`/home/${patientId}/chat`, {
            state: {
              meetingPrompt: true,
            },
          })
      }
    } catch (e) {
      error(e)
    }
  }

  usePreventMobileZoom()

  useWebviewMessageListener(handleNativeMessage)

  return (
    <SentryRoutes>
      <Route path="/" element={<AppWrapper />}>
        <Route path="" element={<PublicPageWrapper />}>
          <Route path={paths.imprintAndDataSecurity} element={<ImprintAndDataSecurityPage />} />
          <Route path={`${paths.verifyEmail}/:token?`} element={<VerifyEmailPage />} />
          <Route
            path={`${paths.confirmEmailChange}/:token?`}
            element={<ConfirmEmailChangePage />}
          />
          <Route path={paths.resetPassword} element={<ResetPasswordPage />} />
          <Route path="" element={<RouteGuard guard={NotAuthGuard} fallbackRoute="home" />}>
            <Route path={paths.auth}>
              <Route path="" element={<AuthPage />} />
              <Route path={paths.login} element={<LoginPage />} />
              <Route path={paths.register} element={<RegisterPage />} />
              <Route path={paths.forgotPassword} element={<ForgotPasswordPage />} />
            </Route>
            <Route path="" element={<Navigate to={paths.auth} />} />
            <Route path="*" element={<Navigate to={paths.auth} />} />
          </Route>
        </Route>
        <Route path="" element={<RouteGuard guard={AuthGuard} fallbackRoute="auth" />}>
          <Route path="" element={<AuthProvider />}>
            <Route path={paths.onboarding} element={<OnboardingPage />} />
            <Route path={paths.profile} element={<ProfilePage />} />
            <Route path={paths.settings} element={<SettingsPage />} />
            <Route path={paths.passwordChange} element={<PasswordChangePage />} />
            <Route path={paths.home} element={<SurveyProvider />}>
              <Route path=":patientId" element={<ActivePatientGuard />}>
                <Route path={paths.videoCall} element={<VideoCallPage />} />
                <Route path={paths.media} element={<MediaPage />} />
                <Route path={paths.relatives}>
                  <Route path="" element={<RelativesPage />} />
                  <Route path={paths.invite} element={<InvitePage />} />
                  <Route
                    path=""
                    element={
                      <SuperRelativeGuard
                        fallbackRoute={init(split('/', window.location.pathname)).join('/')}
                      />
                    }
                  >
                    <Route path=":relativeId" element={<ManageRelativePage />} />
                  </Route>
                </Route>
              </Route>
              <Route path=":patientId?" element={<HomePage />}>
                <Route path="" element={<Navigate to={paths.dashboard} />} />
                <Route path="" element={<MatchGuard />}>
                  <Route path={paths.dashboard} element={<DashboardPage />} />
                  <Route path={paths.chat} element={<ChatPage />} />
                  <Route path={paths.icuDiary} element={<IcuDiaryPage />} />
                  <Route path={paths.mySpace} element={<MySpacePage />} />
                  <Route path={paths.survey} element={<SurveyPage />} />
                </Route>
                <Route path="*" element={<Navigate to={paths.dashboard} />} />
              </Route>
            </Route>
            <Route path="" element={<Navigate to={paths.home} />} />
            <Route path="*" element={<Navigate to={paths.home} />} />
          </Route>
        </Route>
      </Route>
    </SentryRoutes>
  )
}
