import { makeQueryString } from '@/utils'
import { api } from './api'

export type LoginBody = { email: string; password: string }

export type RegisterBody = LoginBody & {
  phone: string
  first_name: string
  last_name: string
  invite_code?: string
  pin_code?: string
  search?:
    | { patient_account_creation: true; register_with_existing_invitations?: never }
    | { patient_account_creation?: never; register_with_existing_invitations: true }
}

type LoginResponse = {
  access: string
  onesignal_token: string
  invitations_found: never
  patient_account_found: never
}

export type RegisterResponse =
  | LoginResponse
  | {
      invitations_found: boolean
      patient_account_found: never
      access: never
      onesignal_token: never
    }
  | {
      invitations_found: never
      patient_account_found: boolean
      access: never
      onesignal_token: never
    }

export const login = (body: LoginBody) =>
  api.post<LoginBody, LoginResponse>('/auth/jwt/create/', body)

export const register = ({ search, ...body }: RegisterBody) =>
  api.post<RegisterBody, RegisterResponse>(
    `/auth/users/${
      body.pin_code ? `submit_registration_pin_code/${makeQueryString(search!)}` : ''
    }`,
    body,
  )

export const resendRegisterOTP = (body: {
  email: string
  phone: string
  first_name: string
  last_name: string
}) => api.patch('/auth/users/resend_registration_pin_code/', body)

export const doesEmailExist = (email: string) => api.post('/auth/users/validate_email/', { email })

export const doesPhoneExist = (phone: string) => api.get(`/auth/users/check_phone/${phone}/`)

export const requestResetPassword = (email: string) =>
  api.post('/auth/users/reset-password/', { email, origin: 'app' }) // todo origin

export const validateResetPasswordToken = (token: string) =>
  api.get(`/auth/users/${token}/check_if_password_reset_token_is_valid/`)

export type ResetPasswordBody = { token: string; uid: string; password: string }

export const resetPassword = (body: ResetPasswordBody) =>
  api.post('/auth/users/reset-password-confirm/', body)

export const resendVerificationEmail = () => api.put('/auth/users/resend_activation_link/')

export const resendEmailChangeVerification = () =>
  api.put('/auth/users/resend_email_changing_mail/')

export const verifyEmail = (token: string) => api.post(`/auth/users/activate_account/${token}/`)

type confirmEmailResponse =
  | { email_change_with_additional_sms: true; email_is_changed?: never; phone_is_changed?: never }
  | { email_is_changed: true; email_change_with_additional_sms?: never; phone_is_changed?: never }
  | { phone_is_changed: true; email_is_changed?: never; email_change_with_additional_sms?: never }

export const confirmEmailChange = (token: string) =>
  api.post<{ token: string }, confirmEmailResponse>('/auth/users/email-confirm/', { token })

export type InviteData = { already_registered: boolean } & Pick<
  RegisterBody,
  'first_name' | 'last_name' | 'email'
>

export const getInviteData = (invite_code?: string) =>
  api.get<InviteData>(`/auth/users/get_invitation_data/${makeQueryString({ invite_code })}`)

export type UpdatePasswordBody = { new_password: string; old_password: string }

export const updatePassword = (body: UpdatePasswordBody) =>
  api.patch<UpdatePasswordBody>('/auth/users/change_password/', body)
