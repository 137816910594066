import { ReactComponent as EyeClose } from '@/assets/eye-close.svg'
import { ReactComponent as EyeOpen } from '@/assets/eye-open.svg'
import { useModal } from '@/utils'
import { ComponentProps, InputHTMLAttributes, useEffect } from 'react'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean
  inputType?: 'email' | 'name'
}

export const Input = ({ className = '', error, onChange, inputType, ...props }: InputProps) => {
  const handleChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) => {
    switch (inputType) {
      case 'email':
        if (e.target.value.includes(' ')) {
          e.target.value = e.target.value.trim()
        }
        break
      case 'name':
        if (/[`!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?~]/.test(e.target.value)) {
          e.target.value = e.target.value.replace(/[`!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?~]/g, '')
        }
        break
    }
    onChange?.(e)
  }

  return (
    <input
      onChange={handleChange}
      className={`px-4 py-[10px] border-[0.5px] bg-b0 rounded-[10px] placeholder:text-b50 text-placeholder w-full outline-none h-[48px] transition-all duration-[400ms] ${
        error ? 'border-danger text-danger placeholder:text-danger' : 'border-sec30 text-b100'
      } ${className}`}
      {...props}
    />
  )
}
export const Password = ({ value, ...props }: Omit<ComponentProps<typeof Input>, 'type'>) => {
  const visibleState = useModal(false)

  useEffect(() => {
    if (!value) {
      visibleState.close()
    }
  }, [value])

  return (
    <div className="relative">
      <Input
        value={value}
        type={visibleState.isOpen ? 'text' : 'password'}
        className="pr-[64px]"
        {...props}
      />
      {value &&
        (visibleState.isOpen ? (
          <EyeClose
            width={32}
            height={32}
            onClick={visibleState.close}
            className="absolute right-4 cursor-pointer top-2"
            fill="var(--primary)"
          />
        ) : (
          <EyeOpen
            width={32}
            height={32}
            onClick={visibleState.open}
            className="absolute right-4 cursor-pointer top-2"
            fill="var(--primary)"
          />
        ))}
    </div>
  )
}
