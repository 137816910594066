import { ReactComponent as PlucIcon } from '@/assets/plus.svg'
import { ReactComponent as RelativesIcon } from '@/assets/relatives.svg'
import { Alert, Banner, BannerButton, DetailsCard, PageLoader, SecondaryButton } from '@/components'
import { useAuthContext, useSurveyContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useCheckCurrentSuperRelative, useCurrentPatient, useRemovePatient } from '@/services'
import { changeDateFormat, useModal, useNavigateWithFromState } from '@/utils'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { doesMatchRequestExist } from '../HomePage/HomePageNavBar'

const useMessages = (patient_name: string) => {
  const { t } = useTranslation()

  return {
    title: t('dashboard.title'),
    hospital: t('global.hospital'),
    ward: t('global.ward'),
    relationshipType: t('global.relationshipType'),
    admissionDate: t('global.admissionDate'),
    stay: t('global.stay'),
    diaShowTitle: t('dashboard.diaShowCard.title'),
    diaShowText: t('dashboard.diaShowCard.text'),
    diaShowInactiveText: t('dashboard.diaShowCard.inactiveText', { patient_name }),
    diaShowInactiveBtn: t('dashboard.diaShowCard.inactiveButton'),
    diaShowBtn: t('dashboard.diaShowCard.button'),

    relativesTitle: t('dashboard.relativesCard.title'),
    relativesText: t('dashboard.relativesCard.text'),
    relativesBtn: t('dashboard.relativesCard.button'),
    relativesTitleSuper: t('dashboard.relativesCard.titleSuper'),
    relativesTextSuper: t('dashboard.relativesCard.textSuper'),
    relativesBtnSuper: t('dashboard.relativesCard.buttonSuper'),

    removePatientBtn: t('dashboard.removePatient'),
    removePatientTitle: t('dashboard.removePatientModal.title'),
    removePatientText: t('dashboard.removePatientModal.text1'),
    removePatientConfirm: t('dashboard.removePatientModal.confirmCta'),
    removePatientDecline: t('dashboard.removePatientModal.declineCta'),
  }
}

export const DashboardPage = () => {
  const { patientId } = useParams<{ patientId: string }>()
  const { relatives, currentPatient } = useAuthContext()
  const surveyContext = useSurveyContext()
  const { data, isLoading } = useCurrentPatient()
  const superRelativeResponse = useCheckCurrentSuperRelative()
  const navigate = useNavigateWithFromState()
  const alertState = useModal(false)

  const removePatientMutation = useRemovePatient(currentPatient?.id!)

  const handleRemovePatient = () => {
    removePatientMutation.mutate(
      {},
      {
        onSuccess: surveyContext.triggerSurvey,
      },
    )
  }
  const messages = useMessages(`${data?.first_name} ${data?.last_name}`)

  if (isLoading) return <PageLoader />

  return (
    <div className="max-w-[488px] flex flex-col gap-4 p-4 w-full">
      <div className="text-h4">{messages.title}</div>
      <DetailsCard
        details={[
          { label: messages.hospital, value: data?.hospital },
          { label: messages.ward, value: data?.station },
          {
            label: currentPatient?.active ? messages.admissionDate : messages.stay,
            value: data?.date_of_admission
              ? `${changeDateFormat('-', '.', data.date_of_admission)}${
                  currentPatient?.active
                    ? ''
                    : ' - ' + changeDateFormat('-', '.', data.deactivated_at)
                }`
              : '',
          },
          { label: messages.relationshipType, value: currentPatient?.relation },
        ]}
      />
      <Banner
        title={messages.diaShowTitle}
        actions={[
          {
            variant: 'outlined',
            text: (
              <BannerButton
                text={currentPatient?.active ? messages.diaShowBtn : messages.diaShowInactiveBtn}
                icon={PlucIcon}
              />
            ),
            onClick: () => {
              navigate(`/${paths.home}/${patientId}/${paths.media}`)
            },
          },
        ]}
      >
        {currentPatient?.active ? messages.diaShowText : messages.diaShowInactiveText}
      </Banner>
      {!superRelativeResponse.data || !doesMatchRequestExist(relatives) ? (
        <Banner
          title={messages.relativesTitle}
          actions={[
            {
              variant: 'outlined',
              text: <BannerButton text={messages.relativesBtn} icon={RelativesIcon} />,
              onClick: () => {
                navigate(`/${paths.home}/${patientId}/${paths.relatives}`)
              },
            },
          ]}
        >
          {messages.relativesText}
        </Banner>
      ) : (
        <Banner
          badge
          title={messages.relativesTitleSuper}
          actions={[
            {
              variant: 'outlined',
              text: <BannerButton text={messages.relativesBtnSuper} icon={RelativesIcon} />,
              onClick: () => {
                navigate(`/${paths.home}/${patientId}/${paths.relatives}`)
              },
            },
          ]}
        >
          {messages.relativesTextSuper}
        </Banner>
      )}
      {!currentPatient?.is_patient_user && (
        <SecondaryButton
          block
          danger
          className="mt-auto"
          disabled={removePatientMutation.isLoading}
          onClick={alertState.open}
        >
          {messages.removePatientBtn}
        </SecondaryButton>
      )}
      {alertState.isOpen && (
        <Alert
          title={messages.removePatientTitle}
          contents={[messages.removePatientText]}
          actions={[
            { text: messages.removePatientDecline, onClick: alertState.close },
            {
              text: messages.removePatientConfirm,
              variant: 'danger',
              onClick: handleRemovePatient,
            },
          ]}
        />
      )}
    </div>
  )
}
