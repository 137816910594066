import { api } from './api'

export type MeetingType = {
  id: string
  status: 'inactive' | 'active' | 'in_progress'
  room_name: string
  signature: string
  timer_will_elapse: string
  users_in_call: number
  max_users_in_call: number
  meeting_participants: []
}

export type MeetingPromptType = {
  status: MeetingType['status']
  time_left: number
  users_in_call: number
}

export const getMeeting = (patientId: number) =>
  api.get<MeetingType>(`/meetings/${patientId}/get_by_patient/`)

export const getMeetingPrompt = (patientId: number) =>
  api.get<MeetingPromptType>(`/meetings/${patientId}/meeting_notification_data/`)
