import { PropsWithChildren } from 'react'
import { ButtonBase, ButtonProps } from '../Button'
import { Paper } from '../Paper'

type bannerActionType = Omit<ButtonProps, 'block' | 'children'> & {
  text: string | JSX.Element
}

type BannerProps = {
  title?: string
  badge?: boolean
  icon?: JSX.Element
  actions?: bannerActionType[]
  helperText?: string
}

export const Banner = ({
  children,
  title,
  actions = [],
  badge,
  icon,
  helperText,
}: PropsWithChildren<BannerProps>) => (
  <Paper className="bg-blueGradient !shadow-[0_2px_4px_0_#C1DBDF]">
    {title && (
      <div className="flex gap-2 items-center text-h4">
        {badge && <div className="badge-danger" />}
        {title}
      </div>
    )}
    <div className="flex gap-4">
      {icon}
      <span className="text-textS">{children}</span>
    </div>
    {actions.map(({ text, ...props }) => (
      <ButtonBase key={text.toString()} {...props}>
        {text}
      </ButtonBase>
    ))}
    {helperText && <span className="text-textS text-b90">{helperText}</span>}
  </Paper>
)
