import { MatchingInviteRespondType, RelationType } from '@/api'
import { DetailsCard, Divider, PrimaryButton, SecondaryButton, Select } from '@/components'
import { useAuthContext, useSnackbar } from '@/providers'
import { useAcceptMatchingInvite, useDeclineMatchingInvite } from '@/services'
import { areLoading } from '@/utils'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('upcomingInvitePage.title'),
    titleBold: t('upcomingInvitePage.title2Bold'),
    youAre: t('upcomingInvitePage.youAre'),
    text: t('upcomingInvitePage.text'),
    buttonAccept: t('upcomingInvitePage.buttonAccept'),
    buttonDecline: t('upcomingInvitePage.buttonDecline'),
    snackAccept: t('upcomingInvitePage.snackAccept'),
    snackDecline: t('upcomingInvitePage.snackDecline'),
    hospital: t('global.hospital'),
    ward: t('global.ward'),
    firstName: t('profilePage.firstName'),
    lastName: t('profilePage.lastName'),
  }
}

export const UpcomingInvitePage = () => {
  const snackbar = useSnackbar()
  const { currentPatient, settings } = useAuthContext()
  const [relation, setRelation] = useState(currentPatient?.relation)
  const messages = useMessages()

  const acceptInviteMutation = useAcceptMatchingInvite({
    patient_id: currentPatient?.id,
    request_id: currentPatient?.request_id,
  } as MatchingInviteRespondType)

  const declineInviteMutation = useDeclineMatchingInvite({
    patient_id: currentPatient?.id,
    request_id: currentPatient?.request_id,
  } as MatchingInviteRespondType)

  const handleAccept = () => {
    acceptInviteMutation.mutate(currentPatient?.is_patient_user ? {} : { relation }, {
      onSuccess: () => {
        snackbar.success(messages.snackAccept)
      },
    })
  }

  const handleDecline = () => {
    declineInviteMutation.mutate(
      {},
      {
        onSuccess: () => {
          snackbar.success(messages.snackDecline)
        },
      },
    )
  }

  return (
    <div className="flex justify-center">
      <div className="flex flex-col gap-4 p-6 h-full max-w-[488px]">
        <div className="text-b90">
          {messages.title}
          <br />
          <br />
          <strong>{messages.titleBold}</strong>
        </div>
        <DetailsCard
          details={[
            { label: messages.firstName, value: currentPatient?.first_name },
            { label: messages.lastName, value: currentPatient?.last_name },
            { label: messages.hospital, value: currentPatient?.hospital },
            { label: messages.ward, value: currentPatient?.station },
          ]}
        />
        <Divider />
        <div className="text-b90">
          <Trans
            i18nKey={'upcomingInvitePage.text'}
            values={{ patient_name: `${currentPatient?.first_name} ${currentPatient?.last_name}` }}
          />
        </div>
        {!currentPatient?.is_patient_user && (
          <div className="flex flex-col gap-2 w-full">
            <span className="text-h4">{messages.youAre}:</span>
            <Select<RelationType>
              readOnly
              value={relation}
              onChange={setRelation}
              options={settings.relationships}
            />
          </div>
        )}
        <PrimaryButton
          block
          onClick={handleAccept}
          disabled={
            areLoading([acceptInviteMutation, declineInviteMutation]) ||
            (!relation && !currentPatient?.is_patient_user)
          }
        >
          {messages.buttonAccept}
        </PrimaryButton>
        <SecondaryButton
          block
          danger
          onClick={handleDecline}
          disabled={areLoading([acceptInviteMutation, declineInviteMutation])}
        >
          {messages.buttonDecline}
        </SecondaryButton>
      </div>
    </div>
  )
}
