import {
  NotificationType,
  getNotifications,
  getNotificationsConfig,
  readNotification,
  updateNotificationConfig,
} from '@/api'
import { ReactQueryOptions, reactQueryKeys, useInvalidateOnSuccessMutation } from '@/utils'
import { useQuery } from 'react-query'

export const useNotifications = (options: ReactQueryOptions<NotificationType[], 'notifications'>) =>
  useQuery(reactQueryKeys.notifications, getNotifications, options)

export const useReadNotifications = () =>
  useInvalidateOnSuccessMutation('notifications', readNotification)

export const useNotificationConfig = () =>
  useQuery(reactQueryKeys.notificationsConfig, getNotificationsConfig)

export const useUpdateNotificationConfig = () =>
  useInvalidateOnSuccessMutation('notificationsConfig', updateNotificationConfig)
