import { SurveyAnswer, SurveyType } from '@/api'
import { ReactComponent as Rating1Icon } from '@/assets/rating1.svg'
import { ReactComponent as Rating2Icon } from '@/assets/rating2.svg'
import { ReactComponent as Rating3Icon } from '@/assets/rating3.svg'
import { ReactComponent as Rating4Icon } from '@/assets/rating4.svg'
import { ReactComponent as Rating5Icon } from '@/assets/rating5.svg'
import { FormItem, useAuthContext } from '@/providers'
import { useSubmitSurveyAnswers, useTrackEvent } from '@/services'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { CardSelect } from '../CardSelect'
import { StepperForm, StepperFormProps } from '../StepperForm'
import { TextArea } from '../TextArea'

type SurveyProps = Omit<SurveyType, 'answered_at'> & {
  onClose: () => void
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    finish: t('profilePage.done'),
    min: (min: number) => t('validations.min', { min }),
    required: t('validations.required'),
    yes: t('global.yes'),
    no: t('global.no'),
    write: t('mySpacePage.survey.textAreaPlaceholder'),
    title: t('mySpacePage.survey.title'),
    ratingMinLabel: t('mySpacePage.survey.rating.minLabel'),
    ratingMidLabel: t('mySpacePage.survey.rating.midLabel'),
    ratingMaxLabel: t('mySpacePage.survey.rating.maxLabel'),
  }
}

type StepKey = SurveyType['questions'][number]['id']
type FormValues = Record<StepKey, SurveyAnswer>

type stepsType = StepperFormProps<FormValues, StepKey>['steps']

export const Survey = ({ id: surveyId, questions, onClose }: SurveyProps) => {
  const messages = useMessages()
  const { currentPatient } = useAuthContext()

  const submitAnswersMutation = useSubmitSurveyAnswers(
    surveyId,
    currentPatient?.is_patient_user ? 'patient' : 'relative',
  )
  const trackEventMutation = useTrackEvent()

  const handleSubmit: StepperFormProps<FormValues, StepKey>['onFinish'] = (values) =>
    new Promise<void>((resolve, reject) => {
      submitAnswersMutation.mutate(values, {
        onError: reject,
        onSuccess: () => {
          trackEventMutation.mutate({
            event: 'survey_finish',
            properties: {
              survey_id: surveyId,
            },
          })
          onClose()
          resolve()
        },
      })
    })

  const handleFirstStepSubmit: stepsType[number]['onSubmit'] = (_, { next }) =>
    new Promise<void>((resolve) => {
      trackEventMutation.mutate({
        event: 'survey_fist_select',
        properties: {
          survey_id: surveyId,
        },
      })
      next()
      resolve()
    })

  const steps = useMemo<stepsType>(
    () =>
      questions.map(({ id, question, question_type, possible_answers }, i) => ({
        key: id,
        stepTitle: question,
        validateFieldsToDisable:
          question_type === 'MULTI_SELECT' || question_type === 'RATING' ? [id] : undefined,
        onSubmit: i ? undefined : handleFirstStepSubmit,
        component: () => {
          switch (question_type) {
            case 'FREE_TEXT':
              return (
                <FormItem<typeof TextArea>
                  component={TextArea}
                  name={id}
                  componentProps={{
                    placeholder: messages.write,
                    rows: 8,
                  }}
                />
              )

            case 'RATING':
              return (
                <div className="flex flex-col gap-4">
                  <FormItem<typeof CardSelect>
                    component={CardSelect}
                    name={id}
                    componentProps={{
                      layout: 'horizontal',
                      optionClassName: 'justify-center !h-[60px] !bg-sec10 sm:px-0',
                      options: [
                        {
                          label: <Rating1Icon width={40} height={40} className="shrink-0" />,
                          value: 1,
                        },
                        {
                          label: <Rating2Icon width={40} height={40} className="shrink-0" />,
                          value: 2,
                        },
                        {
                          label: <Rating3Icon width={40} height={40} className="shrink-0" />,
                          value: 3,
                        },
                        {
                          label: <Rating4Icon width={40} height={40} className="shrink-0" />,
                          value: 4,
                        },
                        {
                          label: <Rating5Icon width={40} height={40} className="shrink-0" />,
                          value: 5,
                        },
                      ],
                    }}
                  />
                  <div className="flex child:w-[calc((100%-32px)/5)] gap-2 child:text-center child:text-ellipsis child:overflow-hidden child:text-textXS">
                    <div>{messages.ratingMinLabel}</div>
                    <div />
                    <div>{messages.ratingMidLabel}</div>
                    <div />
                    <div>{messages.ratingMaxLabel}</div>
                  </div>
                </div>
              )
            case 'BOOLEAN_SELECT':
              return (
                <FormItem<typeof CardSelect>
                  component={CardSelect}
                  name={id}
                  componentProps={{
                    optionClassName: 'justify-center',
                    options: [
                      { label: messages.yes, value: true },
                      { label: messages.no, value: false },
                    ],
                  }}
                />
              )
            case 'MULTI_SELECT':
              return (
                <FormItem<typeof CardSelect>
                  component={CardSelect}
                  name={id}
                  componentProps={{
                    multiple: true,
                    options: possible_answers.map((label, value) => ({ label, value })),
                  }}
                />
              )
          }
        },
      })),
    [JSON.stringify(questions)],
  )

  const validationSchema: StepperFormProps<FormValues, StepKey>['validationSchema'] = useMemo(
    () =>
      questions.reduce((acc, cur) => {
        let rule

        switch (cur.question_type) {
          case 'FREE_TEXT':
            rule = yup.string()
            break
          case 'MULTI_SELECT':
            rule = yup.array().of(yup.number()).min(1, messages.min(1))
            break
          case 'BOOLEAN_SELECT':
            rule = yup.boolean()
            break
          case 'RATING':
            rule = yup
              .number()
              .oneOf([1, 2, 3, 4, 5], messages.required)
              .required(messages.required)
            break
        }

        return {
          ...acc,
          [cur.id]: yup.object({
            [cur.id]: rule,
          }),
        }
      }, {}),
    [JSON.stringify(questions)],
  )

  useEffect(() => {
    trackEventMutation.mutate({
      event: 'survey_start',
      properties: {
        survey_id: surveyId,
      },
    })
  }, [surveyId])

  return (
    <StepperForm<FormValues, StepKey>
      layout="modal"
      title={messages.title}
      submitButtonText={messages.finish}
      onClose={onClose}
      steps={steps}
      onFinish={handleSubmit}
      validationSchema={validationSchema}
      initialValues={questions.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.id]: undefined,
        }),
        {},
      )}
    />
  )
}
