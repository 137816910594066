// todo cleanup
import { Alert, AudioPlayer } from '@/components'
import { useSnackbar } from '@/providers'
import {
  base64ToBlob,
  error,
  postMessageToWebview,
  useModal,
  useWebviewMessageListener,
  webviewMessageTypes,
} from '@/utils'
import { inc } from 'ramda'
import { useEffect, useState } from 'react'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import { useTranslation } from 'react-i18next'
import { WaveSoundAnimation } from './WaveSoundAnimation'

export const shortFormatSeconds = (val: number) => {
  const minutes = Math.trunc(val / 60)
  const seconds = val % 60
  return minutes + ':' + seconds.toString().padStart(2, '0')
}

type RecordAudioProps = {
  onClose: () => void
  onConfirm: (file: Blob) => void
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('recordAudioPage.title'),
    hint: t('recordAudioPage.hint'),
    buttonSend: t('recordAudioPage.buttonSend'),
    buttonTakeAgain: t('recordAudioPage.buttonTakeAgain'),
    cancel: t('global.cancel'),
    snackError: t('recordAudioPage.snackError'),
    loading: t('global.loading'),
  }
}
const limitSecondsRecordAudio = 120

export const useRecording = () => {
  const recordingState = useModal(false)
  const [recordingTime, setRecordingTime] = useState(0)
  const [blob, setBlob] = useState<Blob>()

  useEffect(() => {
    let interval: NodeJS.Timeout
    if (recordingState.isOpen) {
      setBlob(undefined)
      interval = setInterval(() => setRecordingTime(inc), 1000)
    }

    return () => {
      setRecordingTime(0)
      clearInterval(interval)
    }
  }, [recordingState.isOpen])

  return {
    isRecording: recordingState.isOpen,
    toggleRecording: recordingState.toggle,
    recordingTime,
    blob,
    setBlob,
  }
}

export const RecordAudio = ({ onClose, onConfirm }: RecordAudioProps) => {
  const snackbar = useSnackbar()
  const startState = useModal(false)
  const messages = useMessages()

  const nativeRecorder = useRecording()

  const onError = () => {
    startState.close()
    snackbar.error(messages.snackError)
  }

  const {
    startRecording,
    stopRecording,
    isRecording,
    recordingTime,
    recordingBlob,
  } = useAudioRecorder(
    {
      echoCancellation: true,
      noiseSuppression: true,
    },
    onError,
  )

  const handleStartRecording = () => {
    postMessageToWebview({
      type: 'startAudioRecord',
      callback: startRecording,
    })
  }

  const startListeningAudio = () => {
    postMessageToWebview({
      type: 'stopAudioRecord',
      callback: stopRecording,
    })
    startState.open()
  }

  const handleConfirm = () => {
    onConfirm(recordingBlob || nativeRecorder.blob!)
    onClose()
  }

  useEffect(() => {
    if (recordingTime >= limitSecondsRecordAudio) {
      startListeningAudio()
    }
  }, [recordingTime])

  useEffect(() => {
    if (nativeRecorder.recordingTime >= limitSecondsRecordAudio) {
      startListeningAudio()
    }
  }, [nativeRecorder.recordingTime])

  const handleNativeMessage = ({ data }: MessageEvent) => {
    try {
      const { type, value } = JSON.parse(data)

      switch (type as keyof typeof webviewMessageTypes) {
        case 'startAudioRecord':
          if (value) {
            nativeRecorder.toggleRecording()
          } else {
            onError()
          }
          break
        case 'stopAudioRecord':
          if (value) {
            nativeRecorder.toggleRecording()
            base64ToBlob(`data:audio/mp3;base64,${value}`).then(nativeRecorder.setBlob)
          }
          break
      }
    } catch (e) {
      error(e)
    }
  }

  useWebviewMessageListener(handleNativeMessage, () =>
    postMessageToWebview({
      type: 'stopAudioRecord',
      callback: stopRecording,
    }),
  )

  return (
    <Alert
      closeable
      onClose={onClose}
      title={messages.title}
      actions={
        startState.isOpen
          ? [
              {
                variant: 'primary',
                text: messages.buttonSend,
                onClick: handleConfirm,
              },
              { variant: 'secondary', text: messages.buttonTakeAgain, onClick: startState.close },
            ]
          : [
              {
                variant: 'secondary',
                text: messages.cancel,
                onClick: onClose,
              },
            ]
      }
      contents={
        startState.isOpen
          ? [
              <div className="w-full">
                {recordingBlob || nativeRecorder.blob ? (
                  <AudioPlayer audio={URL.createObjectURL(recordingBlob || nativeRecorder.blob!)} />
                ) : (
                  <span>{messages.loading}</span>
                )}
              </div>,
            ]
          : [
              <div className="flex flex-col gap-6 items-center">
                {isRecording || nativeRecorder.isRecording ? (
                  <div className="flex flex-col gap-1 items-start">
                    <WaveSoundAnimation />
                    <span className="text-textXS text-danger">
                      {shortFormatSeconds(nativeRecorder.recordingTime || recordingTime)}
                    </span>
                  </div>
                ) : (
                  <span className="text-textS">{messages.hint}</span>
                )}
                <div className="flex w-[64px] h-[64px] shrink-0 items-center justify-center cursor-pointer p-1 border-4 border-b50 rounded-[50%]">
                  <div
                    className={` bg-danger transition-all ${
                      isRecording || nativeRecorder.isRecording
                        ? 'rounded-[8px] w-[32px] h-[32px]'
                        : 'rounded-[50%] w-full h-full'
                    }`}
                    onClick={
                      isRecording || nativeRecorder.isRecording
                        ? startListeningAudio
                        : handleStartRecording
                    }
                  />
                </div>
              </div>,
            ]
      }
    />
  )
}
