import {
  Me,
  Profile,
  UpdateUserBody,
  consumePhoneOTP,
  deleteAccount,
  deleteEmailChangeOTP,
  deleteProfilePicture,
  getAuthenticatedUser,
  getProfile,
  getProfilePicture,
  queryEmailChangeOTP,
  updateProfile,
  updateProfilePicture,
  updateUser,
} from '@/api'
import { useAuthContext } from '@/providers'
import {
  ReactQueryOptions,
  disunion,
  reactQueryKeys,
  useInvalidateOnSuccessMutation,
  useMutation,
} from '@/utils'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useLogout } from './auth'

export const useAuthenticatedUser = (options?: ReactQueryOptions<Me, 'me'>) =>
  useQuery(reactQueryKeys.me, getAuthenticatedUser, options)

export const useProfile = (options?: ReactQueryOptions<Profile, 'profile'>) =>
  useQuery(reactQueryKeys.profile, getProfile, options)

export const useProfilePicture = (
  options?: ReactQueryOptions<{ avatar: string | null }, 'avatar'>,
) => useQuery(reactQueryKeys.avatar, getProfilePicture, options)

export const useUpdateUser = () => {
  const { user } = useAuthContext()

  return useInvalidateOnSuccessMutation('me', (body: UpdateUserBody) =>
    updateUser(disunion(body, user)),
  )
}

export const useUpdateProfile = () => useInvalidateOnSuccessMutation('profile', updateProfile)

export const useUpdateProfilePicture = () =>
  useInvalidateOnSuccessMutation('avatar', updateProfilePicture)

export const useDeleteProfilePicture = () =>
  useInvalidateOnSuccessMutation('avatar', deleteProfilePicture)

export const useDeleteAccount = () => {
  const logout = useLogout()

  return useMutation(deleteAccount, { onSuccess: logout })
}

export const useConsumePhoneOTP = () => {
  const { t } = useTranslation()

  return useInvalidateOnSuccessMutation(
    'me',
    consumePhoneOTP,
    t('profilePage.validations.invalidOtp'),
  )
}

export const useQueryEmailChangeOTP = () => useQuery(reactQueryKeys.otp, queryEmailChangeOTP)

export const useDeleteEmailChangeOTP = () =>
  useInvalidateOnSuccessMutation('otp', deleteEmailChangeOTP)
