import { IcuDiaryItem } from '@/api'
import { ReactComponent as PenIcon } from '@/assets/pen.svg'
import { Banner, InfiniteScroller, PageLoader, PrimaryButton } from '@/components'
import { useAuthContext } from '@/providers'
import { useCurrentPatient, useIcuDiary } from '@/services'
import { useModal } from '@/utils'
import { filter } from 'ramda'
import { Trans, useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { AddIcuDiary } from './AddIcuDiary'
import { DiaryHelpModal } from './DiaryHelpModal'
import { IcuDiaryElement } from './IcuDiaryElement'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    bannerTitle: t('icuDiaryPage.banner.title'),
    bannerButton: t('icuDiaryPage.banner.button'),
    addButtonText: t('icuDiaryPage.button'),
  }
}

const IcuDiary = ({ onAdd }: { onAdd: () => void }) => {
  const { patientId } = useParams<{ patientId: string }>()
  const { currentPatient } = useAuthContext()
  const messages = useMessages()

  const diaryResponse = useIcuDiary(patientId!, { select: filter(Boolean) })
  const { data = [] } = diaryResponse

  return (
    <div className="max-w-[488px] p-4 w-full flex flex-col gap-4 !h-full">
      {!currentPatient?.is_patient_user && Boolean(data.length) && <DiaryHelpModal />}
      {!currentPatient?.is_patient_user &&
        (data.length ? (
          <PrimaryButton block className="svg:active:stroke-primary" onClick={onAdd}>
            <PenIcon width={20} height={20} className="stroke-b0 !fill-primary" />
            {messages.addButtonText}
          </PrimaryButton>
        ) : (
          <Banner
            title={messages.bannerTitle}
            actions={[
              {
                text: messages.bannerButton,
                variant: 'contained',
                onClick: onAdd,
              },
            ]}
          >
            <Trans
              i18nKey="icuDiaryPage.banner.text"
              values={{
                patientName: `${currentPatient?.first_name} ${currentPatient?.last_name}`,
                patientFirstName: currentPatient?.first_name,
              }}
            />
          </Banner>
        ))}
      {Boolean(data.length) && (
        <InfiniteScroller<IcuDiaryItem>
          queryState={diaryResponse}
          componentKey="id"
          offset={406}
          component={IcuDiaryElement}
          maxHeight="calc(100% - 92px)"
          containerClassName="shadow-[0_6px_25px_0_rgba(0,0,0,0.1)] bg-[rgba(0,0,0,0.05)]"
        />
      )}
    </div>
  )
}

export const IcuDiaryPage = () => {
  const addState = useModal(false)

  const { data, isLoading } = useCurrentPatient()

  if (isLoading) return <PageLoader />

  if (!data?.icu_diary_enabled) return <Navigate to="chat" />

  if (addState.isOpen) return <AddIcuDiary onClose={addState.close} />

  return <IcuDiary onAdd={addState.open} />
}
