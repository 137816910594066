import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { ReactComponent as Close } from '@/assets/close.svg'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { AnyObject } from 'yup'
import { ButtonLink, PrimaryButton, SecondaryButton } from '../Button'
import { LineProgress } from '../LineProgress'
import { StepperFormStepType } from './Component'

type AllOrNone<T> = Required<T> | Partial<Record<keyof T, undefined>>

export type StepLayoutBaseProps<Values, StepKey extends string> = PropsWithChildren<{
  onBack: false | (() => void)
  title: string
  onClose: () => void
  percentage: number
  stepTitle?: StepperFormStepType<Values, StepKey>['stepTitle']
  submitButtonText: string
  submitButtonDisabled: boolean
  layout?: 'page' | 'modal'
}>

export type StepLayoutPageProps<Values, StepKey extends string> = StepLayoutBaseProps<
  Values,
  StepKey
> &
  ({
    layout?: 'page'
    errorComponent?: false | JSX.Element
    hideProgress?: boolean
  } & AllOrNone<{ skip: StepperFormStepType<Values, StepKey>['skip']; onSkip: () => void }>)

type StepLayout<Values, StepKey extends string> =
  | (StepLayoutBaseProps<Values, StepKey> & {
      errorComponent?: never
      hideProgress?: never
      skip?: never
      onSkip?: never
    })
  | StepLayoutPageProps<Values, StepKey>

const useMessages = () => {
  const { t } = useTranslation()

  return { back: t('global.back') }
}

export const StepLayoutPage = <
  Values extends AnyObject = AnyObject,
  StepKey extends string = string
>({
  onBack,
  title,
  onClose,
  errorComponent,
  hideProgress,
  stepTitle,
  percentage,
  children,
  submitButtonDisabled,
  submitButtonText,
  skip,
  onSkip,
}: Omit<StepLayoutPageProps<Values, StepKey>, 'layout'>) => (
  <>
    <div className="subpage-header">
      {onBack ? (
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={onBack}
        />
      ) : (
        <div className="w-[24px]" />
      )}
      <div className="text-textM">{title}</div>
      <Close
        height={24}
        width={24}
        fill="var(--primary)"
        className="cursor-pointer"
        onClick={onClose}
      />
    </div>
    <>
      {errorComponent || (
        <>
          {!hideProgress && <LineProgress percentage={percentage} />}
          {stepTitle && (
            <span className="text-center text-b90 whitespace-pre-line">{stepTitle}</span>
          )}
          <div className="flex flex-col gap-2 w-full">{children}</div>
          <div className="flex flex-col gap-4 w-full sm:mt-auto">
            <PrimaryButton disabled={submitButtonDisabled} block type="submit">
              {submitButtonText}
            </PrimaryButton>
            {skip?.text && (
              <SecondaryButton block type={skip.type || 'button'} onClick={onSkip}>
                {skip.text}
              </SecondaryButton>
            )}
          </div>
        </>
      )}
    </>
  </>
)

export const StepLayoutModal = <
  Values extends AnyObject = AnyObject,
  StepKey extends string = string
>({
  onBack,
  title,
  onClose,
  percentage,
  stepTitle,
  children,
  submitButtonDisabled,
  submitButtonText,
}: StepLayoutBaseProps<Values, StepKey>) => {
  const messages = useMessages()

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-3 py-3 sticky top-0 bg-b0">
        <LineProgress percentage={percentage} />
        <Close
          height={24}
          width={24}
          fill="var(--b100)"
          className="cursor-pointer shrink-0"
          onClick={onClose}
        />
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <div className="text-h3 text-primary">{title}</div>
          <div className="text-h4">{stepTitle}</div>
        </div>
        {children}
        <div className="flex justify-between items-center sticky bottom-0 bg-b0 z-10">
          {onBack ? (
            <ButtonLink
              className="cursor-pointer flex items-center gap-1 capitalize !text-b50"
              onClick={onBack}
              type="button"
            >
              <ArrowLeft height={16} width={16} fill="var(--b50)" />
              {messages.back}
            </ButtonLink>
          ) : (
            <div />
          )}
          <PrimaryButton disabled={submitButtonDisabled} type="submit" className="px-8">
            {submitButtonText}
          </PrimaryButton>
        </div>
      </div>
    </div>
  )
}

export const StepLayout = <Values extends AnyObject = AnyObject, StepKey extends string = string>({
  onBack,
  title,
  onClose,
  errorComponent,
  hideProgress,
  percentage,
  stepTitle,
  children,
  submitButtonText,
  submitButtonDisabled,
  skip,
  onSkip,
  layout = 'page',
}: StepLayout<Values, StepKey>) => {
  if (layout === 'page')
    return (
      <StepLayoutPage
        onBack={onBack}
        onClose={onClose}
        title={title}
        stepTitle={stepTitle}
        skip={skip}
        onSkip={onSkip}
        errorComponent={errorComponent}
        submitButtonDisabled={submitButtonDisabled}
        submitButtonText={submitButtonText}
        percentage={percentage}
        hideProgress={hideProgress}
      >
        {children}
      </StepLayoutPage>
    )

  if (layout === 'modal')
    return (
      <StepLayoutModal
        onBack={onBack}
        onClose={onClose}
        title={title}
        stepTitle={stepTitle}
        submitButtonDisabled={submitButtonDisabled}
        submitButtonText={submitButtonText}
        percentage={percentage}
      >
        {children}
      </StepLayoutModal>
    )
}
