import { api } from './api'

type TrackEvent = 'page_viewed' | 'survey_start' | 'survey_fist_select' | 'survey_finish'

type EventTrackBody =
  | ({ event: TrackEvent; properties?: object } & {
      event: 'page_viewed'
      properties: {
        route: string
        duration_in_seconds: number
      }
    })
  | {
      event: Exclude<TrackEvent, 'page_viewed'>
      properties: {
        survey_id: string
      }
    }

export const trackEvent = ({ event, properties }: EventTrackBody) =>
  api.post('/auth/users/track_event/', {
    event,
    properties,
  })
