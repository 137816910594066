import { ReactComponent as LogoutIcon } from '@/assets/logout.svg'
import { ReactComponent as StarIcon } from '@/assets/star.svg'
import { Alert, Avatar, Popover } from '@/components'
import { config } from '@/config'
import { useAuthContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useCheckCurrentSuperRelative, useLogout } from '@/services'
import { openInNewTab, useModal, useNavigateWithFromState } from '@/utils'
import { useTranslation } from 'react-i18next'
import { PatientsDropdown } from './PatientsDropdown'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    settings: t('settings.title'),
    language: t('global.language'),
    cancel: t('global.cancel'),
    profile: t('userMenu.profile'),
    helpFeedback: t('userMenu.helpFeedback'),
    privacyPolicyImprint: t('userMenu.privacyPolicyImprint'),
    signOut: t('userMenu.signOut'),
    signOutTitle: t('signOutModal.title'),
    signOutText1: t('signOutModal.text1'),
    signOutText2: t('signOutModal.text2'),
  }
}

export const HomePageHeader = () => {
  const { user, currentPatient } = useAuthContext()
  const alertState = useModal(false)
  const superRelativeResponse = useCheckCurrentSuperRelative()
  const logout = useLogout()
  const navigate = useNavigateWithFromState()
  const messages = useMessages()

  return (
    <div className="fixed top-0 right-0 left-[108px] h-[60px] bg-b0 flex gap-4 items-center px-4 sm:left-0">
      {currentPatient ? <PatientsDropdown /> : <div className="flex-1" />}
      <div className="flex items-center gap-2">
        {superRelativeResponse.data && !currentPatient?.is_patient_user && (
          <StarIcon width={24} height={24} className="shrink-0" />
        )}
        <Popover
          title={`${user.first_name} ${user.last_name}`}
          actions={[
            {
              text: messages.profile,
              onClick: () => {
                navigate(`/${paths.profile}`)
              },
            },
            {
              text: messages.settings,
              badge: !user.is_active,
              onClick: () => {
                navigate(`/${paths.settings}`)
              },
            },
            {
              text: messages.helpFeedback,
              onClick: () => {
                openInNewTab(config.faqsUrl)
              },
            },
            {
              text: messages.privacyPolicyImprint,
              onClick: () => {
                navigate(`/${paths.imprintAndDataSecurity}`)
              },
            },
            { text: messages.signOut, onClick: alertState.open, icon: LogoutIcon },
          ]}
        >
          <div>
            <Avatar
              first_name={user.first_name || ''}
              last_name={user.last_name || ''}
              src={user.avatar}
            />
            {!user.is_active && <div className="badge-danger absolute right-0 bottom-0" />}
          </div>
        </Popover>
      </div>
      {alertState.isOpen && (
        <Alert
          title={messages.signOutTitle}
          actions={[
            {
              text: messages.signOut,
              variant: 'primary',
              onClick: logout,
            },
            { text: messages.cancel, variant: 'secondary', onClick: alertState.close },
          ]}
          contents={[messages.signOutText1, messages.signOutText2]}
        />
      )}
    </div>
  )
}
