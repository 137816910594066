import { ReactComponent as Close } from '@/assets/close.svg'
import { ReactComponent as SuccessIcon } from '@/assets/tick-circle.svg'
import { PrimaryButton } from '@/components'
import { useInvalidateQuery } from '@/utils'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('registerPage.successState.title'),
    text: t('registerPage.successState.text'),
    button: t('registerPage.successState.button'),
  }
}

export const RegisterSuccess = () => {
  const messages = useMessages()
  const invalidateQuery = useInvalidateQuery()

  const handleInvalidateSession = () => {
    invalidateQuery('session')
  }

  return (
    <div className="page flex items-center justify-center pt-[60px] bg-sec0 sm:items-start">
      <div className="subpage-header">
        <div />
        <div className="text-textM">{messages.title}</div>
        <Close
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={handleInvalidateSession}
        />
      </div>
      <div className="max-w-[488px] flex flex-col gap-[62px] items-center p-6">
        <div className="flex flex-col gap-5 items-center">
          <SuccessIcon width={48} height={48} className="shrink-0" />
          <div className="text-textL text-center">{messages.text}</div>
        </div>
        <PrimaryButton block onClick={handleInvalidateSession}>
          {messages.button}
        </PrimaryButton>
      </div>
    </div>
  )
}
