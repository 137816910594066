import { ChatMessageType } from '@/api'
import { ReactComponent as HeartIcon } from '@/assets/heart.svg'
import { useLikeMessage } from '@/services'
import { useModal } from '@/utils'
import { dec, inc } from 'ramda'
import { useState } from 'react'

type LikeMessageType = Pick<ChatMessageType, 'liked' | 'likes' | 'id'> & { disabled?: boolean }

export const LikeMessage = ({ likes, liked, id, disabled }: LikeMessageType) => {
  const likeState = useModal(liked)
  const [likesCount, setLikesCount] = useState(likes)
  const likeMessageMutation = useLikeMessage(id)

  const handleLike = () => {
    if (!disabled) {
      likeMessageMutation.mutate(!likeState.isOpen, {
        onSuccess: () => {
          likeState.toggle()
          setLikesCount(likeState.isOpen ? dec : inc)
        },
      })
    }
  }

  if (disabled && !likesCount) return null

  return (
    <div
      className="absolute left-[14px] -bottom-[11px] flex gap-1 items-center p-1 bg-b0 rounded-[100px] border-[0.5px] border-sec10 cursor-pointer"
      onClick={handleLike}
    >
      <HeartIcon
        stroke={likeState.isOpen ? 'var(--danger)' : 'var(--b50)'}
        fill={likeState.isOpen ? 'var(--danger)' : 'var(--b0)'}
        className="shrink-0 transition-all"
      />
      {likesCount > (likeState.isOpen ? 1 : 0) && likesCount}
    </div>
  )
}
