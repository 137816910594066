import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { AuthTroubleshootLayout, PageLoader, Password, PrimaryButton } from '@/components'
import { FormItem, FormProvider, useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import { useResetPassword, useValidateResetPasswordToken } from '@/services'
import { useModal, useSearchParams } from '@/utils'
import { FormikConfig } from 'formik'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router-dom'
import { object, string } from 'yup'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    validations: {
      newPasswordRequired: t('changePasswordPage.validations.newPassHint'),
      newPasswordPattern: t('changePasswordPage.validations.currentPassLabel'),
      confirmPasswordRequired: t('resetPasswordPage.validations.confirmPasswordRequired'),
      confirmPasswordMatch: t('resetPasswordPage.validations.confirmPasswordMatch'),
    },
    snackError: t('resetPasswordPage.snackError'),
    snackSuccess: t('resetPasswordPage.snackSuccess'),
    title: t('resetPasswordPage.title'),
    button: t('resetPasswordPage.button'),
    subtitle: t('resetPasswordPage.subtitle'),
    newPasswordLabel: t('resetPasswordPage.newPasswordLabel'),
    confirmPasswordLabel: t('resetPasswordPage.confirmPasswordLabel'),
    confirmPasswordHint: t('resetPasswordPage.confirmPasswordHint'),
    errorButton: t('resetPasswordPage.errorState.button'),
    errorTitle: t('resetPasswordPage.errorState.title'),
  }
}

const validationSchema = (messages: ReturnType<typeof useMessages>['validations']) =>
  object({
    password: string()
      .required(messages.newPasswordRequired)
      .matches(/^.*(?=.{8,})((?=.*\d)+)((?=.*[A-z]){1}).*$/, messages.newPasswordPattern),
    confirm_password: string()
      .required(messages.confirmPasswordRequired)
      .test('confirm-password', messages.confirmPasswordMatch, (value, { parent }) =>
        Boolean(value.length && value === parent.password),
      ),
  })

export const ResetPasswordPage = () => {
  const messages = useMessages()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const { token, uid } = useSearchParams<{ token: string; uid: string }>()
  const errorState = useModal(!token || !uid)
  const data = useValidateResetPasswordToken(token)

  const resetPasswordMutation = useResetPassword({ token, uid })

  const handleSubmit: FormikConfig<{ password: string }>['onSubmit'] = (body, { setErrors }) => {
    if (!token || !uid) {
      navigate(window.location.pathname)
      return
    }

    resetPasswordMutation.mutate(body.password, {
      onError: () => {
        setErrors({ password: messages.snackError })
      },
      onSuccess: () => {
        snackbar.success(messages.snackSuccess)
        navigate(`/${paths.auth}/${paths.login}`)
      },
    })
  }

  useEffect(() => {
    if (!token || !uid) {
      errorState.open()
    }
  }, [token, uid])

  if (data.isLoading) return <PageLoader />

  if (data.isError) return <Navigate to={window.location.pathname} />

  if (errorState.isOpen)
    return (
      <AuthTroubleshootLayout
        title={messages.errorTitle}
        buttonText={messages.errorButton}
        redirectPath={`/${paths.auth}/${paths.login}`}
      >
        <Trans i18nKey="resetPasswordPage.errorState.text" />
      </AuthTroubleshootLayout>
    )

  return (
    <div className="page flex items-center justify-center pt-[60px] bg-sec0 sm:items-start">
      <div className="subpage-header">
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={() => navigate('/')}
        />
        <div className="text-textM">{messages.title}</div>
        <div />
      </div>
      <div className="max-w-[488px] w-full flex flex-col gap-8 items-center p-6">
        <h2 className="text-h2reg text-center">{messages.subtitle}</h2>
        <FormProvider<{ password: string }>
          initialValues={{ password: '' }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema(messages.validations)}
          className="w-full flex flex-col gap-8"
        >
          <div className="flex flex-col gap-2">
            <FormItem<typeof Password>
              name="password"
              component={Password}
              label={messages.newPasswordLabel}
            />
            <FormItem<typeof Password>
              helperText={messages.confirmPasswordHint}
              name="confirm_password"
              component={Password}
              label={messages.confirmPasswordLabel}
            />
          </div>
          <PrimaryButton disabled={resetPasswordMutation.isLoading} type="submit" block>
            {messages.button}
          </PrimaryButton>
        </FormProvider>
      </div>
    </div>
  )
}
