import { Banner } from '@/components'
import { useAuthContext, useSurveyContext } from '@/providers'
import { useTranslation } from 'react-i18next'
import { DocumentSpace } from './DocumentSpace'
import { PatientInvite } from './PatientInvite'
import { PatientSurvey } from './PatientSurvey'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    appSurveyTitle: t('mySpacePage.survey.title'),
    surveyDescription: t('mySpacePage.survey.description'),
    surveyBannerButton: t('mySpacePage.survey.bannerCta'),
  }
}

export const MySpacePage = () => {
  const messages = useMessages()
  const { surveyExists, open } = useSurveyContext()
  const { currentPatient } = useAuthContext()

  return (
    <div className="max-w-[488px] flex flex-col gap-6 p-4 w-full">
      {!currentPatient?.is_patient_user && <PatientInvite />}
      {surveyExists && (
        <Banner
          title={messages.appSurveyTitle}
          actions={[
            {
              text: messages.surveyBannerButton,
              variant: 'contained',
              onClick: open,
            },
          ]}
        >
          {messages.surveyDescription}
        </Banner>
      )}
      <PatientSurvey />
      <DocumentSpace />
    </div>
  )
}
