import { RelativeListItem } from '@/api'
import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { Alert, DetailsCard, PageLoader, PrimaryButton, SecondaryButton } from '@/components'
import { useAuthContext, useSnackbar } from '@/providers'
import {
  useAcceptMatchRequest,
  useAssignSuperRelative,
  useDeclineMatchRequest,
  useRelativesList,
  useRemoveRelative,
  useRemoveSuperRelative,
  useResendInvite,
  useResendPatientInvite,
  useRevokeInvitation,
} from '@/services'
import { areLoading, useInvalidateQuery, useModal, useNavigateToPreviousLocation } from '@/utils'
import { parsePhoneNumber } from 'libphonenumber-js'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Location, Navigate, useLocation, useParams } from 'react-router-dom'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    generalError: t('global.messageError'),
    firstName: t('profilePage.firstName'),
    lastName: t('profilePage.lastName'),
    phone: t('profilePage.phone'),
    email: t('profilePage.email'),
    relationshipType: t('inviteRelativePage.relationshipType'),
    assigned: t('manageRelativePage.assigned', { returnObjects: true }),
    need_to_accept: t('manageRelativePage.needToAccept', { returnObjects: true }),
    in_progress: t('manageRelativePage.inProgress', { returnObjects: true }),
    toast: t('manageRelativePage.toast', { returnObjects: true }),
    primaryButtonWaiting: t('manageRelativePage.primaryButtonWaiting'),
    primaryButtonElse: t('manageRelativePage.primaryButtonElse'),
    modal: t('manageRelativePage.modal', { returnObjects: true }),
    assignSuperRelative: t('manageRelativePage.assignSuperRelative'),
    removeSuperRelative: t('manageRelativePage.removeSuperRelative'),
    removeSuperRelativeAlert: t('manageRelativePage.removeSuperRelativeAlert', {
      returnObjects: true,
    }),
    assignSuperRelativeAlert: t('manageRelativePage.assignSuperRelativeAlert', {
      returnObjects: true,
    }),
    relativeStatusToTitle: {
      assigned: t('manageRelativePage.assigned.title'),
      need_to_accept: t('manageRelativePage.needToAccept.title'),
      in_progress: t('manageRelativePage.inProgress.title'),
    },
    relativeStatusToDangerButtonText: {
      assigned: t('manageRelativePage.assigned.button'),
      need_to_accept: t('manageRelativePage.needToAccept.button'),
      in_progress: t('manageRelativePage.inProgress.button'),
    },
    relativeStatusToDangerAlertProps: {
      assigned: {
        title: t('manageRelativePage.assigned.modals.title'),
        buttonText: t('manageRelativePage.assigned.modals.button'),
        contents: [
          t('manageRelativePage.assigned.modals.text1'),
          t('manageRelativePage.assigned.modals.text2'),
        ],
      },
      need_to_accept: {
        title: t('manageRelativePage.needToAccept.modals.title'),
        buttonText: t('manageRelativePage.needToAccept.modals.button'),
        contents: [
          t('manageRelativePage.needToAccept.modals.text1'),
          t('manageRelativePage.needToAccept.modals.text2'),
        ],
      },
      in_progress: {
        title: t('manageRelativePage.inProgress.modals.title'),
        buttonText: t('manageRelativePage.inProgress.modals.button'),
        contents: [
          t('manageRelativePage.inProgress.modals.text1'),
          t('manageRelativePage.inProgress.modals.text2'),
        ],
      },
    },
    relativeStatusToDeleteAlertProps: {
      assigned: {
        title: t('manageRelativePage.assigned.modals.deleteTitle'),
        content: t('manageRelativePage.assigned.modals.deleteText'),
      },
      need_to_accept: {
        title: t('manageRelativePage.needToAccept.modals.deleteTitle'),
        content: t('manageRelativePage.needToAccept.modals.deleteText'),
      },
      in_progress: {
        title: t('manageRelativePage.inProgress.modals.deleteTitle'),
        content: t('manageRelativePage.inProgress.modals.deleteText'),
      },
    },
  }
}

type ManageRelativeButtonsType = {
  status: RelativeListItem['status']
  id: RelativeListItem['id'] | RelativeListItem['request_id']
  onBack: () => void
  isPatientAccount: boolean
  isSuperRelative: boolean
}

const ManageRelativeButtons = ({
  status,
  id,
  onBack,
  isPatientAccount,
  isSuperRelative,
}: ManageRelativeButtonsType) => {
  const snackbar = useSnackbar()
  const invalidateQuery = useInvalidateQuery()
  const successAlertState = useModal(false)
  const confirmDeleteAlertState = useModal(false)
  const superRelativeAlertState = useModal(false)
  const errorAlertState = useModal(false)
  const { patientId } = useParams<{ patientId: string }>()
  const { currentPatient } = useAuthContext()
  const messages = useMessages()

  const removeSuperRelativeMutation = useRemoveSuperRelative(parseInt(patientId as string))
  const assignSuperRelativeMutation = useAssignSuperRelative(
    parseInt(patientId as string),
    id as RelativeListItem['id'],
  )
  const removeRelativeMutation = useRemoveRelative(
    parseInt(patientId as string),
    id as RelativeListItem['id'],
  )
  const revokeInvitationMutation = useRevokeInvitation(
    parseInt(patientId as string),
    id as RelativeListItem['request_id'],
  )
  const resendPatientInviteMutation = useResendPatientInvite(parseInt(patientId as string))
  const resendInviteMutation = useResendInvite(
    parseInt(patientId as string),
    id as RelativeListItem['request_id'],
  )
  const declineMatchRequestMutation = useDeclineMatchRequest(
    parseInt(patientId as string),
    id as RelativeListItem['request_id'],
  )
  const acceptMatchRequestMutation = useAcceptMatchRequest(
    parseInt(patientId as string),
    id as RelativeListItem['request_id'],
  )

  const handleSuccessAlertClose = async () => {
    successAlertState.close()
    if (status === 'need_to_accept') {
      await invalidateQuery('relatives')
    }
    onBack()
  }

  const handleSuperRelativeSuccessAlertClose = async () => {
    superRelativeAlertState.close()
    onBack()
  }

  const handleErrorAlertClose = async () => {
    errorAlertState.close()
    await invalidateQuery('relatives')
  }

  const handlePrimaryClick = () => {
    if (status === 'in_progress') {
      const mutation = isPatientAccount ? resendPatientInviteMutation : resendInviteMutation
      mutation.mutate(
        {},
        {
          onSuccess: successAlertState.open,
          onError: () => {
            snackbar.error(messages.toast.inProgressError)
          },
        },
      )
    } else if (status === 'need_to_accept') {
      acceptMatchRequestMutation.mutate(
        {},
        {
          onSuccess: successAlertState.open,
          onError: () => {
            snackbar.error(messages.toast.waitingError)
          },
        },
      )
    }
  }

  const handleChangeSuperRelative = () => {
    if (isSuperRelative) {
      removeSuperRelativeMutation.mutate(
        {},
        {
          onError: () => {
            snackbar.error(messages.generalError)
          },
        },
      )
    } else {
      assignSuperRelativeMutation.mutate(
        {},
        {
          onError: () => {
            snackbar.error(messages.generalError)
          },
        },
      )
    }
  }

  const handleDeleteConfirm = () => {
    if (status === 'assigned') {
      removeRelativeMutation.mutate(
        {},
        {
          onSuccess: errorAlertState.open,
          onError: () => {
            snackbar.error(messages.toast.onDeleteAssignedError)
          },
        },
      )
    }
    if (status === 'in_progress') {
      revokeInvitationMutation.mutate(
        {},
        {
          onSuccess: errorAlertState.open,
          onError: () => {
            snackbar.error(messages.toast.onDeleteInProgressError)
          },
        },
      )
    }
    if (status === 'need_to_accept') {
      declineMatchRequestMutation.mutate(
        {},
        {
          onSuccess: errorAlertState.open,
          onError: () => {
            snackbar.error(messages.toast.onDeleteWaitingError)
          },
        },
      )
    }
  }

  useEffect(() => {
    if (errorAlertState.isOpen) {
      confirmDeleteAlertState.close()
    }
  }, [errorAlertState.isOpen])

  return (
    <div className="flex flex-col gap-2 w-full">
      {status !== 'assigned' && (
        <PrimaryButton
          block
          onClick={handlePrimaryClick}
          disabled={areLoading([acceptMatchRequestMutation, resendInviteMutation])}
        >
          {status === 'need_to_accept' ? messages.primaryButtonWaiting : messages.primaryButtonElse}
        </PrimaryButton>
      )}
      {currentPatient?.is_patient_user && status === 'assigned' && (
        <PrimaryButton
          block
          onClick={superRelativeAlertState.open}
          disabled={areLoading([assignSuperRelativeMutation, removeSuperRelativeMutation])}
        >
          {isSuperRelative ? messages.removeSuperRelative : messages.assignSuperRelative}
        </PrimaryButton>
      )}
      {!isPatientAccount && (
        <SecondaryButton danger block onClick={confirmDeleteAlertState.open}>
          {messages.relativeStatusToDangerButtonText[status]}
        </SecondaryButton>
      )}
      {successAlertState.isOpen && (
        <Alert
          title={
            status === 'in_progress' ? messages.modal.inProgressTitle : messages.modal.elseTitle
          }
          contents={[
            status === 'in_progress' ? messages.modal.inProgressText : messages.modal.elseText,
          ]}
          variant="success"
          actions={[{ text: messages.modal.buttonDone, onClick: handleSuccessAlertClose }]}
        />
      )}
      {errorAlertState.isOpen && (
        <Alert
          title={messages.relativeStatusToDeleteAlertProps[status].title}
          contents={[messages.relativeStatusToDeleteAlertProps[status].content]}
          variant="danger"
          actions={[{ text: messages.modal.buttonDone, onClick: handleErrorAlertClose }]}
        />
      )}
      {confirmDeleteAlertState.isOpen && (
        <Alert
          title={messages.relativeStatusToDangerAlertProps[status].title}
          contents={messages.relativeStatusToDangerAlertProps[status].contents}
          actions={[
            {
              text: messages.relativeStatusToDangerAlertProps[status].buttonText,
              variant: 'danger',
              onClick: handleDeleteConfirm,
            },
            {
              text: messages.modal.buttonCancel,
              variant: 'secondary',
              onClick: confirmDeleteAlertState.close,
            },
          ]}
        />
      )}
      {superRelativeAlertState.isOpen && (
        <Alert
          title={
            removeSuperRelativeMutation.isSuccess
              ? messages.removeSuperRelativeAlert.successTitle
              : assignSuperRelativeMutation.isSuccess
              ? messages.assignSuperRelativeAlert.successTitle
              : isSuperRelative
              ? messages.removeSuperRelativeAlert.title
              : messages.assignSuperRelativeAlert.title
          }
          contents={
            removeSuperRelativeMutation.isSuccess
              ? messages.removeSuperRelativeAlert.successContents
              : assignSuperRelativeMutation.isSuccess
              ? messages.assignSuperRelativeAlert.successContents
              : isSuperRelative
              ? messages.removeSuperRelativeAlert.contents
              : messages.assignSuperRelativeAlert.contents
          }
          variant={
            removeSuperRelativeMutation.isSuccess || assignSuperRelativeMutation.isSuccess
              ? 'success'
              : 'default'
          }
          actions={
            removeSuperRelativeMutation.isSuccess || assignSuperRelativeMutation.isSuccess
              ? [{ text: messages.modal.buttonDone, onClick: handleSuperRelativeSuccessAlertClose }]
              : [
                  {
                    text: isSuperRelative
                      ? messages.removeSuperRelativeAlert.buttonText
                      : messages.assignSuperRelativeAlert.buttonText,
                    variant: isSuperRelative ? 'danger' : 'primary',
                    onClick: handleChangeSuperRelative,
                  },
                  {
                    text: messages.modal.buttonCancel,
                    variant: 'secondary',
                    onClick: superRelativeAlertState.close,
                  },
                ]
          }
        />
      )}
    </div>
  )
}

export const ManageRelativePage = () => {
  const { relativeId } = useParams<{ relativeId: string }>()
  const { state } = useLocation() as Omit<Location, 'state'> & {
    state?: { is_patient_user?: boolean }
  }

  const { currentPatient, relatives } = useAuthContext()
  const { isLoading } = useRelativesList(currentPatient)

  const currentRelative = relatives.find(({ id, request_id, is_patient_user }) => {
    return (
      id === parseInt(relativeId as string) ||
      request_id === relativeId ||
      (is_patient_user && state?.is_patient_user)
    )
  })

  const navigate = useNavigateToPreviousLocation()

  const handleBack = () => {
    navigate(window.location.pathname.replace(`/${relativeId}`, ''))
  }
  const messages = useMessages()

  if (isLoading) return <PageLoader />

  if (!currentRelative)
    return <Navigate to={window.location.pathname.replace(`/${relativeId}`, '')} />

  return (
    <div className="page flex justify-center pt-[60px] bg-sec0">
      <div className="subpage-header">
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={handleBack}
        />
        <div className="text-textM">{messages.relativeStatusToTitle[currentRelative.status]}</div>
        <div />
      </div>
      <div className="max-w-[488px] w-full flex flex-col gap-10 items-center p-6">
        <DetailsCard
          details={[
            { label: messages.firstName, value: currentRelative.first_name || '--' },
            { label: messages.lastName, value: currentRelative.last_name || '--' },
            {
              label: messages.phone,
              value: currentRelative.phone
                ? parsePhoneNumber(currentRelative.phone).formatInternational()
                : '--',
            },
            { label: messages.email, value: currentRelative.email || '--' },
            { label: messages.relationshipType, value: currentRelative.relation },
          ]}
        />
        <ManageRelativeButtons
          onBack={handleBack}
          status={currentRelative.status}
          id={currentRelative.request_id || currentRelative.id}
          isPatientAccount={currentRelative.is_patient_user}
          isSuperRelative={currentRelative.is_super_relative}
        />
      </div>
    </div>
  )
}
