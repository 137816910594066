import { UpdatePasswordBody } from '@/api'
import { FormProvider, useSnackbar } from '@/providers'
import { useUpdatePassword } from '@/services'
import { FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import { ChangePasswordForm } from './ChangePasswordForm'
import { makeValidationSchema } from './validationSchema'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    snackError: t('changePasswordPage.snackError'),
    validations: t('changePasswordPage.validations', { returnObjects: true }),
  }
}

export const PasswordChangePage = () => {
  const messages = useMessages()
  const snackbar = useSnackbar()

  const updatePasswordMutation = useUpdatePassword()

  const handleSubmit: FormikConfig<UpdatePasswordBody>['onSubmit'] = (values) =>
    new Promise<void>((resolve, reject) => {
      updatePasswordMutation.mutate(values, {
        onSuccess: () => {
          resolve()
        },
        onError: () => {
          snackbar.error(messages.snackError)
          reject()
        },
      })
    })

  return (
    <FormProvider<UpdatePasswordBody>
      initialValues={{ new_password: '', old_password: '' }}
      onSubmit={handleSubmit}
      className="h-full"
      validationSchema={makeValidationSchema(messages.validations)}
    >
      <ChangePasswordForm />
    </FormProvider>
  )
}
