import { UpdateUserBody } from '@/api'
import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { ReactComponent as PenIcon } from '@/assets/pen.svg'
import { Alert, ButtonLink, DetailsCard, OTPInput, OTPInputRef, PhoneInput } from '@/components'
import { FormItem, FormProvider, useAuthContext, useFormContext, useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import {
  useConsumePhoneOTP,
  useDeleteEmailChangeOTP,
  useQueryEmailChangeOTP,
  useResendEmailChangeVerification,
  useUpdateUser,
} from '@/services'
import { useModal, useNavigateToPreviousLocation } from '@/utils'
import { FormikConfig } from 'formik'
import { parsePhoneNumber } from 'libphonenumber-js'
import { isEmpty, map, pick } from 'ramda'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ProfileAvatar } from './ProfileAvatar'
import { validationSchema } from './validationSchema'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('profilePage.title'),
    subtitle: t('profilePage.subtitle'),
    firstName: t('profilePage.firstName'),
    lastName: t('profilePage.lastName'),
    phone: t('profilePage.phone'),
    email: t('profilePage.email'),
    youHaveUnsavedChangesTitle: t('profilePage.youHaveUnsavedChangesTitle'),
    youHaveUnsavedChangesText1: t('profilePage.youHaveUnsavedChangesText1'),
    youHaveUnsavedChangesText2: t('profilePage.youHaveUnsavedChangesText2'),
    hintChangePhone: t('profilePage.hintChangePhone'),
    hintChangeMail: t('profilePage.hintChangeMail'),
    phoneInUse: t('profilePage.phoneInUse'),
    mailInUse: t('profilePage.mailInUse'),
    checkMailForPhone: t('profilePage.checkMailForPhone'),
    confirmEmailTitle: t('profilePage.confirmEmailTitle'),
    confirmEmailText1: t('profilePage.confirmEmailText1'),
    confirmEmailText2: t('profilePage.confirmEmailText2'),
    resendEmail: t('profilePage.resendEmail'),
    done: t('profilePage.done'),
    verifyPhoneTitle: t('profilePage.verifyPhoneTitle'),
    verifyPhoneText: t('profilePage.verifyPhoneText'),
    inputCode: t('profilePage.inputCode'),
    discard: t('profilePage.discard'),
    save: t('profilePage.save'),
    cancel: t('global.cancel'),
    messageError: t('global.messageError'),
    validations: t('profilePage.validations', { returnObjects: true }),
  }
}

const ProfilePageContent = () => {
  const {
    initialValues,
    values,
    isValid,
    hasChanges,
    isSubmitting,
    submitForm,
    validateForm,
    resetForm,
    setTouched,
  } = useFormContext<UpdateUserBody>()
  const editState = useModal(false)
  const alertState = useModal(false)
  const navigate = useNavigateToPreviousLocation()

  const disabled = isSubmitting || !isValid || !hasChanges

  const goBack = () => {
    navigate(`/${paths.home}`)
  }

  const handleCancel = () => {
    editState.close()
    resetForm()
  }

  const handleBack = () => {
    if (editState.isOpen) {
      if (hasChanges) {
        alertState.open()
      } else {
        handleCancel()
      }
    } else {
      goBack()
    }
  }

  const handleSubmit = () =>
    validateForm().then((errors) => {
      if (isEmpty(errors)) {
        submitForm().then(handleCancel)
      } else {
        setTouched(map(() => true, errors))
      }
    })

  const handleSubmitBeforeLeave = () => {
    handleSubmit().finally(alertState.close)
  }
  const messages = useMessages()

  useEffect(() => {
    if (!editState.isOpen) {
      alertState.close()
    }
  }, [editState.isOpen])

  return (
    <div className="page flex justify-center pt-[60px] bg-sec0">
      <div className="subpage-header">
        <ArrowLeft
          height={24}
          width={24}
          fill="var(--primary)"
          className="cursor-pointer"
          onClick={handleBack}
        />
        <>
          {editState.isOpen ? (
            <ButtonLink onClick={handleSubmit} disabled={disabled} type="button">
              {messages.save}
            </ButtonLink>
          ) : (
            <>
              <div className="text-textM">{messages.title}</div>
              <PenIcon
                height={24}
                width={24}
                className="cursor-pointer stroke-primary"
                onClick={editState.open}
              />
            </>
          )}
        </>
      </div>
      <div className="max-w-[488px] flex flex-col gap-4 items-center p-4 w-full">
        <ProfileAvatar />
        <div className="w-full text-left text-h3">{messages.subtitle}</div>
        {editState.isOpen ? (
          <>
            <FormItem
              name="first_name"
              label={`${messages.firstName}*`}
              componentProps={{ inputType: 'name' }}
            />
            <FormItem
              name="last_name"
              label={`${messages.lastName}*`}
              componentProps={{ inputType: 'name' }}
            />
            <FormItem<typeof PhoneInput>
              component={PhoneInput}
              animateLabel={false}
              name="phone"
              label={`${messages.phone}*`}
              helperText={values.email !== initialValues.email ? messages.hintChangePhone : ''}
              componentProps={{
                disabled: values.email !== initialValues.email,
              }}
            />
            <FormItem
              name="email"
              label={`${messages.email}*`}
              helperText={values.phone !== initialValues.phone ? messages.hintChangeMail : ''}
              componentProps={{
                inputType: 'email',
                disabled: values.phone !== initialValues.phone,
              }}
            />
          </>
        ) : (
          <div className="w-full">
            <DetailsCard
              details={[
                { label: messages.firstName, value: initialValues.first_name || '--' },
                { label: messages.lastName, value: initialValues.last_name || '--' },
                {
                  label: messages.phone,
                  value: initialValues.phone
                    ? parsePhoneNumber(initialValues.phone).formatInternational()
                    : '--',
                },
                { label: messages.email, value: initialValues.email },
              ]}
            />
          </div>
        )}
      </div>
      {alertState.isOpen && (
        <Alert
          title={messages.youHaveUnsavedChangesTitle}
          contents={[messages.youHaveUnsavedChangesText1, messages.youHaveUnsavedChangesText2]}
          actions={[
            { text: messages.save, onClick: handleSubmitBeforeLeave },
            { text: messages.discard, variant: 'secondary', onClick: handleCancel },
          ]}
        />
      )}
    </div>
  )
}

export const ProfilePage = () => {
  const snackbar = useSnackbar()
  const otpInputRef = useRef<OTPInputRef>(null)
  const { user } = useAuthContext()
  const emailAlertState = useModal(false)
  const phoneAlertState = useModal(false)
  const emailOtpResponse = useQueryEmailChangeOTP()

  const emailOtpExists = useMemo(() => emailOtpResponse.data && emailOtpResponse.data !== 204, [
    emailOtpResponse.data,
  ])

  const updateProfileMutation = useUpdateUser()
  const resendVerifyEmailMutation = useResendEmailChangeVerification()
  const consumePhoneOtpMutation = useConsumePhoneOTP()
  const deleteEmailOtpMutation = useDeleteEmailChangeOTP()
  const messages = useMessages()

  const handleSubmit: FormikConfig<UpdateUserBody>['onSubmit'] = (values, { setErrors }) =>
    new Promise<void>((resolve, reject) =>
      updateProfileMutation.mutate(values, {
        onSuccess: () => {
          if (values.phone !== user.phone) {
            phoneAlertState.open()
          } else if (values.email !== user.email) {
            emailAlertState.open()
          }
          resolve()
        },
        onError: (err) => {
          if (err.response?.status === 409) {
            if (values.phone !== user.phone) {
              setErrors({ phone: messages.phoneInUse })
            } else if (values.email !== user.email) {
              setErrors({
                email: messages.mailInUse,
              })
            }
          } else {
            snackbar.error(messages.messageError)
          }
          reject()
        },
      }),
    )

  const handleResendEmail = () => {
    resendVerifyEmailMutation.mutate({}) // todo success
    emailAlertState.close()
  }

  const handleConsumeOTP = (confirmation_code: string) => {
    consumePhoneOtpMutation.mutate(
      { confirmation_code },
      {
        onSuccess: () => {
          snackbar.success(messages.checkMailForPhone)
          phoneAlertState.close()
        },
        onError: () => {
          otpInputRef.current?.reset()
        },
      },
    )
  }

  const handleCancelOTP = useCallback(async () => {
    if (emailOtpExists) {
      await deleteEmailOtpMutation.mutateAsync({})
    }

    phoneAlertState.close()
  }, [emailOtpExists])

  useEffect(() => {
    if (emailOtpExists) {
      phoneAlertState.open()
    }
  }, [emailOtpExists])

  return (
    <FormProvider<UpdateUserBody>
      enableReinitialize
      initialValues={pick(['email', 'first_name', 'last_name', 'phone'], user)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(messages.validations)}
      className="h-full"
    >
      <ProfilePageContent />
      {emailAlertState.isOpen && (
        <Alert
          title={messages.confirmEmailTitle}
          contents={[messages.confirmEmailText1, messages.confirmEmailText2]}
          actions={[
            { text: messages.resendEmail, onClick: handleResendEmail },
            { text: messages.done, variant: 'secondary', onClick: emailAlertState.close },
          ]}
        />
      )}
      {phoneAlertState.isOpen && (
        <Alert
          title={messages.verifyPhoneTitle}
          contents={[messages.verifyPhoneText]}
          actions={[
            {
              text: messages.inputCode,
              customAction: <OTPInput ref={otpInputRef} onConfirm={handleConsumeOTP} />,
            },
            { text: messages.cancel, variant: 'secondary', onClick: handleCancelOTP },
          ]}
        />
      )}
    </FormProvider>
  )
}
