import { PageLoader, Select, selectOptionType } from '@/components'
import { FormItem } from '@/providers'
import { useHospitalList } from '@/services'
import { map } from 'ramda'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    label: t('global.hospital'),
  }
}

export const OnboardingHospitalStep = () => {
  const { data = [], isLoading } = useHospitalList<selectOptionType[]>({
    select: map((item) => ({ value: item.id, label: item.name })),
  })
  const messages = useMessages()

  if (isLoading) return <PageLoader />

  return (
    <FormItem<typeof Select>
      component={Select}
      name="hospital"
      label={messages.label}
      componentProps={{ options: data }}
    />
  )
}
