import { FormItem } from '@/providers'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    label: t('profilePage.lastName'),
  }
}
export const OnboardingLastNameStep = () => {
  const messages = useMessages()

  return (
    <FormItem
      name="patient_last_name"
      label={messages.label}
      componentProps={{ inputType: 'name' }}
    />
  )
}
