import { Password } from '@/components'
import { FormItem } from '@/providers'
import { useDoesEmailExist } from '@/services'
import { useFormikContext } from 'formik'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    email: t('profilePage.email'),
    emailExist: t('registerPage.emailExist'),
    password: t('changePasswordPage.newPasswordLabel'),
  }
}

export const RegisterStep = () => {
  const messages = useMessages()
  const doesEmailExistMutation = useDoesEmailExist()
  const { setFieldError } = useFormikContext<{ email: string; password: string }>()

  const onEmailBlur: ComponentProps<typeof FormItem>['onBlur'] = (e) => {
    doesEmailExistMutation.mutate(e.target.value, {
      onError: (err) => {
        if (err.response?.status === 406) setFieldError('email', messages.emailExist)
      },
    })
  }

  return (
    <>
      <FormItem
        name="email"
        label={`${messages.email}*`}
        onBlur={onEmailBlur}
        componentProps={{ inputType: 'email' }}
      />
      <FormItem<typeof Password> component={Password} name="password" label={messages.password} />
    </>
  )
}
