// todo cleanup
import { ReactComponent as PlayAudioIcon } from '@/assets/play-audio.svg'
import { ReactComponent as DefaultSoundWave } from '@/assets/soundwave.svg'
import { ReactComponent as StopAudioIcon } from '@/assets/stop-audio.svg'
import stringToHtml from 'html-react-parser'
import { useEffect, useRef, useState } from 'react'

type NativeAudioPlayerProps = {
  audio: string
  sound_wave?: string
}

type SoundWaveProps = {
  sound_wave?: string
  width: number
  color: string
}
//animation per seconds
const ANIMATION_MULTIPLIER_PER_SECOND = 20

const SoundWave = ({ sound_wave, color, width }: SoundWaveProps) => {
  if (sound_wave)
    return (
      <div className={`svg:h-[60px] svg:w-full`} style={{ width }}>
        {stringToHtml(sound_wave.replace('<svg', `<svg stroke=${color}`))}
      </div>
    )

  return <DefaultSoundWave height={60} width={width} fill={color} />
}

export const AudioPlayer = ({ audio, sound_wave }: NativeAudioPlayerProps) => {
  // state
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)

  // references
  const audioPlayer = useRef<HTMLAudioElement>(null) // reference our audio component
  const progressBar = useRef<HTMLInputElement>(null) // reference our progress bar
  const animationRef = useRef<number>() // reference the animation

  useEffect(() => {
    // @ts-ignore
    function getDuration(event) {
      event.target.currentTime = 0
      event.target.removeEventListener('timeupdate', getDuration)
      setDuration(event.target.duration)
      if (progressBar.current) {
        progressBar.current.max = String(event.target.duration * ANIMATION_MULTIPLIER_PER_SECOND)
      }
    }

    if (audioPlayer.current) {
      audioPlayer.current.onloadedmetadata = () => {
        if (audioPlayer.current) {
          const seconds = audioPlayer.current.duration
          if (seconds === Infinity) {
            audioPlayer.current.currentTime = 1e101
            audioPlayer.current.addEventListener('timeupdate', getDuration)
          } else {
            setDuration(seconds)
            if (progressBar.current) {
              progressBar.current.max = String(seconds * ANIMATION_MULTIPLIER_PER_SECOND)
            }
          }

          setIsPlaying(false)
          setCurrentTime(0)

          audioPlayer.current.onended = () => {
            setIsPlaying(false)
            setCurrentTime(0)
            if (progressBar.current) {
              progressBar.current.value = '0'
            }

            cancelAnimationFrame(animationRef.current!)
          }
        }

        return () => {
          cancelAnimationFrame(animationRef.current!)
        }
      }
    }
  }, [audioPlayer])

  const calculateTime = (secs: number) => {
    const minutes = Math.floor(secs / 60)
    const returnedMinutes = minutes < 10 ? `${minutes}` : `${minutes}`
    const seconds = Math.floor(secs % 60)
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
    return `${returnedMinutes}:${returnedSeconds}`
  }

  const togglePlayPause = () => {
    if (!isPlaying) {
      setIsPlaying(true)
      audioPlayer.current?.play()
      animationRef.current = requestAnimationFrame(whilePlaying)
    } else {
      setIsPlaying(false)
      audioPlayer.current?.pause()
      cancelAnimationFrame(animationRef.current!)
    }
  }

  const whilePlaying = () => {
    if (audioPlayer.current && progressBar.current) {
      progressBar.current.value = String(
        audioPlayer.current.currentTime * ANIMATION_MULTIPLIER_PER_SECOND,
      )
    }
    changePlayerCurrentTime()
    animationRef.current = requestAnimationFrame(whilePlaying)
  }

  const changeRange = () => {
    if (audioPlayer.current && progressBar.current) {
      audioPlayer.current.currentTime =
        parseInt(progressBar.current.value) / ANIMATION_MULTIPLIER_PER_SECOND
    }
    changePlayerCurrentTime()
  }

  const changePlayerCurrentTime = () => {
    if (progressBar.current)
      setCurrentTime(parseInt(progressBar.current.value) / ANIMATION_MULTIPLIER_PER_SECOND)
  }

  //stop all audio files except the current one
  useEffect(() => {
    const players = document.querySelectorAll('audio')
    players.forEach((player) => {
      player.addEventListener('play', playHandler)
    })
    function playHandler(e: Event) {
      players.forEach((player) => {
        if (e.target !== player) {
          player.load()
        }
      })
    }
  }, [])

  return (
    <div className="flex flex-col items-start w-full">
      <audio className="hidden" ref={audioPlayer} src={audio} preload="metadata"></audio>
      <div className="flex items-center gap-[10px] w-full">
        <div onClick={togglePlayPause} className="cursor-pointer">
          {isPlaying ? (
            <StopAudioIcon width={24} height={24} />
          ) : (
            <PlayAudioIcon width={24} height={24} fill="var(--b90)" />
          )}
        </div>
        <div className="flex items-center relative h-[60px] w-full">
          <div className="absolute left-1 right-1 -top-[calc(50%-30px)] ">
            <SoundWave
              sound_wave={sound_wave}
              color="var(--b50)"
              width={(progressBar.current?.offsetWidth || 0) - 4}
            />
            <div
              className="absolute top-0 left-0 overflow-x-hidden"
              style={{
                width: `${(currentTime / duration) * 100 || 0}%`,
              }}
            >
              <SoundWave
                sound_wave={sound_wave}
                color="var(--primary)"
                width={(progressBar.current?.offsetWidth || 0) - 4}
              />
            </div>
          </div>
          <input
            type="range"
            defaultValue="0"
            ref={progressBar}
            onChange={changeRange}
            onClick={changeRange}
            className="my2-slider z-10"
          />
        </div>
      </div>
      <span className="text-textXS text-b90 text-center">
        {calculateTime(Math.round(duration - currentTime))}
      </span>
    </div>
  )
}
