import { ReactComponent as CameraIcon } from '@/assets/camera.svg'
import { ReactComponent as DocumentIcon } from '@/assets/document.svg'
import { ReactComponent as GreenMark } from '@/assets/green-mark.svg'
import { Camera, PopoverModal } from '@/components'
import { paths } from '@/routes/paths.ts'
import { useDocumentTypes, useUploadDocuments } from '@/services'
import { useItemModal, useModal } from '@/utils'
import { InputHTMLAttributes, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Preview } from '../MediaPage/Preview'
import { ChosenDocumentType } from './DocumentSpace'

type DocumentUploadPopoverContentProps = {
  documentType: ChosenDocumentType
  onClose: () => void
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('documentTypesPopover.title'),
    camera: t('mediaPage.uploadModal.camera'),
    gallery: t('documentTypesPopover.gallery'),
  }
}

export const DocumentUploadPopoverContent = ({
  documentType,
  onClose,
}: DocumentUploadPopoverContentProps) => {
  const { patientId } = useParams()
  const navigate = useNavigate()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const uploadDocumentMutation = useUploadDocuments(patientId as string)
  const popoverState = useModal(true)
  const cameraState = useModal(false)
  const previewState = useItemModal<File>()
  const messages = useMessages()

  const handleUploadMedia = (file: File) => {
    uploadDocumentMutation.mutate(
      { file, document_request: documentType.id },
      { onSuccess: () => navigate(`/${paths.home}/${patientId}/${paths.chat}`) },
    )
  }

  const handleGalleryClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = ''
      inputFileRef.current.click()
    }
  }

  const handleFileChange: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) => {
    const inputFile = e.target.files?.[0]
    if (inputFile) {
      handleUploadMedia(inputFile)
    }
  }

  useEffect(() => {
    if (
      !popoverState.isOpen &&
      !cameraState.isOpen &&
      !previewState.isOpen &&
      !uploadDocumentMutation.isLoading
    ) {
      onClose()
    }
  }, [
    popoverState.isOpen,
    cameraState.isOpen,
    previewState.isOpen,
    uploadDocumentMutation.isLoading,
  ])

  return (
    <>
      {popoverState.isOpen && (
        <PopoverModal
          title={documentType.name}
          onClose={popoverState.close}
          actions={[
            { text: messages.camera, icon: CameraIcon, onClick: cameraState.open },
            { text: messages.gallery, icon: DocumentIcon, onClick: handleGalleryClick },
          ]}
          closeOnSelect={false}
        />
      )}
      {(cameraState.isOpen || previewState.isOpen) &&
        createPortal(
          <>
            {cameraState.isOpen && (
              <Camera
                onCapture={(blob) => {
                  const file = new File([blob], `${documentType.name}.jpg`, { type: 'image/jpeg' })
                  previewState.openItemModal(file)
                }}
                onClose={cameraState.close}
              />
            )}
            {previewState.isOpen && (
              <Preview
                assetType="image"
                file={previewState.item!}
                onClose={() => {
                  previewState.closeItemModal()
                  cameraState.open()
                }}
                onConfirm={() => {
                  handleUploadMedia(previewState.item!)
                  previewState.closeItemModal()
                }}
              />
            )}
          </>,
          document.body,
        )}
      <input
        hidden
        type="file"
        accept="image/*,.pdf"
        ref={inputFileRef}
        onChange={handleFileChange}
      />
    </>
  )
}

type PatientPopoverContentProps = {
  onClose: () => void
  onSelect: (documentType: ChosenDocumentType) => void
}

export const DocumentTypesModal = ({ onClose, onSelect }: PatientPopoverContentProps) => {
  const { patientId } = useParams()
  const { data = [] } = useDocumentTypes(patientId as string)
  const messages = useMessages()

  return (
    <PopoverModal
      contentClassName="max-h-[496px]"
      title={messages.title}
      onClose={onClose}
      actions={data.map(({ id, name, has_an_uploaded_document }) => ({
        text: name,
        icon: has_an_uploaded_document ? GreenMark : undefined,
        onClick: () => {
          onSelect({ id, name })
        },
        disabled: has_an_uploaded_document,
      }))}
      parentElement={null}
    />
  )
}
