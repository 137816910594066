type LineProgressProps = {
  percentage: number
}

export const LineProgress = ({ percentage }: LineProgressProps) => (
  <div className="h-[8px] w-full bg-b30 rounded-[24px]">
    <div
      className="bg-primary h-[8px] rounded-[24px] transition-all duration-[400ms]"
      style={{ width: `${percentage}%` }}
    />
  </div>
)
