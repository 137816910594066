import { Hospital, Station } from '@/api'
import { DetailsCard } from '@/components'
import { useFormContext } from '@/providers'
import { useHospitalList, useStationList } from '@/services'
import { changeDateFormat } from '@/utils'
import { find, propEq } from 'ramda'
import { OnboardingValues } from './Page'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    hospital: t('global.hospital'),
    ward: t('global.ward'),
    firstName: t('profilePage.firstName'),
    lastName: t('profilePage.lastName'),
    relationshipType: t('global.relationship'),
    birthdate: t('global.relationship'),
  }
}

export const OnboardingOverviewStep = () => {
  const messages = useMessages()
  const { values } = useFormContext<OnboardingValues>()

  // @ts-ignore
  const { data: hospital } = useHospitalList<Hospital>({
    select: find(propEq(values.hospital, 'id')),
  })

  // @ts-ignore
  const { data: station } = useStationList<Station>(values.hospital, {
    select: find(propEq(values.station, 'id')),
  })

  return (
    <div className="flex flex-col bg-b0 border-[0.5px] border-sec10 rounded-[8px] shadow-[0_0.5px_0_0_rgba(16,34,40,0.02)]">
      <DetailsCard
        details={[
          { label: messages.hospital, value: hospital?.name },
          { label: messages.ward, value: station?.name },
          { label: messages.firstName, value: values.patient_first_name },
          { label: messages.lastName, value: values.patient_last_name },
          { label: messages.birthdate, value: changeDateFormat('-', '.', values.date_of_birth) },
          { label: messages.relationshipType, value: values.patient_relation },
        ]}
      />
    </div>
  )
}
