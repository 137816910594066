import { api } from './api'
import { PatientStatusType, RelationType } from './patient'

export type RelativeListItem = {
  first_name?: string
  last_name?: string
  email?: string
  phone?: string
  relation: RelationType
  status: PatientStatusType
  is_super_relative: boolean
} & (
  | {
      request_id: string
      is_patient_user: false
      status: 'need_to_accept' | 'in_progress'
      id?: never
    }
  | {
      request_id: null
      is_patient_user: true
      status: 'need_to_accept' | 'in_progress'
      id?: never
    }
  | { id: number; request_id?: never; is_patient_user: boolean; status: 'assigned' }
)

export const listRelatives = (patientId: string) =>
  api.get<RelativeListItem[]>(
    `/relatives/relative_patients/${patientId}/get_patient_relative_data/`,
  )

export type InviteRelativeBody = {
  first_name: string
  last_name: string
  relation: RelationType
  patient_account_invite: boolean
} & (
  | {
      phone: string
      email?: never
    }
  | {
      email: string
      phone?: never
    }
)

export const removeRelative = (patientId: number, relativeId: RelativeListItem['id']) =>
  api.delete(`/contacts/patients/${patientId}/remove-relative/?relative=${relativeId}`)

export const inviteRelative = (patientId: number, body: InviteRelativeBody) =>
  api.post(`/relatives/relative_to_relative_invite/${patientId}/`, body)

export const revokeInvitation = (patientId: number, relativeId: RelativeListItem['request_id']) =>
  api.delete(`/contacts/invitations/${patientId}/${relativeId}/`)

export const resendInvite = (patientId: number, relativeId: RelativeListItem['request_id']) =>
  api.post(`/contacts/invitations/${patientId}/${relativeId}/resend_invitation/`)

export const declineMatchRequest = (
  patientId: number,
  relativeId: RelativeListItem['request_id'],
) => api.delete(`/contacts/add_relative_requests/${patientId}/${relativeId}/`)

export const acceptMatchRequest = (patientId: number, relativeId: RelativeListItem['request_id']) =>
  api.post(`/contacts/add_relative_requests/${patientId}/confirm_request/${relativeId}/`)

export const removeSuperRelative = (patientId: number) =>
  api.delete(`/contacts/patients/${patientId}/remove_super_relative/`)

export const assignSuperRelative = (patientId: number, relativeId: RelativeListItem['id']) =>
  api.post(`/contacts/patients/${patientId}/assign_as_super_relative/${relativeId}/`)
