import { AccessDenied, PrimaryButton } from '@/components'
import { useResendVerificationEmail } from '@/services'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('notVerifiedPage.title'),
    text: t('notVerifiedPage.text'),
    button: t('notVerifiedPage.button'),
    title2: t('notVerifiedPage.title2'),
  }
}
export const NotVerifiedPage = () => {
  const messages = useMessages()
  const resendVerificationEmailMutation = useResendVerificationEmail()

  const handleClick = () => {
    resendVerificationEmailMutation.mutate({})
  }

  return (
    <AccessDenied
      pageTitle={messages.title}
      title={messages.title2}
      content={messages.text}
      contactI18nKey="notVerifiedPage.hint"
    >
      <PrimaryButton
        block
        onClick={handleClick}
        disabled={resendVerificationEmailMutation.isLoading}
      >
        {messages.button}
      </PrimaryButton>
    </AccessDenied>
  )
}
