import { api } from './api'

export type PatientStatusType = 'assigned' | 'need_to_accept' | 'in_progress'

export type RelationType =
  | 'Ehepartner:in'
  | 'Partner:in'
  | 'Elternteil'
  | 'Sohn / Tochter'
  | 'Schwester / Bruder'
  | 'Enkel:in'
  | 'Freund:in'
  | 'andere:r Verwandte:r'
  | 'Bekannte:r'
  | 'Unbekannt'

export type PatientListItem = {
  request_id?: string
  id: number
  first_name: string
  last_name: string
  hospital: string
  station: string
  relation: RelationType
  connection_status: PatientStatusType
  active: boolean
  days_left_until_read_only_mode_ends?: number
  is_patient_user?: boolean
}

export type Patient = {
  active: boolean
  date_of_admission: string
  deactivated_at: string
  first_name: string
  last_name: string
  station: string
  hospital: string
  patient_invite_disabled: boolean
  icu_diary_enabled: boolean
}

export const listPatients = () =>
  api.get<PatientListItem[]>('/relatives/relative_patients/get_patients_and_requests/')

export const getPatient = (patientId: string) =>
  api.get<Patient>(`/patient_card/patient_info/${patientId}/`)

export const checkSuperRelative = (patientId: string) =>
  api.get<{ super_relative: boolean }>(
    `/relatives/relative_patients/${patientId}/my_superrelative_status/`,
  )

type PatientSearch = {
  hospital: string
  station?: number
  date_of_birth: string
  patient_last_name?: string
  patient_first_name?: string
}

type PatientSearchResult = {
  first_name: string
  last_name: string
  id: number
  station: number
  date_of_birth: string
}

export const queryPatientByBirthdate = (
  body: Omit<PatientSearch, 'patient_first_name' | 'patient_last_name'>,
) =>
  api.post<Omit<PatientSearch, 'patient_first_name' | 'patient_last_name'>, PatientSearchResult>(
    '/relatives/relative_onboarding/first_stage_patient_search/',
    body,
  )

export const queryPatientByLastName = (body: Omit<PatientSearch, 'patient_first_name'>) =>
  api.post<Omit<PatientSearch, 'patient_first_name'>, PatientSearchResult>(
    '/relatives/relative_onboarding/second_stage_patient_search/',
    body,
  )

export const queryPatientByFirstName = (body: PatientSearch) =>
  api.post<PatientSearch, PatientSearchResult>(
    '/relatives/relative_onboarding/third_stage_patient_search/',
    body,
  )

type OnboardingBody = {
  patient_id: number
  patient_relation: RelationType
}

export const submitOnboarding = (body: OnboardingBody) =>
  api.post<OnboardingBody>('/relatives/relative_onboarding/add_to_patient_relative/', {
    ...body,
    is_super_relative: false,
  })

export type MatchingInviteRespondType = {
  patient_id: PatientListItem['id']
  request_id: Omit<PatientListItem['request_id'], 'undefined'>
}

export const acceptMatchingInvite = ({
  patient_id,
  request_id,
  relation,
}: MatchingInviteRespondType & { relation?: RelationType }) =>
  api.post(`/contacts/add_relative_requests/${patient_id}/accept_request/${request_id}/`, {
    relation,
  })

export const declineMatchingInvite = ({ patient_id, request_id }: MatchingInviteRespondType) =>
  api.delete(`/contacts/add_relative_requests/${patient_id}/${request_id}/`)

export const removePatient = (patientId: number) =>
  api.delete(`/relatives/relative_patients/${patientId}/remove_me_from_relatives/`)

export type SurveyBody = {
  data: object
  step: number
}

export type Survey = {
  id: string
} & SurveyBody

export const getSurveyData = (patientId: number) =>
  api.get<Survey>(`/contacts/medical_surveys/${patientId}/`)

export const createSurveyData = (patientId: number, body: SurveyBody) =>
  api.post(`/contacts/medical_surveys/${patientId}/`, body)

export const addSurveyData = (patientId: number, surveyId: string, body: SurveyBody) =>
  api.patch(`/contacts/medical_surveys/${patientId}/${surveyId}/`, body)

export type PatientInviteType = {
  email: string | null
  phone: string | null
  first_name: string
  last_name: string
  invite_date: string
}

export const getPatientInvite = (patientId: number) =>
  api.get<PatientInviteType>(
    `/relatives/relative_to_relative_invite/${patientId}/get_patient_account_invitation/`,
  )

export const resendPatientInvite = (patientId: number) =>
  api.patch(
    `/relatives/relative_to_relative_invite/${patientId}/resend_patient_account_invitation/`,
  )
