import { DocumentType } from '@/api'
import { ReactComponent as InfoIcon } from '@/assets/info.svg'
import { Banner, Loader } from '@/components'
import { useAuthContext } from '@/providers'
import { useDocumentTypes } from '@/services'
import { changeDateFormat, useItemModal, useModal } from '@/utils'
import { complement, find, pipe, pluck, prop, slice } from 'ramda'
import { memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DocumentTypesModal, DocumentUploadPopoverContent } from './DocumentTypesPopover'

export type ChosenDocumentType = Pick<DocumentType, 'id' | 'name'>

const useMessages = () => {
  const { t } = useTranslation()

  return {
    docTitle: t('documentsPage.docsCard.title'),
    docButton: t('documentsPage.docsCard.button'),
  }
}

const makeRequestDate = pipe<[DocumentType], string, string, string>(
  prop('creation_time'),
  slice(0, 10),
  changeDateFormat('-', '.'),
)

const makeRequestTime = pipe<[DocumentType], string, string>(prop('creation_time'), slice(11, 16))

export const DocumentSpace = memo(() => {
  const messages = useMessages()
  const { patientId } = useParams()
  const { data = [], isLoading } = useDocumentTypes(patientId as string)
  const { currentPatient } = useAuthContext()

  const documentTypesState = useModal(false)
  const documentUploadState = useItemModal<ChosenDocumentType>()

  const completed = !find(complement(prop('has_an_uploaded_document')), data)

  if (isLoading) return <Loader />

  return (
    <>
      {completed ? (
        <Banner
          icon={<InfoIcon fill="var(--primary)" height={24} width={24} className="shrink-0" />}
        >
          <Trans
            i18nKey={'documentsPage.nothingNeeded'}
            values={{ patient_name: `${currentPatient?.first_name} ${currentPatient?.last_name}` }}
          />
        </Banner>
      ) : (
        <Banner
          badge
          title={messages.docTitle}
          actions={[
            {
              variant: 'contained',
              text: messages.docButton,
              onClick: documentTypesState.open,
              disabled: !currentPatient?.active || currentPatient?.is_patient_user,
            },
          ]}
        >
          <>
            <Trans
              i18nKey={'documentsPage.docsCard.textRequest'}
              values={{
                date_request: makeRequestDate(data[0]),
                time_request: makeRequestTime(data[0]),
              }}
            />
            <br />
            <Trans
              i18nKey={'documentsPage.docsCard.documentsList'}
              values={{
                documents_list: pluck('name', data).join(', '),
              }}
            />
            <br />
            <br />
            <br />
            <Trans
              i18nKey={'documentsPage.docsCard.hintRequest'}
              values={{
                patient_name: `${currentPatient?.first_name} ${currentPatient?.last_name}`,
              }}
            />
          </>
        </Banner>
      )}
      {documentTypesState.isOpen && (
        <DocumentTypesModal
          onClose={documentTypesState.close}
          onSelect={documentUploadState.openItemModal}
        />
      )}
      {documentUploadState.isOpen && (
        <DocumentUploadPopoverContent
          onClose={documentUploadState.closeItemModal}
          documentType={documentUploadState.item!}
        />
      )}
    </>
  )
})
