import { PaginatedResponseType } from '@/utils'
import { api } from './api'

export type Hospital = {
  id: string
  name: string
}

export type Station = {
  id: number
  name: string
}

export const listHospitals = () => api.get<PaginatedResponseType<Hospital>>('/contacts/hospitals/')

export const listStations = (hospitalId: string) =>
  api.get<Station[]>(`/contacts/hospitals/${hospitalId}/get_hospital_stations/`)
