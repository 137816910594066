import { FunctionComponent, ReactNode } from 'react'
import { Location, Navigate, Outlet, useLocation, useOutletContext } from 'react-router-dom'
import { Path } from '../routes/paths'

type RouteGuardProps = {
  fallbackRoute: Path
  guard: FunctionComponent<{ fallback: ReactNode; children: ReactNode }>
}

export const RouteGuard = ({ guard: Guard, fallbackRoute }: RouteGuardProps) => {
  const location = useLocation() as Omit<Location, 'state'> & { state?: { prev?: Location } }
  const context = useOutletContext()

  const { state } = location
  const { prev } = state || {}

  return (
    <Guard
      fallback={
        <Navigate
          replace
          to={prev?.pathname ? `${prev.pathname}${prev.search}` : fallbackRoute}
          state={{ prev: location }}
        />
      }
    >
      <Outlet context={context} />
    </Guard>
  )
}
