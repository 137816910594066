import { FunctionComponent, SVGProps } from 'react'
import { ButtonBase, ButtonProps } from '..'
import { Loader } from '../Loader'

type IconButtonProps = {
  disabled?: boolean
  onClick: () => void
  icon: FunctionComponent<SVGProps<SVGSVGElement>>
  size?: number
  className?: string
  variant?: ButtonProps['variant']
  isLoading?: boolean
  square?: boolean
  iconProps?: SVGProps<SVGSVGElement>
}

export const IconButton = ({
  disabled,
  onClick,
  icon: Icon,
  size = 44,
  className,
  variant = 'contained',
  isLoading,
  square,
  iconProps = {},
}: IconButtonProps) => {
  const handleClick = () => {
    if (!disabled && !isLoading) {
      onClick()
    }
  }

  return (
    <ButtonBase
      variant={variant}
      className={`shrink-0 ${square ? 'rounded-[8px]' : '!rounded-[50%]'}  ${
        disabled || isLoading ? 'bg-transparent cursor-not-allowed' : `cursor-pointer`
      } ${className}`}
      onClick={handleClick}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      {isLoading ? <Loader /> : <Icon {...iconProps} />}
    </ButtonBase>
  )
}
