/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  anyPass,
  both,
  complement,
  concat,
  fromPairs,
  is,
  isEmpty,
  isNil,
  join,
  map,
  pipe,
  reject,
  split,
  startsWith,
  tail,
  toPairs,
  when,
} from 'ramda'
import { useLocation } from 'react-router-dom'
import { postMessageToWebview } from './webview'

const coercedIsNaN = (x: any) => both(is(Number), isNaN)(x)

export const makeQueryString = pipe(
  reject(anyPass([isNil, isEmpty, coercedIsNaN])),
  toPairs, // convert the object to pairs like `['limit', 5]`
  map(map((e) => encodeURIComponent(e as string | number | boolean))), // encode keys and values
  map(join('=')), // Join the key-value pairs with '='
  join('&'), // Join each segment of the query with '&'
  when(complement(isEmpty), concat('?')),
)

export const fromQueryString = pipe<
  [string],
  string,
  string[],
  [string, string][],
  [string, string][],
  Record<string, string>
>(
  tail,
  split('&'),
  map((string) => {
    const [key, ...value] = split('=', string)

    return [key, value.join('=')]
  }),
  // @ts-ignore
  map(map(decodeURIComponent)),
  fromPairs,
)

export const useSearchParams = <T>() => {
  const { search } = useLocation()

  return fromQueryString(search) as T
}

export const openInNewTab = (url: string) =>
  postMessageToWebview({
    type: 'openInNewTab',
    params: { url },
    callback: () =>
      window.open(
        when(startsWith('www.'), concat('https://'), url),
        '_blank',
        'noopener,noreferrer',
      ),
  })
