import { Banner, Loader } from '@/components'
import { useAuthContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useQueryPatientSurvey } from '@/services'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    surveyTitle: t('documentsPage.surveyCard.title'),
    surveyText: (patient_name: string) => t('documentsPage.surveyCard.text', { patient_name }),
    hintAlreadyStarted: (relative_name: string) =>
      t('documentsPage.surveyCard.hintAlreadyStarted', { relative_name }),
    buttonStart: t('documentsPage.surveyCard.buttonStart'),
    buttonContinue: t('documentsPage.surveyCard.buttonContinue'),
  }
}

export const PatientSurvey = memo(() => {
  const messages = useMessages()
  const navigate = useNavigate()
  const { patientId } = useParams()
  const { data, isLoading, error } = useQueryPatientSurvey(parseInt(patientId!))
  const { currentPatient } = useAuthContext()

  if (isLoading) return <Loader />

  if (error?.response?.status === 405) return null

  return (
    <Banner
      title={messages.surveyTitle}
      helperText={
        error?.response?.status === 409
          ? messages.hintAlreadyStarted(
              `${error.response.data.first_name} ${error.response.data.last_name}`,
            )
          : ''
      }
      actions={[
        {
          variant: data ? 'outlined' : 'contained',
          text: data ? messages.buttonContinue : messages.buttonStart,
          onClick: () => {
            navigate(`/${paths.home}/${patientId}/${paths.survey}`)
          },
          disabled:
            error?.response?.status === 409 ||
            !currentPatient?.active ||
            currentPatient.is_patient_user,
        },
      ]}
    >
      {messages.surveyText(`${currentPatient?.first_name} ${currentPatient?.last_name}`)}
    </Banner>
  )
})
