import {
  InviteRelativeBody,
  PatientListItem,
  RelativeListItem,
  acceptMatchRequest,
  assignSuperRelative,
  declineMatchRequest,
  inviteRelative,
  listRelatives,
  removeRelative,
  removeSuperRelative,
  resendInvite,
  revokeInvitation,
} from '@/api'
import { reactQueryKeys, useInvalidateOnSuccessMutations, useMutation } from '@/utils'
import { useQuery } from 'react-query'

export const useRelativesList = (currentPatient?: PatientListItem) =>
  useQuery(
    [reactQueryKeys.relatives, currentPatient?.id],
    () => listRelatives(String(currentPatient?.id)),
    {
      enabled: Boolean(currentPatient?.id && currentPatient?.connection_status === 'assigned'),
    },
  )

export const useRemoveRelative = (patientId: number, relativeId: RelativeListItem['id']) =>
  useMutation(() => removeRelative(patientId, relativeId))

export const useInviteRelative = (patientId: number) =>
  useInvalidateOnSuccessMutations(
    ['relatives', ['patientInvite', String(patientId)]],
    (body: InviteRelativeBody) => inviteRelative(patientId, body),
  )

export const useRevokeInvitation = (
  patientId: number,
  relativeId: RelativeListItem['request_id'],
) => useMutation(() => revokeInvitation(patientId, relativeId))

export const useResendInvite = (patientId: number, relativeId: RelativeListItem['request_id']) =>
  useMutation(() => resendInvite(patientId, relativeId))

export const useDeclineMatchRequest = (
  patientId: number,
  relativeId: RelativeListItem['request_id'],
) => useMutation(() => declineMatchRequest(patientId, relativeId))

export const useAcceptMatchRequest = (
  patientId: number,
  relativeId: RelativeListItem['request_id'],
) => useMutation(() => acceptMatchRequest(patientId, relativeId))

export const useRemoveSuperRelative = (patientId: number) =>
  useMutation(() => removeSuperRelative(patientId))

export const useAssignSuperRelative = (patientId: number, relativeId: RelativeListItem['id']) =>
  useMutation(() => assignSuperRelative(patientId, relativeId))
