import { IcuDiaryBody, IcuDiaryItem, addIcuDiary, getIcuDiary } from '@/api'
import {
  InfiniteQueryOptions,
  reactQueryKeys,
  useInfiniteQueryData,
  useInvalidateOnSuccessMutation,
} from '@/utils'

export const useIcuDiary = (patientId: string, options: InfiniteQueryOptions<IcuDiaryItem> = {}) =>
  useInfiniteQueryData<IcuDiaryItem>(
    [reactQueryKeys.icuDiary, patientId],
    ({ pageParam = 0 }) => getIcuDiary(patientId, { offset: pageParam, limit: 10 }),
    { enabled: Boolean(patientId), ...options },
  )

export const useAddIcuDiary = (patientId: string) =>
  useInvalidateOnSuccessMutation(['icuDiary', patientId], (body: IcuDiaryBody) =>
    addIcuDiary(patientId, body),
  )
