import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { ReactComponent as PinIcon } from '@/assets/pin.svg'
import { Divider } from '@/components'
import { useAuthContext } from '@/providers'
import { usePinnedMessages } from '@/services'
import { useModal } from '@/utils'
import dayjs from 'dayjs'
import { toPairs } from 'ramda'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const useMessages = (patient_name: string) => {
  const { t } = useTranslation()

  return {
    pinnedMessages: t('pinnedMessages.pinnedMessages'),
    defaultWelcomeMessage: t('pinnedMessages.defaultWelcomeMessage', { patient_name }),
  }
}

export const PinnedMessages = () => {
  const { patientId } = useParams<{ patientId: string }>()
  const { currentPatient } = useAuthContext()
  const { data, isLoading } = usePinnedMessages(patientId as string)

  const { isOpen, toggle } = useModal(false)
  const messages = useMessages(`${currentPatient?.first_name} ${currentPatient?.last_name}`)

  if (isLoading || !data) return null

  return (
    <div className="fixed top-[68px] left-[116px] right-[8px] sm:right-2 sm:left-2 flex flex-col gap-4 px-4 py-2 bg-b0 border-[1px] border-sec10 rounded-[8px] shadow-[0px_0.5px_0_0_#ECEFF0,0_6px_14px_0_rgba(0,0,0,0.05)] backdrop-blur-[20px]">
      <div className="flex items-center gap-2 cursor-pointer" onClick={toggle}>
        <PinIcon width={24} height={24} className="shrink-0" />
        <div className="flex-1 text-button">{messages.pinnedMessages}</div>
        <ArrowLeft
          width={16}
          height={16}
          fill="var(--primary)"
          className={`transition-all duration-[400ms] ${isOpen ? 'rotate-90' : '-rotate-90'}`}
        />
      </div>
      {isOpen &&
        toPairs(data).map(
          ([key, value], i, arr) =>
            value && (
              <Fragment key={key}>
                <div className="text-button">{dayjs(value.time).format('dd. DD. MMMM')}</div>
                <div
                  className="whitespace-pre-line text-inputDescription text-b90"
                  style={{
                    wordBreak: 'break-word',
                  }}
                >
                  {key === 'welcome_message' && !value.text
                    ? messages.defaultWelcomeMessage
                    : value.text}
                </div>
                {i !== arr.length - 1 && <Divider />}
              </Fragment>
            ),
        )}
    </div>
  )
}
