import { getMeeting, getMeetingPrompt } from '@/api'
import { reactQueryKeys } from '@/utils'
import { useQuery } from 'react-query'

export const useMeeting = (patientId?: number) =>
  useQuery([reactQueryKeys.meeting, patientId], () => getMeeting(patientId!), {
    enabled: Boolean(patientId),
  })

export const useMeetingPrompt = (patientId?: number) =>
  useQuery([reactQueryKeys.meetingPrompt, patientId], () => getMeetingPrompt(patientId!), {
    enabled: Boolean(patientId),
  })
