import { ReactComponent as ErrorIcon } from '@/assets/error.svg'
import { ReactComponent as InfoIcon } from '@/assets/info.svg'
import { ReactComponent as SuccessIcon } from '@/assets/tick-circle.svg'
import { useAnimation } from '@/utils'
import { FunctionComponent, SVGProps, useEffect, useMemo } from 'react'

type SnackbarStatus = 'success' | 'error' | 'info' | 'accent'

const snackbarStatusToIcon: Record<
  SnackbarStatus,
  FunctionComponent<SVGProps<SVGSVGElement>> | null
> = {
  success: SuccessIcon,
  error: ErrorIcon,
  info: InfoIcon,
  accent: null,
}

const snackbarStatusToColor: Record<SnackbarStatus, string> = {
  success: 'var(--success)',
  error: 'var(--danger)',
  info: 'var(--info)',
  accent: 'var(--accent)',
}

const SnackbarIcon = ({ status }: { status: SnackbarStatus }) => {
  const Icon = useMemo(() => snackbarStatusToIcon[status], [status])
  if (Icon)
    return <Icon width={32} height={32} className="shrink-0" fill={snackbarStatusToColor[status]} />

  return null
}

export type SnackbarProps = {
  status: SnackbarStatus
  message: string | FunctionComponent<{ onClose: () => void }>
  duration?: number
  onClose: () => void
}

export const Snackbar = ({ message, status, duration = 5000, onClose }: SnackbarProps) => {
  const { isOpen, isLeaving, leave } = useAnimation({
    onLeave: onClose,
    duration: 200,
    defaultOpened: Boolean(message),
  })

  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (isOpen && duration) {
      timeout = setTimeout(leave, duration)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [duration])

  if (!isOpen) return null

  const Message = message

  return (
    <div
      className={`fixed w-screen px-4 bottom-[32px] flex justify-center 
                  z-[10001] ${isLeaving ? 'animate-fade-out' : 'animate-fade-in'}`}
    >
      <div
        className="flex items-center gap-3 p-4 w-full max-w-[420px] bg-b0 border-2 rounded-[10px]"
        style={{
          borderColor: snackbarStatusToColor[status],
        }}
      >
        <SnackbarIcon status={status} />
        <div className="text-textM">
          {typeof Message === 'string' ? Message : <Message onClose={leave} />}
        </div>
      </div>
    </div>
  )
}
