import { adjust, filter, isEmpty, range } from 'ramda'
import {
  ForwardedRef,
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

type OTPInputProps = {
  onConfirm?: (value: string) => void
  length?: number
}

export type OTPInputRef = { reset: () => void; value: string }

export const OTPInput = forwardRef(
  ({ onConfirm, length = 5 }: OTPInputProps, ref: ForwardedRef<OTPInputRef>) => {
    const parentRef = useRef<HTMLDivElement>(null)
    const [values, setValues] = useState(range(0, length).map(() => ''))

    const handleChange = (position: number): InputHTMLAttributes<HTMLInputElement>['onChange'] => (
      e,
    ) => {
      setValues(adjust(position, () => e.target.value))
      if (e.target.nextElementSibling && e.target.value) {
        const nextInput = e.target.nextElementSibling as HTMLInputElement
        nextInput.focus()
      }
    }

    const handleKeyDown: InputHTMLAttributes<HTMLInputElement>['onKeyDown'] = (e) => {
      if (
        !e.currentTarget.value &&
        e.key === 'Backspace' &&
        e.currentTarget.previousElementSibling
      ) {
        const prevInput = e.currentTarget.previousElementSibling as HTMLInputElement
        prevInput.focus()
      }
    }

    useEffect(() => {
      if (!filter(isEmpty, values).length && onConfirm) {
        onConfirm(values.join(''))
      }
    }, [JSON.stringify(values)])

    useImperativeHandle(
      ref,
      () => {
        return {
          reset: () => {
            setValues(range(0, length).map(() => ''))
            if (parentRef.current) {
              const firstElement = parentRef.current.firstElementChild as HTMLInputElement
              firstElement.focus()
            }
          },
          value: values.join(''),
        }
      },
      [JSON.stringify(values)],
    )

    return (
      <div className="flex gap-2" ref={parentRef}>
        {range(0, length).map((item) => (
          <input
            className="h-[68px] w-[54px] bg-sec10 border-[1px] border-sec30 rounded-[8px] outline-none text-[58px] text-b90 placeholder:text-sec30 placeholder:text-[58px] text-center"
            placeholder="0"
            maxLength={1}
            inputMode="numeric"
            key={item}
            value={values[item]}
            onChange={handleChange(item)}
            onKeyDown={handleKeyDown}
          />
        ))}
      </div>
    )
  },
)
