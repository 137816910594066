import { ReactComponent as UnmatchedIcon } from '@/assets/unmatched-icon.svg'
import { PrimaryButton } from '@/components'
import { paths } from '@/routes/paths'
import { useNavigateWithFromState } from '@/utils'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    text: t('onboardingPage.step0Text'),
    button: t('onboardingPage.step0Button'),
  }
}

export const UnmatchedPage = () => {
  const navigate = useNavigateWithFromState()
  const messages = useMessages()

  return (
    <div className="flex flex-col items-center justify-center h-full px-4 child:max-w-[488px] gap-4">
      <UnmatchedIcon className="shrink-0" width={64} height={64} />
      <div className="text-textL text-center font-normal">{messages.text}</div>
      <PrimaryButton block onClick={() => navigate(`/${paths.onboarding}`)}>
        {messages.button}
      </PrimaryButton>
    </div>
  )
}
