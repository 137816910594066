import { PatientInviteType } from '@/api'
import { ReactComponent as PlusIcon } from '@/assets/plus.svg'
import { ReactComponent as SendIcon } from '@/assets/send.svg'
import { Banner, Loader } from '@/components'
import { useAuthContext } from '@/providers'
import { paths } from '@/routes/paths'
import { useCurrentPatient, usePatientInvite, useResendPatientInvite } from '@/services'
import { useNavigateWithFromState } from '@/utils'
import dayjs from 'dayjs'
import { pick, prop } from 'ramda'
import { ComponentProps, memo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    title: t('mySpacePage.patientInvite.title'),
    invitedTitle: t('mySpacePage.patientInvite.invitedTitle'),
    primaryButton: t('mySpacePage.patientInvite.primaryButton'),
    invitedPrimaryButton: t('mySpacePage.patientInvite.invitedPrimaryButton'),
    invitedSecondaryButton: t('mySpacePage.patientInvite.invitedSecondaryButton'),
  }
}

export const PatientInvite = memo(() => {
  const messages = useMessages()
  const navigate = useNavigateWithFromState()
  const { patientId } = useParams()

  const patientResponse = useCurrentPatient({ select: prop('patient_invite_disabled') })
  const { data, isLoading } = usePatientInvite(parseInt(patientId!), {
    enabled: !patientResponse.data && !patientResponse.isLoading,
  })
  const { currentPatient } = useAuthContext()
  const resendPatientInviteMutation = useResendPatientInvite(parseInt(patientId as string))

  if (patientResponse.isLoading || isLoading) return <Loader />

  if (patientResponse.data) return null

  const goToInvitePage = (state?: Pick<PatientInviteType, 'email' | 'phone'>) => {
    navigate(`/${paths.home}/${patientId}/${paths.relatives}/${paths.invite}`, {
      patient_account_invite: true,
      ...(state || {}),
    })
  }

  const handlePrimaryClick = () => {
    if (data?.invite_date) {
      resendPatientInviteMutation.mutate({})
    } else {
      goToInvitePage()
    }
  }

  return (
    <Banner
      badge={!currentPatient?.active}
      title={data?.invite_date ? messages.invitedTitle : messages.title}
      actions={
        [
          {
            variant: 'contained',
            text: (
              <>
                {data?.invite_date ? (
                  <SendIcon
                    width={16}
                    height={16}
                    stroke="var(--b0)"
                    className={
                      data?.invite_date && resendPatientInviteMutation.isSuccess
                        ? '!fill-b50'
                        : '!fill-primary'
                    }
                  />
                ) : (
                  <PlusIcon />
                )}
                {data?.invite_date ? messages.invitedPrimaryButton : messages.primaryButton}
              </>
            ),
            onClick: handlePrimaryClick,
            disabled: data?.invite_date && resendPatientInviteMutation.isSuccess,
          },
          !!data?.invite_date && {
            variant: 'outlined',
            text: messages.invitedSecondaryButton,
            onClick: () => {
              goToInvitePage(pick(['email', 'phone'], data))
            },
          },
        ].filter(Boolean) as ComponentProps<typeof Banner>['actions']
      }
    >
      <Trans
        i18nKey={
          data?.invite_date
            ? 'mySpacePage.patientInvite.invitedText'
            : 'mySpacePage.patientInvite.text'
        }
        values={
          data?.invite_date
            ? {
                patientName: `${data?.first_name} ${data?.last_name}`,
                contact: data.email || data.phone,
                date: dayjs(data.invite_date).format('DD.MM.YYYY'),
              }
            : {
                patientName: `${currentPatient?.first_name} ${currentPatient?.last_name}`,
                patientFirstName: currentPatient?.first_name,
              }
        }
      />
    </Banner>
  )
})
