import { BrowserRouter } from 'react-router-dom'
import { ReactQueryProvider, SnackbarProvider } from './providers'
import { BaseRouter } from './routes'

export const App = () => (
  <BrowserRouter>
    <ReactQueryProvider>
      <SnackbarProvider>
        <BaseRouter />
      </SnackbarProvider>
    </ReactQueryProvider>
  </BrowserRouter>
)
