import { Select } from '@/components'
import { FormItem, useAuthContext, useFormContext } from '@/providers'
import { OnboardingValues } from './Page'
import { Trans, useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    label: t('onboardingPage.step4Label'),
    placeholder: t('onboardingPage.step4Placeholder'),
  }
}

export const OnboardingRelationStep = () => {
  const { settings } = useAuthContext()
  const { values } = useFormContext<OnboardingValues>()
  const messages = useMessages()

  return (
    <>
      <span className="text-center text-b90">
        <Trans
          i18nKey={'onboardingPage.step4Text'}
          values={{ patient_name: `${values.patient_first_name} ${values.patient_last_name}` }}
        />
      </span>
      <div className="flex flex-col gap-2 w-full pt-6">
        <span className="text-h4">{messages.label}:</span>
        <FormItem<typeof Select>
          component={Select}
          name="patient_relation"
          componentProps={{
            readOnly: true,
            placeholder: messages.placeholder,
            options: settings.relationships,
          }}
        />
      </div>
    </>
  )
}
