import { parsePhoneNumber } from 'libphonenumber-js'
import { ComponentProps, InputHTMLAttributes } from 'react'
import PhoneInputBase, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

type PhoneInputProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: boolean
  defaultCountry?: string
  value?: string | null
}

export const PhoneInput = ({
  value,
  onChange,
  error,
  defaultCountry = 'de',
  disabled,
  ...rest
}: PhoneInputProps) => {
  const handleChange: ComponentProps<typeof PhoneInputBase>['onChange'] = (
    value,
    data: CountryData,
    event,
  ) => {
    if (value.startsWith('00')) {
      event.target.value = event.target.value.replace(/^\+.{2}/g, `+${data.dialCode}`)
    }

    try {
      event.target.value = parsePhoneNumber(event.target.value).getURI().replace('tel:', '')
      // eslint-disable-next-line no-empty
    } catch (e) {}
    onChange?.(event)
  }

  return (
    <PhoneInputBase // todo design
      country={defaultCountry}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      inputProps={{
        type: 'phone',
        disabled: disabled,
        ...rest,
      }}
      inputClass={`!pr-4 !py-[10px] !border-[0.5px] !bg-b0 !rounded-[10px] !placeholder:text-b50 !text-placeholder !w-full !outline-none !h-[48px] transition-all duration-[400ms] ${
        error ? '!border-danger !text-danger !placeholder:text-danger' : '!border-sec30 !text-b100'
      }`}
      buttonClass={`!border-[0.5px] !bg-b0 !rounded-l-[10px] child:!rounded-l-[10px] ${
        error ? '!border-danger' : '!border-sec30'
      }`}
    />
  )
}
