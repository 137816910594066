import { DateInput } from '@/components'
import { FormItem } from '@/providers'
import { useTranslation } from 'react-i18next'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    label: t('global.birthdate'),
  }
}

export const OnboardingBirthdateStep = () => {
  const messages = useMessages()

  return (
    <FormItem<typeof DateInput>
      component={DateInput}
      name="date_of_birth"
      label={`${messages.label}*`}
      animateLabel={false}
    />
  )
}
