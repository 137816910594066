import { TextareaHTMLAttributes } from 'react'

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  error?: boolean
}

export const TextArea = ({ className = '', error, onChange, ...props }: TextAreaProps) => (
  <textarea
    onChange={onChange}
    className={`p-[10px] border-[1px] bg-b0 rounded-[10px] placeholder:text-b50 text-placeholder w-full outline-none transition-all duration-[400ms] resize-none ${
      error
        ? 'border-danger text-danger placeholder:text-danger'
        : 'border-sec30 text-b100 focus:border-accent'
    } ${className}`}
    {...props}
  />
)
