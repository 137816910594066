import { PageLoader, Survey } from '@/components'
import { useSurvey } from '@/services'
import { getDaysPassed, useLocalStorage, useLocalStorageObject, useModal } from '@/utils'
import { useCallback, useEffect, useMemo } from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import { AuthenticatedOutlet, useAuthContext } from './authProvider'

type SurveyContext = {
  open: () => void
  triggerSurvey: () => void
  surveyExists: boolean
  setMeetingSurveyId: (meetingSurveyId?: string) => void
}

export const SurveyProvider = () => {
  const { user, currentPatient } = useAuthContext()
  const { data, isLoading } = useSurvey(currentPatient?.is_patient_user ? 'patient' : 'relative', {
    enabled: Boolean(currentPatient),
  })
  const [searchParams, setSearchParams] = useSearchParams({ meetingSurveyId: '' })
  const surveyState = useModal(false)

  const [meetingSurveyId, setMeetingSurveyId] = useLocalStorage('meeting-survey-id', '')

  const { value, setValue } = useLocalStorageObject<string>({
    parent: 'surveys',
    key: data?.id!,
    initialValue: '',
  })

  const handleTriggerSurvey = useCallback(() => {
    if (data?.id) {
      if (
        (!data?.answered_at && (!value || getDaysPassed(value) >= 7)) ||
        (data.answered_at && getDaysPassed(data.answered_at) >= 30)
      ) {
        surveyState.open()
      }
    }
  }, [data?.id, data?.answered_at, value])

  useEffect(() => {
    if (surveyState.isOpen) {
      setValue(new Date().toISOString())

      if (meetingSurveyId) {
        localStorage.removeItem('meeting-survey-id')
      }

      if (searchParams.get('meetingSurveyId')) {
        setSearchParams((prev) => {
          prev.delete('meetingSurveyId')
          return prev
        })
      }
    }
  }, [surveyState.isOpen])

  useEffect(() => {
    if (
      (!value && getDaysPassed(user.created_at) >= (currentPatient?.is_patient_user ? 5 : 7)) ||
      (value && currentPatient?.is_patient_user)
    ) {
      handleTriggerSurvey()
    }
  }, [user.created_at, value, handleTriggerSurvey, currentPatient?.is_patient_user])

  useEffect(() => {
    if (
      !currentPatient?.is_patient_user &&
      meetingSurveyId &&
      searchParams.get('meetingSurveyId') === meetingSurveyId
    ) {
      handleTriggerSurvey()
    }
  }, [
    searchParams.get('meetingSurveyId'),
    meetingSurveyId,
    handleTriggerSurvey,
    currentPatient?.is_patient_user,
  ])

  const memoizedContext = useMemo<SurveyContext>(
    () => ({
      open: surveyState.open,
      triggerSurvey: currentPatient?.is_patient_user ? () => {} : handleTriggerSurvey,
      surveyExists: Boolean(data?.id),
      setMeetingSurveyId,
    }),
    [data?.id, handleTriggerSurvey, currentPatient?.is_patient_user],
  )

  if (isLoading) return <PageLoader />

  return (
    <>
      <AuthenticatedOutlet<SurveyContext> context={memoizedContext} />
      {surveyState.isOpen && (
        <Survey id={data?.id!} questions={data?.questions!} onClose={surveyState.close} />
      )}
    </>
  )
}

export const useSurveyContext = () => useOutletContext<SurveyContext>()
